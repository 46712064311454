import React, { useState, useContext } from "react";
import { GoPlus } from "react-icons/go";
import EditSummaryModal from "../EditSummaryModal";
import { logincontext } from "../../contexts/Logincontext";
import { LuPencilLine } from "react-icons/lu";

export default function SummaryComponent({
  dash,
  manageSummarymodal,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const [view, setview] = useState(true);
  return (
    <div className="w-full px-4 pt-3 md:pt-5 md:px-6 border-[1px] md:rounded-md bg-white">
          <div className="text-lg font-[550] flex justify-between">
            Summary{" "}
            {dash?.isloggedinuser && (
              <div className="flex gap-4">
                <div
                  onClick={() => manageSummarymodal(null)}
                  className="w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
                >
                  <LuPencilLine />
                </div>
              </div>
            )}
          </div>
          <div className="text-sm whitespace-pre-wrap">
            {dash?.complete_profile?.profile?.summary}
          </div>
          <br />
        </div>
  );
}

