import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import countriesList from "../../asserts/Countrylist";
import Months from "../../asserts/Months";
import { useNavigate } from "react-router-dom";
import Login from "../../pages/Login";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";
import DefaultEducation from "../../images/DefaultEducation.png";
import AddOrganization from "../AddOrganization";
import { Label, Radio } from "keep-react";


export default function Profile4() {
  const [loading, setLoading] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [selectedSchoolImage, setSelectedSchoolImage] = useState("");
  const [selectedLevelOfEdu, setSelectedLevelOfEdu] = useState("");
  const [filteredCounties, setfilteredCounties] = useState([]);
  const [graduatedError,setGraduatedError] = useState("")


  const token = localStorage.getItem("token");
  const levelOfEducations = [
    "No Formal Education",
    "Some High School",
    "High School Diploma or Equivalent",
    "Some College",
    "Associate Degree",
    "Bachelor's Degree",
    "Some Graduate School",
    "Master's Degree",
    "Professional Degree (e.g., MD, JD)",
    "Doctoral Degree (e.g., PhD, EdD)",
    "Other",
  ];

  const fieldOfStudy = [
    "Computer Science",
    "Electrical Engineering",
    "Electronics & Communications Engineering",
    "Mechanical Engineering",
    "Biology",
    "Mathematics",
    "Physics",
    "Chemistry",
    "Information Technology",
    "Civil Engineering",
    "Chemical Engineering",
    "Aerospace Engineering",
    "Biomedical Engineering",
    "Environmental Science",
    "Materials Science",
    "Industrial Engineering",
    "Software Engineering",
    "Data Science",
    "Artificial Intelligence",
    "Robotics",
    "Statistics",
    "Geology",
    "Astronomy",
    "Nuclear Engineering",
    "Systems Engineering",
    "Applied Mathematics",
    "Bioinformatics",
    "Biotechnology",
    "Earth Science",
    "Marine Biology",
    "Neuroscience",
  ];
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedtoMonth, setSelectedtoMonth] = useState("");
  const [selectedtoYear, setSelectedtoYear] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [filteredFieldOfStudy, setFilteredFieldOfStudy] = useState([]);
  const [selectedFieldOfStudy, setSelectedFieldOfStudy] = useState("");

  const [filteredLevelofEducations, setFilteredLevelofEducations] = useState(
    []
  );

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [check, setcheck] = useState(false);

  const checked = () => {
    setcheck(!check);
  };
  const initialtoyears = [
    ...[...Array(Months[1][0] + 11 - Months[1][0]).keys()].map(
      (num) => Months[1][0] + 11 - num
    ),
    ...Months[1],
  ];
  const [toyears, settoyears] = useState(initialtoyears);
  const yearselected = (e) => {
    setSelectedYear(e.target.value);
    settoyears(
      initialtoyears.slice(0, initialtoyears[0] - parseInt(e.target.value) + 1)
    );
    // settoyears(Months[1].slice(0,  Months[1][0] - parseInt(e.target.value) + 1));
  };
  const [tomonths, settomonths] = useState(Months[0]);
  const monthsselected = (e) => {
    setSelectedMonth(e.target.value);
    settomonths(Months[0].slice(Months[0].indexOf(e.target.value)));
  };
  useEffect(() => {
    // // // console,log(selectedtoYear, selectedYear)
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(selectedMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    const verifyDetails = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofile + `${token}`
        );
        // // // console,log(response.data);
        if (response.data.existing_user.phone != " ") {
          // navigate("/")
        }
      } catch (error) {
        // // // console,log(error);
      }
    };

    const postProfileStatus = async () => {
      try{
        const reqbody = {
          profilestatus : "/signup/add-education"
        }
        const response = await axios.post(apiUrl.profile.editprofile + `${token}`,reqbody);
        if(response.status === 200){
        }
      }catch(error){
        //// console.log(error)
      }
    }

    postProfileStatus();

    verifyDetails();

    fetchUniversities();
  }, []);

  const submited = async (data) => {
    setLoading(!loading);
    const educationDetails = {
      levelofedu: selectedLevelOfEdu,
      field: selectedFieldOfStudy,
      school: selectedSchool,
      city: data.City,
      country: selectedCountry,
      fromMonth: data.Month,
      fromYear: data.year,
      graduated : check,
    };
    
    if(check){
      const toDate = new Date(`${data.toyear}-${data.toMonth}-01`);
      const today = new Date();
      if (toDate > today) {
        setGraduatedError("Graduated date should not be greater than current date");
        setLoading(false);
        return;
      } 
    }
    educationDetails.toMonth = data.toMonth;
    educationDetails.toYear = data.toyear;

    const education = [];
    education.push(educationDetails);
    //// // // console,log(educationDetails);
    
    const reqbody = {
      education: education,
      // profilestatus: "/signup/add-education",
    };
    try {
      const response = await axios.post(apiUrl.profile.addexp + token, reqbody);
      //// // // console,log(response)
      if (response.status === 200) {
        //// // // console,log(response.body);
        navigate(`/signup/education-review`);
      }
    } catch (err) {
      //// // // console,log(err);
    }

    window.scroll(0, 0);
    setLoading(!loading);
  };

  const skip = () => {
    navigate(`/signup/education-review`);
    window.scroll(0, 0);
  };

  const handleLevelOfEduChange = (e) => {
    const levelofeducation = e.target.value;
    setSelectedLevelOfEdu(levelofeducation);
    const filtered1 = levelOfEducations.filter((obj) =>
      obj.toLowerCase().startsWith(levelofeducation.toLowerCase())
    );

    const filtered2 = levelOfEducations.filter((obj) =>
      obj.toLowerCase().includes(levelofeducation.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);
    // if(levelofeducation==""){
    //   setFilteredLevelofEducations([]);
    // }
    setFilteredLevelofEducations(finalFiltered);
  };

  const handleLevelOfEduSelect = (name) => {
    setSelectedLevelOfEdu(name);
    setFilteredLevelofEducations([]);
  };

  const [lengthexceeded, setlengthexceeded] = useState(false);
  const handleSchoolChange = (e) => {
    const inputSchool = e.target.value;
    if (lengthexceeded > 100) {
      setlengthexceeded(true);
    } else {
      setlengthexceeded(false);
    }

    setSelectedSchool(inputSchool);
    // Filter schools based on input
    // You would replace usSchools with the array containing your school names
    // For the purpose of this example, let's assume we have a dummy array called usSchools

    const filtered1 = universities.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchool.toLowerCase())
    );

    const filtered2 = universities.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchool.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchool === "") {
      setSelectedSchoolImage("");
    }
    if (finalFiltered.length > 0) {
      // setSelectedSchoolImage(filtered[0].s3Link);
    } else {
      setSelectedSchoolImage(DefaultEducation);
    }
    setFilteredSchools(finalFiltered);
  };

  const handleSchoolSelect = (obj) => {
    setSelectedSchool(obj.Name);
    setSelectedSchoolImage(obj.s3Link);
    setFilteredSchools([]);
  };

  useEffect(() => {
    // // // console,log(selectedtoYear, selectedYear)
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(selectedMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filteredSchools.length > 0 &&
        !document.getElementById("schoolDropdown").contains(event.target)
      ) {
        setFilteredSchools([]);
      }
      if (
        filteredLevelofEducations.length > 0 &&
        !document
          .getElementById("levelofeducationDropdown")
          .contains(event.target)
      ) {
        setFilteredLevelofEducations([]);
      }
      if (
        filteredCounties.length > 0 &&
        !document.getElementById("countryDropdown").contains(event.target)
      ) {
        setfilteredCounties([]);
      }
      if (
        filteredFieldOfStudy.length > 0 &&
        !document.getElementById("fieldOfStudyDropdown").contains(event.target)
      ) {
        setFilteredFieldOfStudy([]);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setFilteredSchools([]);
        setFilteredLevelofEducations([]);
        setfilteredCounties([]);
        setFilteredFieldOfStudy([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [
    filteredSchools,
    filteredLevelofEducations,
    filteredCounties,
    filteredFieldOfStudy,
  ]);
  const [selectedCountry, setselectedCountry] = useState("");

  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  const handleFieldOfStudyChange = (e) => {
    const fieldofstudy = e.target.value;
    setSelectedFieldOfStudy(fieldofstudy);
    const filtered1 = fieldOfStudy.filter((obj) =>
      obj.toLowerCase().startsWith(fieldofstudy.toLowerCase())
    );

    const filtered2 = fieldOfStudy.filter((obj) =>
      obj.toLowerCase().includes(fieldofstudy.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);
    // if(levelofeducation==""){
    //   setFilteredLevelofEducations([]);
    // }
    setFilteredFieldOfStudy(finalFiltered);
  };

  const handleFieldOfStudySelect = (name) => {
    setSelectedFieldOfStudy(name);
    setFilteredFieldOfStudy([]);
  };
  const [edulen, setedulen] = useState(0);

  useEffect(() => {
    const edulength = async () => {
      const response = await axios.post(apiUrl.profile.getprofile + token);
      if (response.status === 200) {
        setedulen(response?.data?.existing_profile?.education?.length);
      }
    };

    edulength();
  }, []);
  const [addorg, setaddorg] = useState(false);
  const handleaddorg = () => {
    setaddorg(!addorg);
  };

  if (token === null) {
    return <Login />;
  } else {
    return (
      <div>
        {addorg && (
          <AddOrganization
            addorg={addorg}
            setaddorg={setaddorg}
            title="School"
          />
        )}
        <div>
          <div className=" h-1 w-full flex">
            <div className="h-full bg-[#0052ff] w-[50%]"></div>
            <div className="h-full "></div>
          </div>
          <div className="m-4 ">
            <div className="py-3 text-2xl font-[550] font-sans">
              Add Education
            </div>

            <div>
              <form
                action=""
                onSubmit={handleSubmit(submited)}
                className="flex flex-col gap-4 relative"
              >
                <div>
                  <label htmlFor="school" className="font-[550]">
                    School <span className="text-red-500">*</span>
                  </label>
                  <div
                    className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 bg-white text-black`}
                  >
                    {selectedSchoolImage && (
                      <img
                        src={selectedSchoolImage}
                        alt="School Logo"
                        className="h-6 w-6 ml-2"
                      />
                    )}
                    <input
                      type="text"
                      className="focus:outline-none w-[100%]"
                      name="school"
                      {...register("school", {
                        maxLength: {
                          value: 100,
                          message: "School must not exceed 100 characters",
                        },
                        pattern: {
                          value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                          message:
                            "School can only contain alphanumeric characters, spaces, underscores, and hyphens",
                        },
                      })}
                      value={selectedSchool}
                      onChange={handleSchoolChange}
                    />
                  </div>
                  {errors.school && (
                    <p className="text-red-500 text-sm">
                      {errors.school.message}
                    </p>
                  )}
                  <p>
                    Did not find your School name?{" "}
                    <span
                      className=" font-[550] text-[#0052ff] cursor-pointer "
                      onClick={() => {
                        handleaddorg();
                        // closemodal();
                      }}
                    >
                      {" "}
                      Add here{" "}
                    </span>
                  </p>

                  {/* Dropdown menu for filtered schools */}
                  {filteredSchools.length > 0 && (
                    <div
                      id="schoolDropdown"
                      className="absolute z-10 mt-1 bg-white border max-h-[150px] overflow-y-auto rounded-md w-[100%] shadow-lg"
                    >
                      {filteredSchools.map((obj, index) => (
                        <div
                          key={index}
                          className="cursor-pointer flex hover:bg-gray-100 p-2"
                          onClick={() => handleSchoolSelect(obj)}
                        >
                          <img
                            src={obj.s3Link}
                            alt="School Logo"
                            className="h-6 w-6 mr-2 rounded-sm"
                          />
                          {/* Display School logo */}
                          {obj.Name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="levelofedu" className=" font-[550]">
                    Level of education <span className=" text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                    name="levelofedu"
                    required
                    {...register("levelofedu", {
                      maxLength: {
                        value: 100,
                        message:
                          "Level of education must not exceed 100 characters",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                        message:
                          "Level of education can only contain alphanumeric characters, spaces, underscores, and hyphens",
                      },
                    })}
                    value={selectedLevelOfEdu}
                    onChange={handleLevelOfEduChange}
                  />
                  {errors.levelofedu && (
                    <p className="text-red-500 text-sm">
                      {errors.levelofedu.message}
                    </p>
                  )}
                  {filteredLevelofEducations.length > 0 && (
                    <div
                      id="levelofeducationDropdown"
                      className="absolute z-10 mt-1 bg-white border rounded-md w-[100%]  max-h-[150px] overflow-y-auto shadow-lg"
                    >
                      {filteredLevelofEducations.map((obj, index) => (
                        <div
                          key={index}
                          className="cursor-pointer flex hover:bg-gray-100 p-2"
                          onClick={() => handleLevelOfEduSelect(obj)}
                        >
                          {obj}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className=" relative">
                  <label htmlFor="field" className=" font-[550]">
                    Field of study
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                    name="field"
                    {...register("field", {
                      maxLength: {
                        value: 100,
                        message:
                          "Field of study must not exceed 100 characters",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                        message:
                          "Field of study can only contain alphanumeric characters, spaces, underscores, and hyphens",
                      },
                    })}
                    value={selectedFieldOfStudy}
                    onChange={handleFieldOfStudyChange}
                  />
                  {errors.field && (
                    <p className="text-red-500 text-sm">
                      {errors.field.message}
                    </p>
                  )}
                  {filteredFieldOfStudy.length > 0 && (
                    <div
                      id="fieldOfStudyDropdown"
                      className="absolute z-10 mt-1 bg-white border max-h-[150px] overflow-y-auto rounded-md w-[100%] shadow-lg"
                    >
                      {filteredFieldOfStudy.map((obj, index) => (
                        <div
                          key={index}
                          className="cursor-pointer flex hover:bg-gray-100 p-2"
                          onClick={() => handleFieldOfStudySelect(obj)}
                        >
                          {obj}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* <div className="flex flex-col gap-4"> */}
                <div>
                  <label htmlFor="city" className="font-[550]">
                    City
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 border-[1.5px] rounded-md my-2 bg-white text-black`}
                    name="city"
                    {...register("City", {
                      maxLength: {
                        value: 100,
                        message: "City must not exceed 100 characters",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                        message:
                          "City can only contain alphanumeric characters, spaces, underscores, and hyphens",
                      },
                    })}
                  />
                   {errors.City && (
                          <p className="text-red-500 text-sm">
                            {errors.City.message}
                          </p>
                        )}
                </div>
                <div>
                  <label htmlFor="city" className="font-[550]">
                    Country <span className="text-red-500">*</span>
                  </label>
                  <div className="w-[100%] relative">
                    <div>
                      <input
                        type="text"
                        className={`w-full p-2 border-[1.5px] rounded-md my-2 `}
                        name="city"
                        value={selectedCountry}
                        {...register("Country", {
                          maxLength: {
                            value: 100,
                            message: "Country must not exceed 100 characters",
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                            message:
                              "Country can only contain alphanumeric characters, spaces, underscores, and hyphens",
                          },
                        })}
                        onChange={handleCityChange}
                      />
                      {/* Dropdown menu for filtered cities */}
                      {errors.Country && (
                          <p className="text-red-500 text-sm">
                            {errors.Country.message}
                          </p>
                        )}
                      {filteredCounties.length > 0 && (
                        <div
                          id="countryDropdown"
                          className="absolute z-10 mt-1 bg-white border w-[100%] max-h-[150px] overflow-y-auto   rounded-md shadow-lg"
                        >
                          {filteredCounties.map((city, index) => (
                            <div
                              key={index}
                              className="cursor-pointer hover:bg-gray-100 p-2"
                              onClick={() => handleCitySelect(city)}
                            >
                              {city}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col gap-2">
                  {/* education years */}
                  <div>
                    <label htmlFor="city" className="font-[550] block">
                      From <span className="text-red-500">*</span>
                    </label>
                    <div className="flex justify-between">
                    <select
                        className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                        name="Month"
                        required
                        {...register("year")}
                        value={selectedYear}
                        onChange={yearselected}
                      >
                        <option value="">Year</option>
                        {Months[1].map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <select
                        className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                        name="Month"
                        {...register("Month")}
                        value={selectedMonth}
                        onChange={monthsselected}
                      >
                        <option value="">Month</option>
                        {Months[0].map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      
                    </div>
                  </div>

                  <div>
                    <label htmlFor="city" className="font-[550] block">
                      To (or expected) <span className="text-red-500">*</span>
                    </label>
                    <div className="flex justify-between">
                    <select
                        className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                        name="Month"
                        required
                        {...register("toyear")}
                        value={selectedtoYear}
                        onChange={(e) => setSelectedtoYear(e.target.value)}
                      >
                        <option value="">Year</option>
                        {toyears.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <select
                        className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                        name="Month"
                        {...register("toMonth")}
                        value={selectedtoMonth}
                        onChange={(e) => setSelectedtoMonth(e.target.value)}
                      >
                        <option value="">Month</option>
                        {tomonths.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      
                    </div>
                  </div>
                </div>
                <fieldset className="items-center gap-2 my-2 py-2">
                            <Label
                              htmlFor="graduated"
                              className="flex text-black font-[550] text-md gap-4 items-center"
                            >
                              Graduated
                              <Radio
                                id="graduated"
                                name="graduated"
                                className="text-black"
                                checked={check}
                                onClick={checked}
                              />
                            </Label>
                          </fieldset>
                          {graduatedError && (
                            <div className="text-red-500">{graduatedError}</div>
                          )}
                <div className="flex flex-row-reverse  w-full justify-between">
                  {loading ? (
                    <FaSpinner
                      size={30}
                      className="text-[#0052ff] animate-spin"
                    />
                  ) : (
                    <div>
                      {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                      <button
                        type="submit"
                        className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                      >
                        Next
                      </button>
                    </div>
                  )}
                
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
