import React, { useState, useEffect,useContext } from "react";
import AccessManagement from "./AccessManagement";
import ComponentOrder from "../components/ComponentOrder";
import ChangePassword from "../components/ChangePassword";
import apiUrl from "../apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import ChangePersonalDetails from "../components/ChangePersonalDetails";
import ChangeVisibility from "../components/ChangeVisibility";
import DeleteAccount from "../components/DeleteAccount";
import { logincontext } from "../contexts/Logincontext";


import ChangePasswordIcon from "../images/Change Password.webp";
import { ImProfile } from "react-icons/im";
import { IoShieldHalfOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function Settings({
  url,
  email,
  userId,
}) {
  const token = localStorage.getItem("token");

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  

  const subtab = {
    "Change Password": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    ),
    "Personal Details": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
        />
      </svg>
    ),
    "Privacy Settings": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
        />
      </svg>
    ),
    "Delete Account": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
        />
      </svg>
    ),
  };

  const [subsettting, setsubsetting] = useState(Object.keys(subtab)[0]);
  function rendercomponent(subsettings) {
    switch (subsettings) {
     
      case "Access Management":
        return <AccessManagement url={url}  />;
      case "Change Password":
        return (
          <ChangePassword url={url}  />
        );
      case "Personal Details":
        return <ChangePersonalDetails url={url} loggedInUser={loggedInUser} changeloggedInUser={changeloggedInUser}/>;
      case "Privacy Settings":
        return <ChangeVisibility email={email} />;
      case "Delete Account":
        return (
          <DeleteAccount url={url}  email={email} />
        );
    }
  }

  const length = 100 / Object.keys(subtab).length;

  if (token === null) {
    return <Login />;
  } else
    return (
      <div
        className={`flex justify-center w-full min-h-[90vh] `}
      >
        <div
          className={`max-w-screen-xl lg:w-[100%]  w-full h-[100%] justify-between flex lg:flex-row flex-col gap-4 lg:p-4  `}
        >
          <div className=" w-full min-h-[90vh]  flex lg:flex-row lg:gap-4 gap-1 flex-col  ">
            {/* <VerticalTabs url={url}  /> */}
            <div className="lg:w-[25%] w-[100%] bg-white border-[1px]  rounded-md h-fit  text-nowrap lg:flex-col flex-row flex overflow-x-auto ">
              <div className=" hidden lg:block">
                <div className=" text-lg font-[550] py-2 px-4 border-b-[1px] ">
                  Settings{" "}
                </div>
              </div>
              <div className=" md:py-2 flex lg:flex-col flex-row w-full items-center justify-center ">
                {Object.keys(subtab).map((item, key) => (
                  <li
                    key={key}
                    className={` lg:text-base text-sm list-none w-full w-[${length}] py-2    px-4  flex gap-2 items-center justify-center lg:justify-start cursor-pointer  hover:bg-blue-100 ${
                      subsettting === item
                        ? // ? " bg-blue-200 lg:border-r-2 border-b-2 lg:border-b-0 border-[#0052ff] "
                          " bg-blue-100 lg:border-r-[1.5px] lg:border-b-0 border-b-[1.5px] border-[#0052ff] "
                        : ""
                    } `}
                    onClick={() => setsubsetting(item)}
                  >
                    {subtab[item]} <span className=" sm:inline-block hidden">{item}</span>
                  </li>
                ))}
              </div>
            </div>
            <div className="w-full border-[1px] rounded-md   mt-4 lg:mt-0  ">
              {rendercomponent(subsettting)}
            </div>
          </div>
        </div>
      </div>
    );
}
