import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import apiUrl from "../../apiConfig";
import DefaultCompany from "../../images/DefaultCompany.png";
import DefaultEducation from "../../images/DefaultEducation.png";
import Requests from "./Requests";
import Sent from "./Sent";
import Spinnerpage from "../Spinnerpage";
import ProfileTile from "./ProfileTile";
import { useNavigate } from "react-router-dom";

import { logincontext } from "../../contexts/Logincontext";

export default function ReqSent({ refresh, setrefresh }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const rese = ["Requests", "Sent"];
  const [reqsent, setreqsent] = useState(rese[0]);
  const [requests, setrequests] = useState([]);
  const [sent, setsent] = useState([]);
  const [api, setapi] = useState(false);
  const [loading, setloading] = useState(false);

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const getUserDetails = async () => {
    try {
      const response = await axios.post(
        apiUrl.profile.getprofiletileinformation + token,
        {
          requestIds:
            loggedInUser?.complete_profile.profile.connections.requests,
          sentIds: loggedInUser?.complete_profile.profile.connections.sent,
        }
      );
      if (response.status === 200) {
        // // console,log(response.data.existingUser._id);
        setsent(response.data.sent);
        setrequests(response.data.requests);
      }
    } catch (error) {
      // // console,log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [loggedInUser?.complete_profile?.profile?.connections]);

  const reqs = async () => {
    setloading(true);
    try {
      const resp = await axios.post(apiUrl.connections.getrequests + token);
      if (resp && resp.status === 200) {
        // // console,log(resp.data.sent);
        // // console,log(resp.data.requests);
        // // console,log(resp.data.connected);
        setrequests(resp.data.requests);
        setsent(resp.data.sent);
      }
    } catch (err) {
      // console,log(err);
    }
    setloading(false);
  };

  useEffect(() => {
    reqs();
  }, [refresh, api]);

  const [edushortlist, setCollegeProfiles] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  useEffect(() => {
    const getprofile = async () => {
      try {
        setLoading(true);
        // // // console,log("dashboard: ", reqbody, { token: token });
        const response = await axios.post(
          apiUrl.profile.getrandomprofiles + token,
          {
            page: 1,
            limit: 5,
          }
        );
        // // console,log("This");
        // // console,log(response.data);
        //// // // console,log(response.status);
        // // // console,log(response);
        if (response.status === 401) {
          // localStorage.removeuser("token");
          // navigate("/");
        } else if (response.status === 200) {
          // // console,log(response.data);
          const profiles = response.data.profiles;

          const profilelist = [];
          for (let i = 0; i < profiles.length; i++) {
            const profile = profiles[i];
            const newProfile = {
              profilepic: profile.Profilepic,
              Name: profile.Firstname + " " + profile.Lastname,
              currentRole: profile.headline,
              latestEducation: profile.LatestEducation,
              latestExperience: profile.LatestExperience,
              Location: profile.Location,
              url: profile.url,
            };
            profilelist.push(newProfile);
          }
          setCollegeProfiles(response.data.profiles);

          if (response.data.id) {
            setCurrentUserId(response.data.id);
          }
          // // // console,log(response.data.token);
        }
      } catch (err) {
        // // // console,log("error encountered");
        // localStorage.removeuser("token");
        // navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      getprofile();
    }
  }, []);

  const [requestssent, setRequestsSent] = useState([]);
  const connectapi = async (url, userId) => {
    try {
      const resp = await axios.post(apiUrl.connections.request + token, {
        url: url,
      });
      //// console.log(resp);
      if (resp && resp.status === 200) {
        setsent([...sent, resp.data.reqdetails]);
        setRequestsSent([...requestssent, url]);
        // setCollegeProfiles(edushortlist.filter((item) => item.url !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile: {
            ...loggedInUser.complete_profile,
            connections: {
              ...loggedInUser.connections,
              sent: [...loggedInUser.connections.sent, userId],
            },
          },
        });

        // setrefresh(!refresh);
      }
    } catch (err) {
      // // console,log(err.message);
    }
  };

  const withdrawapi = async (url, userId) => {
    try {
      const resp = await axios.post(
        apiUrl.connections.withdrawrequest + token,
        {
          url: url,
        }
      );
      if (resp && resp.status === 200) {
        setRequestsSent(requestssent.filter((item) => item !== url));
        setsent(sent.filter((item) => item.url !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile: {
            ...loggedInUser.complete_profile,
            profile: {
              ...loggedInUser.complete_profile.profile,
              connections: {
                ...loggedInUser.connections,
                sent: loggedInUser.complete_profile.profile.connections.sent.filter(
                  (id) => id !== userId
                ),
              },
            },
          },
        });
      }
    } catch (err) {
      // // console,log(err.message);
      // console.log(err);
    }
  };

  // {
  //   console.log(loggedInUser);
  // }

  const accept = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.accept + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setrequests(requests.filter((item) => item.url !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile: {
            ...loggedInUser.complete_profile,
            connections: {
              connections: [
                ...loggedInUser.complete_profile.profile.connections
                  .connections,
                resp.data.receiverId,
              ],
              requests: requests.filter((item) => item.url !== url)
            },
          },
        });
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };
  const reject = async (url, userId) => {
    try {
      const resp = await axios.post(apiUrl.connections.reject + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        // // console,log(resp);

        // setapi(!api);
        // console.log(sent)
        if (sent.find((item) => item.url === url))
          setsent(sent.filter((item) => item.url !== url));
        if (requests.find((item) => item.url === url))
          setrequests(requests.filter((item) => item.url !== url));
        // set t(requestssent.filter((item) => item !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile: {
            ...loggedInUser.complete_profile,
            connections: {
              ...loggedInUser.connections,
              sent: loggedInUser.connections.sent.filter((id) => id !== userId),
            },
          },
        });
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const findimage = (name, type) => {
    const company = companies.find((obj) => obj.Name === name);
    const comimage = company ? company.s3Link : DefaultCompany;
    const university = universities.find((obj) => obj.Name === name);
    const schimage = university ? university.s3Link : DefaultEducation;
    return type === "job" ? comimage : schimage;
  };

  return (
    <div>
      {" "}
      <div className="bg-white w-full border-[1px]  md:rounded-md px-4">
        <div className=" flex gap-1">
          <h1
            className={` text-lg font-[550] mb-4 py-2 px-3 hover:text-[#0052ff] hover:bg-blue-50 duration-100 cursor-pointer ${
              reqsent === "Requests" &&
              "text-[#0052ff] border-b-[1.5px] border-[#0052ff]"
            }`}
            onClick={() => setreqsent("Requests")}
          >
            Received
          </h1>
          <h1
            className={` text-lg font-[550] mb-4 py-2 px-3 hover:text-[#0052ff] hover:bg-blue-50 duration-100 cursor-pointer ${
              reqsent === "Sent" &&
              "text-[#0052ff] border-b-[1.5px] border-[#0052ff]"
            }`}
            onClick={() => setreqsent("Sent")}
          >
            Sent
          </h1>
        </div>
        {reqsent === "Requests" ? (
          <div className="mb-2">
            {loading ? (
              <div className="w-full py-4 flex justify-center items-center">
                <div className="flex space-x-2 w-20">
                  <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
                  <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
                  <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
                </div>
              </div>
            ) : (
              <Requests
                requests={requests}
                accept={accept}
                reject={reject}
                findimage={findimage}
              />
            )}
          </div>
        ) : (
          <div className="mb-2">
            {loading ? (
              <div className="w-full py-4 flex justify-center items-center">
                <div className="flex space-x-2 w-20">
                  <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
                  <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
                  <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
                </div>
              </div>
            ) : (
              <Sent sent={sent} reject={reject} findimage={findimage} />
            )}
          </div>
        )}
      </div>
      <div className="w-full my-2 border-[1px] p-2 md:rounded-md bg-white ">
        <div className="">
          {" "}
          <div className="text-lg px-2 pb-4 font-[550]">
            Expand your Network
          </div>
        </div>
        {Loading ? (
          <Spinnerpage />
        ) : (
          <div className="w-full justify-center users-stretch px-2">
            {edushortlist &&
              edushortlist.map((user, index) => (
                <div
                  key={index}
                  className=" border-b-[1px] p-2 h-full flex md:flex-row flex-col justify-between md:items-start py-2 w-full"
                >
                  <ProfileTile user={user} findimage={findimage} />
                  {requestssent.includes(user.url) ? (
                    <button
                      className="text-white border-[1.5px] bg-[#0052ff]   cursor-pointer font-[550] py-[0.125rem] md:px-4 px-3 text-xs md:text-sm md:text-left text-center rounded-3xl my-2 md:my-0 h-fit w-fit"
                      onClick={() => {
                        withdrawapi(user.url, user.UserId);
                        // reqs();
                      }}
                    >
                      Requested
                    </button>
                  ) : (
                    <button
                      className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-[0.125rem] md:px-4 px-3 text-xs md:text-sm md:text-left text-center rounded-3xl my-2 md:my-0 h-fit w-fit"
                      onClick={() => {
                        connectapi(user.url, user.UserId);
                        // reqs();
                      }}
                    >
                      Connect
                    </button>
                  )}
                </div>
              ))}

            {edushortlist && edushortlist.length > 0 && (
              <div className="flex justify-center mt-4">
                <button
                  className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                  onClick={() => {
                    navigate("/network/newpeople");
                  }}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
