import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiUrl from "../apiConfig";
import Post from "../components/Post";
import { logincontext } from "../contexts/Logincontext";
import Spinnerpage from "../components/Spinnerpage";

export default function SinglePostPage() {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [post, setPost] = useState({});
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const getpost = async () => {
    try {
      const response = await axios.post(apiUrl.feed.getpost + token + "/" + id);
      if (response.status === 200) {
        // console.log(response.data);
        setPost(response.data);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getpost();
  }, []);
  return post && Object.keys(post).length > 0 ? (
    <div className="flex items-center min-h-screen flex-col pt-4">
      <div className="w-[100%] max-w-screen-xl flex flex-row gap-2 px-4">
        <div className="w-[60%] flex flex-col gap-4">
          <Post post={post} loggedInUser={loggedInUser} index={0} />
        </div>
      </div>
    </div>
  ) : (
    <Spinnerpage />
  );
}
