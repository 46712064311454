import React, { useEffect } from "react";
import { useState } from "react";
import { MdOutlinePublic } from "react-icons/md";
import { VscPerson } from "react-icons/vsc";
import { IoMdSave } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiUrl from "../apiConfig";
import { FaSpinner } from "react-icons/fa";

import ConfirmAccessControlsModal from "./ConfirmAccessControlsModal";

export default function AccessTable({ url }) {
  const [toggle, setToggle] = useState(true);
  const [sections, setSections] = useState({});
  const [showsavemodal, setShowSaveModal] = useState(false);
  const [showresetmodal, setShowResetModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [passablesections, setPassableSections] = useState({});
  const navigate = useNavigate();

  // const [sampleSections,setSampleSections] = useState({recognitions : [true,true,true]});

  const token = localStorage.getItem("token");

  const handleSaveSections = () => {
    try {
      const response = axios.post(apiUrl.profile.editprofile + `${token}`, {
        componentOrder: sections,
      });
      if (response) {
        // toast.success("Access Controls Saved");
        navigate("/" + url);
      }
    } catch (error) {
      // // // console,log(error.response.data);
    }
  };

  const resetToggles = () => {
    setShowResetModal(!showresetmodal);
  };

  const handleResetSections = () => {
    try {
      const updatedSections = { ...sections };
      Object.keys(updatedSections).map((key) => {
        updatedSections[key][0] = true;
        updatedSections[key][1] = true;
        updatedSections[key][2] = true;
      });

      const response = axios.post(apiUrl.profile.editprofile + `${token}`, {
        componentOrder: updatedSections,
      });
      if (response) {
        setOpen(!open);
        setShowResetModal(!showresetmodal);
        setSections(updatedSections);
        setPassableSections(updatedSections);
        navigate("/" + url);
        // toast.success("Access Controls Set to Reset");
      }
    } catch (error) {
      // // // console,log(error.response.data);
    }
  };

  

  useEffect(() => {
    const getSections = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofile + `${token}`
        );
        if (response) {
          // // // // console,log(response.data.existing_profile.componentOrder);
          setSections(response.data.existing_profile.componentOrder);
          setLoading(false);
        }
      } catch (error) {
        // // // console,log(error.response.data);
      }
    };
    getSections();
  }, []);

  const changeToggle = (key, index) => {
    const updatedSections = { ...sections }; // Create a copy of the state object
    updatedSections[key][index] = !updatedSections[key][index]; // Update the copy
    setSections(updatedSections); // Set the state with the updated copy
  };

  return (
    <div className={`bg-white w-full  rounded-xl p-4`}>
      <div>
        {showresetmodal && (
          <ConfirmAccessControlsModal
            functionality="reset"
            handleResetSections={handleResetSections}
            
            handleno={resetToggles}
          />
        )}
      </div>
        <div className="font-[550] text-xl">
          Customise the visibility of sections
        </div>
        <div className="text-sm py-2">
          Toggle the switch of different sections to show or hide the section on
          dashboard
        </div>
      <div
        className={`w-full  justify-between flex md:flex-row flex-col  text-black `}
      >
        <div className={`w-full mt-2  `}>
          <div
            className={`max-w-screen-xl overflow-x-auto w-fulltext-black`}
          >
            <table
              className={` w-full divide-y divide-gray-200 bg-white`}
            >
              <thead>
                <tr
                  className={`h-[3rem] text-black bg-gray-100`}
                >
                  <th
                    className={`min-w-[160px] font-[550] text-lg text-black`}
                  >
                    Name of the section
                  </th>
                  <th
                    className={`min-w-[160px] font-[550] text-lg text-black`}
                  >
                    <div className="w-full h-full flex items-center justify-center">
                      <MdOutlinePublic /> Public View
                    </div>
                  </th>
                  <th
                    className={`min-w-[160px] font-[550] text-lg text-black`}
                  >
                    <div className="w-full font-[550] h-full flex items-center justify-center">
                      <VscPerson /> Recruiter View
                    </div>
                  </th>
                  <th
                    className={`min-w-[160px] text-lg text-black`}
                  >
                    <div className="w-full font-[550] h-full flex items-center justify-center">
                      <RiGitRepositoryPrivateFill /> Private View
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(sections).map((key) => (
                  (
                    key !== "Recognitions" &&
                  <tr
                    key={key}
                    className={`h-[3rem] border-b-[1px]  bg-white text-black hover:bg-gray-100`}
                  >
                    <td>
                      {" "}
                      <div className="w-full h-full flex items-center justify-center ">
                        {key}
                      </div>
                    </td>
                    <td>
                      <div className="w-full h-full flex items-center justify-center ">
                        <Switch
                          checked={sections[key][0]}
                          onClick={() => changeToggle(key, 0)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="w-full h-full flex items-center justify-center ">
                        <Switch
                          checked={sections[key][1]}
                          onClick={() => changeToggle(key, 1)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="w-full h-full flex items-center justify-center ">
                        <Switch
                          checked={sections[key][2]}
                          onClick={() => changeToggle(key, 2)}
                        />
                      </div>
                    </td>
                  </tr>
                  )
                ))}
              </tbody>
            </table>
          </div>
          {loading && (
            <div className="flex justify-center items-center h-[90vh]">
              <FaSpinner
                className="animate-spin text-[#0052ff] mr-2"
                size={40}
              />
            </div>
          )}
          <br />
          <div className="flex gap-2 justify-end text-black px-4">
            <div
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
              onClick={handleSaveSections}
            >
              <div className="flex gap-1 justify-center items-center">
                {/* <IoMdSave size={20} /> */}
                 <p>Save</p>
              </div>
            </div>
            <div
              onClick={resetToggles}
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
            >
              <div className="flex gap-1 justify-center items-center">
                {/* <IoReload size={20} /> */}
                 <p>Reset</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
