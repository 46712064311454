import React, { useContext } from "react";
import { GoPlus } from "react-icons/go";
import Certifications from "../Certifications";
import { IoIosArrowUp } from "react-icons/io";
import DefaultCompany from "../../images/DefaultCertificate.png";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { useState, useEffect } from "react";
import { logincontext } from "../../contexts/Logincontext";

export default function CertificationComponent({
  publicview,
  handleCertificationModal,
  dash,
  api,
  setapi,
  visibleItemsCertificates,
  showallCertificates,
  setshowallCertificates,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
  }, []);

  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;
    experience.forEach((item) => {
      const role = {
        Name: item.Name,
        issuemonth: item.issuedDate ? item.issuedDate.split("-")[0] : "",
        issueyear: item.issuedDate ? item.issuedDate.split("-")[1] : "",
        expirymonth: item.expiryDate ? item.expiryDate.split("-")[0] : "",
        expiryyear: item.expiryDate ? item.expiryDate.split("-")[1] : "",
        credentialid: item.credentialid,
        credentialurl: item.credentialurl,
        Skills: item.Skills,
        link: item.link,
        _id: item._id,
      };
      if (lastCompany && lastCompany.Issuer === item.Issuer) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          Issuer: item.Issuer,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });

    return result;
  };

  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const sortCertByFrom = (experience) => {
    return experience.sort((a, b) => {
      const [monthA, yearA] = a.issuedDate.split("-");
      const [monthB, yearB] = b.issuedDate.split("-");
      const dateA = new Date(yearA, monthMap[monthA]);
      const dateB = new Date(yearB, monthMap[monthB]);
      return dateB - dateA; // Sorting in decreasing order
    });
  };
  const sortedCert = sortCertByFrom(
    dash.complete_profile.profile.professionalDetails.certifications
  );
  const transformedExperience = transformExperienceData(sortedCert);
  // // console,log(transformedExperience)

  const visiblejobitem = showallCertificates
    ? transformedExperience
    : transformedExperience.slice(0, 2);
  // Additional logic or side effects can be added here if needed

  return (
    <div
      className={`w-full  border-[1px] px-4 py-3 md:pt-5 md:px-6 md:rounded-md  bg-white text-black`}
    >
      <div className="text-lg font-[550] flex justify-between ">
        Certifications{" "}
        {dash?.isloggedinuser && (
          <div className="flex gap-2">
            {" "}
            <div
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
              onClick={() => handleCertificationModal(null)}
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      <div>
        {visiblejobitem.map((item, key) => {
          const company = companies.find((obj) => obj.Name === item.Issuer);
          // Get the image link if the university is found
          const image = company ? company.s3Link : DefaultCompany;

          return (
            <Certifications
              key={key}
              props={{
                token: token,
                item: item,
                loggedInUser: loggedInUser,
                changeloggedInUser: changeloggedInUser,
                publicview: !dash?.isloggedinuser,
                api: api,
                setapi: setapi,
                length: transformedExperience.length,
                key: key,
                image: image,
              }}
            />
          );
        })}
      </div>
      {transformedExperience.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center  border-slate-300">
          {/* <hr className="w-[30%]" /> */}
          <button
            className=" border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setshowallCertificates(!showallCertificates)}
          >
            {showallCertificates
              ? "Show Less"
              : `Show All ${transformedExperience.length}`}{" "}
            <IoIosArrowUp
              className={showallCertificates ? "rotate-0" : "rotate-180"}
            />
          </button>
          {/* <hr className="w-[30%]" /> */}
        </div>
      )}
    </div>
  );
}
