// src/context/WebSocketProvider.js
import React, { useState, useEffect, useCallback } from "react";
import { WebSocketContext } from "./WebSocketContext";
import io from "socket.io-client";
import apiUrl from "../apiConfig";
import axios from "axios";
import { useLocation } from "react-router-dom";

const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const token = localStorage.getItem("token");

  const [selectedUser, setSelectedUser] = useState(null);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const Location = useLocation();
  const connectSocket = async () => {
    // // console.log("Connecting to socket server...");
    if (token) {
      // // console.log("Token:", token);
      try {
        const resp = await axios.post(apiUrl.checktoken + token);

        const socketInstance = io(apiUrl.websocket.connection, {
          query: { token: resp.data.newToken, type: "chat" },
        });

        socketInstance.on("connect", () => {
          //// console.log("Connected to socket server");
        });

        socketInstance.on("disconnect", () => {
          //// console.log("Disconnected from socket server");
        });

        socketInstance.on("numberofnotifications", (notifications) => {
          setNumberOfNotifications(numberOfNotifications + notifications);
        });

        setSocket(socketInstance);
      } catch (err) {
        // console.error("Failed to check token:", err);
      }
    }
  };


  const disconnectSocket = () => {
    if (socket) {
      //// console.log("Disconnecting from socket server...");
      socket.disconnect();
      setSocket(null);
    }
  };

  const changeUser = (user) => {
    if (numberOfNotifications > 0) {
      setNumberOfNotifications(numberOfNotifications - user.unSeen);
    }
  };

  // useEffect(() => {
  //   if (token) {
  //     connectSocket();

  //     return () => {
  //       disconnectSocket();
  //     };
  //   }
  // }, [token]);

  const setSocketCallback = useCallback(
    (event, callback) => {
      if (socket) {
        socket.on(event, callback);
      } else {
        // console.error("Socket not connected. Cannot set callback.");
      }
    },
    [socket]
  );

  const getSocket = useCallback(() => socket, [socket]);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        connectSocket,
        disconnectSocket,
        setSocketCallback,
        getSocket,
        selectedUser,
        setSelectedUser,
        numberOfNotifications,
        setNumberOfNotifications,
        changeUser,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
