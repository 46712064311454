import React, { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiUrl from "../apiConfig";
import ComponentOrder from "./ComponentOrder";
import { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { logincontext } from "../contexts/Logincontext";

export default function DocumentOrderModal({
  manageCompModal,
  url,
  openCompModal,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [documentOrder, setDocumentOrder] = useState({});
  const [doc, setDoc] = useState([]);
  const token = localStorage.getItem("token");
  const [param, setParam] = useState(false);
  const [resume, setresume] = useState({});
  const [sectionschema, setsectionschema] = useState([]);
  useEffect(() => {
    // console.log(loggedInUser.complete_profile.profile.documents);

    const resp = {};

    loggedInUser.complete_profile.profile.documents.forEach((key) => {
      resp[key.sectionName] = key.Documents;
    });
    setresume({
      Resume: resp["Resume"],
    });
    const newData = { ...resp };
    delete newData.Resume;
    // console.log(newData);
    // console.log(Object.keys(newData));
    setDocumentOrder(newData);
    setDoc(Object.keys(newData));
  }, []);
  const [loading, setloading] = useState(false);



  const documentsubmit = async () => {
   
    // // // console,log(reorderedComponentsObject);

    const Sections = {
      Resume: resume.Resume,
      ...documentOrder,
    };

    // // // console,log(Sections);
    const secs = [];
    Object.keys(Sections).forEach((element) => {
      let sample = {
        sectionName: element,
        Documents: Sections[element],
      };
      secs.push(sample);
    });
    // console.log(secs);
    const changed = {
      ...loggedInUser,
      complete_profile: {
        ...loggedInUser.complete_profile,
        profile: {
          ...loggedInUser.complete_profile.profile,
          documents: secs,
        },
      },
    };
    // console.log(changed);

    const reqbody = {
      documents: secs,
    };

    // console.log(reqbody);
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      if (response.status === 200) {
        // console.log(response);
        manageCompModal();
        changeloggedInUser(changed);
      }
    } catch (err) {
    } finally {
      setloading(false);
    }
    setloading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={manageCompModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="md:text-2xl text-lg font-[550]">
                      Sections Order
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={manageCompModal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>

                  <ComponentOrder
                    url={url}
                    componentOrder={documentOrder}
                    setComponentOrder={setDocumentOrder}
                    comp={doc}
                    setComp={setDoc}
                    handleArrangeClick={documentsubmit}
                    manageCompModal={manageCompModal}
                    loading={loading}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
