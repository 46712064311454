import React, { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import apiUrl from "../apiConfig";
import { useForm } from "react-hook-form";
import { Upload } from "keep-react";
import { toast } from "react-toastify";

export default function DeleteSectionModal(props) {
  const [open, setOpen] = useState(true);
  const closemodal = () => {
    // setOpen(false); // Set open state to false to close the modal
    props.handleDeleteSectionModal(props.sectionKey, props.sectionIndex,null);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [iserror, setiserror] = useState(false);
  const [value, setValue] = useState("");
  const token = localStorage.getItem("token");
  const handleDeleteSection = async () => {
    if (!isChecked) {
      setiserror(true);
      return;
    } else {
      try {
        const section = {
          data: props.sectionKey,
        };
        const response = await axios.post(
          apiUrl.documents.deletesection + token,
          section
        );
        // console.log(response);
        if (response.status === 200) {
          // console.log(response.data);
          // toast.success('Document Deleted Successfully')
          // // // console,log(response.data);
          props.handleDeleteSectionModal(props.sectionKey, props.sectionIndex,response.data.size);
          // // // console,log(response);
        }
      } catch (error) {
        // // // console,log(error);
      }
    }
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closemodal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl text-left transition-all mx-4">
                  <div
                    className={` md:w-[40vw]  w-[100%] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                  >
                    <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                      <div className="text-2xl font-[550]">Delete Section</div>
                      {/* <IoMdClose
                        size={30}
                        onClick={closemodal}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      /> */}
                    </div>
                    <div>
                      <p className="my-4">
                        Are you sure you want to delete{" "}
                        <span className=" font-[550]">{props.sectionKey}</span>?
                      </p>
                      <p className="mt-4">
                        Deleting the section will: 
                      </p>
                      <ul className="list-disc pl-5">
                        <li>Permanently remove all the documents within the section.</li>
                        <li>This action cannot be undone.</li>
                      </ul>
                      <p className="mt-4">Please confirm to proceed.</p>
                      <div className=" flex items-center gap-2 ">
                        <input
                          type="checkbox"
                          id="delete"
                          name="delete"
                          checked={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                        />
                        <label htmlFor="delete">Confirm deletion</label>
                      </div>
                      {iserror && (
                        <div className=" text-red-500 ">
                          *Please check the box to delete
                        </div>
                      )}
                      <br />
                      <div className=" flex justify-end gap-2">
                        <button
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-2 md:my-0"
                          onClick={closemodal}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                          onClick={handleDeleteSection}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
