import React,{useContext} from "react";
import { LuPencilLine } from "react-icons/lu";
import { GoPlus } from "react-icons/go";
import { logincontext } from "../../contexts/Logincontext";

export default function SkillsComponent({
  dash,
  setshowskills,
  showskills,
  publicview,
  selectedUser,
  handleOpenSkillsModal
}) {

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);


  return (
    <div className={`w-full  border-[1px] px-4 pt-3 md:pt-5 md:px-6 md:rounded-md  bg-white text-black`}>
    <div className="text-lg font-[550]  flex justify-between">
        Skills{" "}
        {dash?.isloggedinuser && (
          <div className="flex gap-4 ">
            <div onClick={() => handleOpenSkillsModal()} className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer">
             
                <GoPlus />
            </div>
          </div>
        )}
      </div>
      <ul className="flex flex-wrap gap-2  list-none">
        {dash?.complete_profile?.profile?.professionalDetails?.skills.length != 0 && (
          dash.complete_profile?.profile?.professionalDetails?.skills.map((item, key) => (
            <li
              className=" border-gray-100 hover:bg-slate-100 hover:shadow-lg text-sm duration-300 cursor-pointer border-2 p-2 rounded-2xl"
              key={key}
            >
              {item} 
            </li>
          ))
        )}
      </ul>
      <br />
    </div>
  );
}
