import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import countryData from "../../asserts/Countrylist";
import Months from "../../asserts/Months";
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../pages/Login";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import apiUrl from "../../apiConfig";
import AddOrganization from "../AddOrganization";

export default function Profile4_1({ }) {
  const [toyears, settoyears] = useState(Months[1]);
  const [selectedYear, setSelectedYear] = useState("");
  const token = localStorage.getItem("token");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedtoYear, setSelectedtoYear] = useState("");
  const [selectedSchoolOrCompany, setSelectedSchoolOrCompany] = useState("");
  const [filteredSchoolsOrCompanies, setFilteredSchoolsOrCompanies] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [check, setcheck] = useState(false);

  const [tomonths, settomonths] = useState(Months[0]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const monthsselected = (e) => {
    setSelectedMonth(e.target.value);
    settomonths(Months[0].slice(Months[0].indexOf(e.target.value)));
  };
  useEffect(() => {
    // // // console,log(selectedtoYear, selectedYear)
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(selectedMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);
  const onSubmit = async (data) => {
    // For example:
    setLoading(true);
    const projectDetails = {
      Title: data.Title,
      company: selectedSchoolOrCompany || data.company,
      fromMonth: data.fromMonth,
      fromYear: data.fromYear,
      description: data.description,
      skills: skil,
      link: data.link,
    };
    // // // console,log(data);
    if (data.pursuing) {
      projectDetails.pursuing = true;
    } else {
      projectDetails.toMonth = data.toMonth;
      projectDetails.toYear = data.toYear;
    }

    const reqbody = {
      projects: projectDetails,
      // profilestatus: "/signup/add-experience",
    };
    try {
      const response = await axios.post(apiUrl.profile.addexp + token, reqbody);
      //// // // console,log(response);
      if (response.status === 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        navigate(`/signup/project-review`);
      }
    } catch (err) {
      //// // // console,log(err);
    }
    setLoading(false);
    window.scroll(0, 0);
  };

  const [universities, setUniversities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const yearselected = (e) => {
    setSelectedYear(e.target.value);
    settoyears(Months[1].slice(0, Months[1][0] - parseInt(e.target.value) + 1));
  };

  useEffect(() => {
    // // // console,log(selectedtoYear, selectedYear);
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(selectedMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filteredSchoolsOrCompanies.length > 0 &&
        !document
          .getElementById("schoolAndCompanyDropdown")
          .contains(event.target)
      ) {
        setFilteredSchoolsOrCompanies([]);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setFilteredSchoolsOrCompanies([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredSchoolsOrCompanies]);

  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);
        setUniversities(response.data);

        setSchoolsAndCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    const postProfileStatus = async () => {
      try{
        const reqbody = {
          profilestatus : "/signup/add-project"
        }
        const response = await axios.post(apiUrl.profile.editprofile + `${token}`,reqbody);
        if(response.status === 200){
        }
      }catch(error){
        //// console.log(error)
      }
    }

    postProfileStatus();

    fetchUniversitiesAndCompanies();
  }, []);
  const [schoolsAndCompanies, setSchoolsAndCompanies] = useState([]);
  const [skil, setskills] = useState([]);
  const [newSkill, setNewSkill] = useState("");

  const [iniskills, setiniskills] = useState([]);

  const delitem = (itemToDelete) => {
    const updatedSkills = skil.filter((item) => item !== itemToDelete);
    setskills(updatedSkills);
    setiniskills([...iniskills, itemToDelete]);
  };

  const handleAddNewSkill = () => {
    if (newSkill.trim() !== "" && !skil.includes(newSkill)) {
      setskills([...skil, newSkill]);
      setNewSkill("");
    }
  };

  const handleSchoolOrCompanyChange = (e) => {
    const inputSchoolOrCompany = e.target.value;
    setSelectedSchoolOrCompany(inputSchoolOrCompany);
    // Filter schools based on input
    // You would replace usSchools with the array containing your school names
    // For the purpose of this example, let's assume we have a dummy array called usSchools

    const filtered1 = schoolsAndCompanies.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchoolOrCompany.toLowerCase())
    );

    const filtered2 = schoolsAndCompanies.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchoolOrCompany.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchoolOrCompany === "") {
      setSelectedImage("");
    }
    // if (finalFiltered.length > 0) {
    //   // setSelectedSchoolImage(filtered[0].s3Link);
    // } else {
    //   setSelectedImage(DefaultEducation);
    // }
    // // // console,log(finalFiltered);
    setFilteredSchoolsOrCompanies(finalFiltered);
  };

  const handleSchoolOrCompanySelect = (obj) => {
    setSelectedSchoolOrCompany(obj.Name);
    setSelectedImage(obj.s3Link);
    setFilteredSchoolsOrCompanies([]);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleAddNewSkill();
  };

  const handleNewSkillChange = (e) => {
    setNewSkill(e.target.value);
  };

  const [addorg, setaddorg] = useState(false);
  const handleaddorg = () => {
    setaddorg(!addorg);
  };

  const skip = () => {
    // Navigate to the next page or wherever you want
    navigate(`/signup/add-experience`);
    window.scroll(0, 0);
  };
  if (token === null) {
    return <Login />;
  } else {
    return (
      <div>
        {addorg && (
          <AddOrganization
            addorg={addorg}
            setaddorg={setaddorg}
            
            title="Organization"
          />
        )}
        <div className=" h-1 w-full flex">
          <div className="h-full bg-[#0052ff] w-[62.5%]"></div>
          <div className="h-full "></div>
        </div>
        <div className="m-4 ">
          <div className="py-3 text-2xl font-[550] font-sans">Add Project</div>

          <form action="" onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="relative">
              <label htmlFor="company" className="font-[550]">
                Company or School <span className="text-red-500">*</span>
              </label>
              <div
                className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2`}
              >
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="School Logo"
                    className="h-6 w-6 ml-2"
                  />
                )}
                <input
                  type="text"
                  className="focus:outline-none w-[99%]"
                  name="company"
                  {...register("company", {
                    maxLength: {
                      value: 100,
                      message: "Company must not exceed 100 characters",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                      message:
                        "Company can only contain alphanumeric characters, spaces, underscores, and hyphens",
                    },
                  })}
                  value={selectedSchoolOrCompany}
                  onChange={handleSchoolOrCompanyChange}
                />
              </div>
                 {errors.company && (
                          <p className="text-red-500 text-sm">
                            {errors.company.message}
                          </p>
                        )}
              {/* Dropdown menu for filtered schools */}
              <p>
                Did not find your Organization name?{" "}
                <span
                  className=" font-[550] text-[#0052ff] cursor-pointer "
                  onClick={() => {
                    handleaddorg();
                    // closemodal();
                  }}
                >
                  {" "}
                  Add here{" "}
                </span>
              </p>
              {filteredSchoolsOrCompanies.length > 0 && (
                <div
                  id="schoolAndCompanyDropdown"
                  className="absolute z-10 mt-1 bg-white border rounded-md w-[100%]  max-h-[150px] overflow-y-auto shadow-lg"
                >
                  {filteredSchoolsOrCompanies.map((obj, index) => (
                    <div
                      key={index}
                      className="cursor-pointer flex hover:bg-gray-100 p-2"
                      onClick={() => handleSchoolOrCompanySelect(obj)}
                    >
                      <img
                        src={obj.s3Link}
                        alt="School or Company Logo"
                        className="h-6 w-6 mr-2 rounded-sm"
                      />
                      {/* Display School logo */}
                      {obj.Name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="Title" className="font-[550]">
                Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2 `}
                name="Title"
                required
                {...register("Title", {
                  maxLength: {
                    value: 200,
                    message: "Title must not exceed 200 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                    message:
                      "Title can only contain alphanumeric characters, spaces, underscores, and hyphens",
                  },
                }
                )}
              />
              {errors.Title && (
                <p className="text-red-500">{errors.Title.message}</p>
              )}
            </div>


            <div>
              <label className="font-[550] block">
                Time period <span className="text-red-500">*</span>
              </label>
              <div className="py-3 hover:cursor-pointer">
                <input
                  type="checkbox"
                  name="pursuing"
                  id="pursuing"
                  {...register("isPursuing")}
                  onChange={() => {
                    setcheck(!check);
                  }}
                />
                <label
                  htmlFor="pursuing"
                  className="text-sm text-justify hover:cursor-pointer px-2"
                >
                  I am currently working on this project
                </label>
              </div>
              <div>
                <label htmlFor="fromMonth" className="font-[550] block">
                  From <span className="text-red-500">*</span>
                </label>
                <div className="flex justify-between">
                <select
                    className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  }`}
                    name="Month"
                    required
                    {...register("fromYear")}
                    value={selectedYear}
                    onChange={yearselected}
                  >
                    <option value="">Year</option>
                    {Months[1].map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  <select
                    className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                    name="fromMonth"
                    required
                    {...register("fromMonth")}
                    value={selectedMonth}
                    onChange={monthsselected}
                  >
                    <option value="">Month</option>
                    {Months[0].map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  
                </div>
              </div>
              {!check || check === "false" ? (
                <div>
                  <label htmlFor="toMonth" className="font-[550] block">
                    To <span className="text-red-500">*</span>
                  </label>
                  <div className="flex justify-between">
                  <select
                      className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                      name="toYear"
                      required
                      {...register("toYear")}
                      onChange={(e) => setSelectedtoYear(e.target.value)}
                    >
                      <option value="">Year</option>
                      {toyears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <select
                      className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2 `}
                      name="toMonth"
                      required
                      {...register("toMonth")}
                    >
                      <option value="">Month</option>
                      {tomonths.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>

                    
                  </div>
                </div>
              ) : null}
            </div>

            <div>
              <label htmlFor="skills" className="font-[550]">
                Skills
              </label>
              <div className="quill-container">
                <div
                  className={`w-full h-[40vh] border-[1.5px] rounded-2xl my-2 overflow-y-auto `}
                >
                  <ul className="flex flex-wrap p-4">
                    <div className="w-full justify-center flex items-center gap-2 ">
                      <div className="my-2 flex gap-2">
                        <input
                          type="text"
                          className={`p-2   border-[1.5px]  rounded-md my-2  `}
                          placeholder="Type a new skill"
                          value={newSkill}
                          onChange={handleNewSkillChange}
                        />
                        <button
                          className=" duration-300   font-[550] py-1 px-4 rounded-xl"
                          type="submit"
                          onClick={handleFormSubmit}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    {skil.map((item, key) => (
                      <li
                        className="text-black flex items-center text-xs md:text-sm duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-black hover:bg-[#80808025] m-1"
                        key={key}
                        onClick={() => delitem(item)}
                      >
                        <ImBin />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="description" className="font-[550]">
                Description
              </label>
              <div className="quill-container">
                <textarea
                  className={`w-full p-2    border-[1.5px]  rounded-md my-2  `}
                  name="description"
                  rows="4"
                  {...register("description")}
                ></textarea>
              </div>
            </div>

            <div>
              <label htmlFor="link" className="font-[550]">
                Link <span className="text-red-500"></span>
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2 `}
                name="link"
                {...register("link", {
                  pattern: {
                    value:
                      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/,
                    message: "Invalid Website URL",
                  },
                })}
              />
              {errors.link && (
                <p className="text-red-500">{errors.link.message}</p>
              )}
            </div>

            <div className="flex flex-row-reverse justify-between">
              {loading ? (
                <FaSpinner size={30} className="animate-spin text-[#0052ff] " />
              ) : (
                <div>
                  {/* <button
                    onClick={() => {navigate("/profile/page4");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                  <button
                    type="submit"
                    className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                  >
                    Next
                  </button>
                </div>
              )}
              <button
                className="text-[#0052ff]  font-[550]  flex justify-center items-center gap-1"
                onClick={skip}
              >
                Skip
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
