import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import countriesList from "../../asserts/Countrylist";
import Months from "../../asserts/Months";
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../pages/Login";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";
import DefaultCompany from "../../images/DefaultCompany.png";
import AddOrganization from "../AddOrganization";

export default function Profile5({  }) {
  const [toyears, settoyears] = useState(Months[1]);
  const [selectedYear, setSelectedYear] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [description, setDescription] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyImage, setSelectedCompanyImage] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [addorg, setaddorg] = useState(false);
  const handleaddorg = () => {
    setaddorg(!addorg);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCompanies.length > 0) {
        const dropdown = document.getElementById("CompanyDropdown");
        if (dropdown && !dropdown.contains(event.target)) {
          setFilteredCompanies([]);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filteredCompanies]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [check, setcheck] = useState(false);

  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchCompanies = async () => {
      // // // // console,log(apiUrl.companies.getcompanies)
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);
        setCompanies(response.data);
        // setcompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    const postProfileStatus = async () => {
      try{
        const reqbody = {
          profilestatus : "/signup/add-experience"
        }
        const response = await axios.post(apiUrl.profile.editprofile + `${token}`,reqbody);
        if(response.status === 200){
        }
      }catch(error){
        //// console.log(error)
      }
    }
    postProfileStatus()

    fetchCompanies();
  }, []);

  const onSubmit = async (data) => {
    // Similar to what you did in Profile4
    // For example:
    setLoading(!loading);
    data.company = selectedCompany;
    data.Country = selectedCompany;
    const jobDetails = {
      jobTitle: data.jobTitle,
      company: data.company,
      country: selectedCountry,
      city: data.city,
      fromMonth: data.fromMonth,
      fromYear: data.fromYear,
      description: data.description,
    };

    if (data.pursuing) {
      jobDetails.pursuing = true;
    } else {
      jobDetails.toMonth = data.toMonth;
      jobDetails.toYear = data.toYear;
    }
    
    const reqbody = {
      experience: jobDetails,
      // profilestatus: "/signup/add-skills",
    };
    try {
      const response = await axios.post(apiUrl.profile.addexp + token, reqbody);
      //// // // console,log(response);
      if (response.status === 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        navigate(`/signup/job-review`);
      }
    } catch (err) {
      //// // // console,log(err);
    }
    setLoading(false);
    window.scroll(0, 0);
  };

  const handleCompanySelect = (obj) => {
    setSelectedCompany(obj.Name);
    setSelectedCompanyImage(obj.s3Link);
    setFilteredCompanies([]);
  };
  const [lengthexceeded, setlengthexceeded] = useState(false);
  const handleCompanyChange = (e) => {
    const inputCompany = e.target.value;
    if (inputCompany.length > 100) {
      setlengthexceeded(true);
    } else {
      setlengthexceeded(false);
    }
    setSelectedCompany(inputCompany);
    // Filter Companys based on input
    // You would replace usCompanys with the array containing your Company names
    // For the purpose of this example, let's assume we have a dummy array called usCompanys

    const filtered = companies.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputCompany.toLowerCase())
    );
    const filtered1 = companies.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputCompany.toLowerCase())
    );

    const filtered2 = companies.filter((obj) =>
      obj.Name.toLowerCase().includes(inputCompany.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputCompany === "") {
      setSelectedCompanyImage("");
    }
    if (finalFiltered.length > 0) {
      // setSelectedCompanyImage(filtered[0].s3Link);
    } else {
      setSelectedCompanyImage(DefaultCompany);
    }
    setFilteredCompanies(finalFiltered);
  };

  const yearselected = (e) => {
    setSelectedYear(e.target.value);
    settoyears(Months[1].slice(0, Months[1][0] - parseInt(e.target.value) + 1));
  };

  const [tomonths, settomonths] = useState(Months[0]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const monthsselected = (e) => {
    setSelectedMonth(e.target.value);
    settomonths(Months[0].slice(Months[0].indexOf(e.target.value)));
  };
  const [selectedtoYear, setSelectedtoYear] = useState("");
  useEffect(() => {
    // // // console,log(selectedtoYear, selectedYear)
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(selectedMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);

  const [selectedCountry, setselectedCountry] = useState("");
  const [filteredCounties, setfilteredCounties] = useState([]);

  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCounties.length > 0) {
        const dropdown = document.getElementById("country");
        if (dropdown && !dropdown.contains(event.target)) {
          setfilteredCounties([]);
        }
      }
    };

    const handleEscapeKey = (event) => {
      // Close the dropdown if the "Escape" key is pressed
      if (event.key === "Escape") {
        setfilteredCounties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredCounties]);

  const skip = () => {
    // Navigate to the next page or wherever you want
    navigate(`/signup/add-skills`);
    window.scroll(0, 0);
  };
  if (token === null) {
    return <Login />;
  } else {
    return (
      <div>
        {addorg && (
          <AddOrganization
            addorg={addorg}
            setaddorg={setaddorg}
            
            title="Organization"
          />
        )}
        <div className=" h-1 w-full flex">
          <div className="h-full bg-[#0052ff] w-[75%]"></div>
          <div className="h-full  "></div>
        </div>
        <div className="m-4 ">
          <div className="py-3 text-2xl font-[550] font-sans">
            Add Work Experience
          </div>

          <form
            action=""
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <div>
              <label htmlFor="company" className="font-[550]">
                Company <span className="text-red-500">*</span>
              </label>
             
              <div
                className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 bg-white text-black`}
              >
                {selectedCompanyImage && (
                  <img
                    src={selectedCompanyImage}
                    alt="Company Logo"
                    className="h-6 w-6 ml-2"
                  />
                )}
                <input
                  type="text"
                  className="focus:outline-none w-[99%]"
                  name="company"
                  {...register("company", {
                    maxLength: {
                      value: 100,
                      message: "Company must not exceed 100 characters",
                    },
                    pattern: {
                      value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                      message:
                        "Company can only contain alphanumeric characters, spaces, underscores, and hyphens",
                    },
                  })}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                />
              </div>
              {errors.company && (
                <p className="text-red-500 text-sm">{errors.company.message}</p>
              )}
              <p>
                Did not find your Organization name?{" "}
                <span
                  className=" font-[550] text-[#0052ff] cursor-pointer "
                  onClick={() => {
                    handleaddorg();
                    // closemodal();
                  }}
                >
                  {" "}
                  Add here{" "}
                </span>
              </p>

              {/* Dropdown menu for filtered Companys */}
              {filteredCompanies.length > 0 && (
                <div
                  id="CompanyDropdown"
                  className="z-10 mt-1 bg-white border rounded-md w-[100%] relative max-h-[150px] overflow-y-auto shadow-lg"
                >
                  {filteredCompanies.map((obj, index) => (
                    <div
                      key={index}
                      className="cursor-pointer flex hover:bg-gray-100 p-2"
                      onClick={() => handleCompanySelect(obj)}
                    >
                      <img
                        src={obj.s3Link}
                        alt="Company Logo"
                        className="h-6 w-6 mr-2 rounded-sm"
                      />
                      {/* Display Company logo */}
                      {obj.Name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="jobTitle" className="font-[550]">
                Job Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                name="jobTitle"
                required
                {...register("jobTitle", {
                  maxLength: {
                    value: 100,
                    message: "Job title must not exceed 100 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                    message:
                      "Job title can only contain alphanumeric characters, spaces, underscores, and hyphens",
                  },
                }
                )}
              />
              {errors.jobTitle && (
                <p className="text-red-500 text-sm">{errors.jobTitle.message}</p>
              )}

            </div>
            <div>
              <label htmlFor="city" className="font-[550]">
                City
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                name="city"
                {...register("city", {
                  maxLength: {
                    value: 100,
                    message: "City must not exceed 100 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                    message:
                      "City can only contain alphanumeric characters, spaces, underscores, and hyphens",
                  },
                }
                )}
              />
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city.message}</p>
              )}

            </div>
            <div>
              <label htmlFor="country" className="font-[550]">
                Country <span className="text-red-500">*</span>
              </label>
              <div className="w-[100%] relative">
                <div>
                  <input
                    type="text"
                    className={`w-full p-2 border-[1.5px] rounded-md my-2 `}
                    name="city"
                    value={selectedCountry}
                    {...register("Country", {
                      maxLength: {
                        value: 100,
                        message: "Country must not exceed 100 characters",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                        message:
                          "Country can only contain alphanumeric characters, spaces, underscores, and hyphens",
                      },
                    }

                    )}
                    onChange={handleCityChange}
                  />
                  {errors.Country && (
                    <p className="text-red-500 text-sm">
                      {errors.Country.message}
                    </p>
                  )}
                  {/* Dropdown menu for filtered cities */}
                  {filteredCounties.length > 0 && (
                    <div
                      id="country"
                      className="absolute z-10 mt-1 bg-white border w-[100%] max-h-[150px] overflow-y-auto   rounded-md shadow-lg"
                    >
                      {filteredCounties.map((city, index) => (
                        <div
                          key={index}
                          className="cursor-pointer hover:bg-gray-100 p-2"
                          onClick={() => handleCitySelect(city)}
                        >
                          {city}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label className="font-[550] block">
                Time period <span className="text-red-500">*</span>
              </label>
              <div className="py-3 hover:cursor-pointer">
                <input
                  type="checkbox"
                  name="pursuing"
                  id="pursuing"
                  {...register("isPursuing")}
                  onChange={() => {
                    setcheck(!check);
                  }}
                />
                <label
                  htmlFor="pursuing"
                  className="text-sm text-justify hover:cursor-pointer px-2"
                >
                  Currently employed here
                </label>
              </div>
              <div>
                <label htmlFor="fromMonth" className="font-[550] block">
                  From <span className="text-red-500">*</span>
                </label>
                <div className="flex justify-between">
                <select
                    className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                    name="Month"
                    required
                    {...register("fromYear")}
                    value={selectedYear}
                    onChange={yearselected}
                  >
                    <option value="">Year</option>
                    {Months[1].map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  <select
                    className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                    name="fromMonth"
                    required
                    {...register("fromMonth")}
                    value={selectedMonth}
                    onChange={monthsselected}
                  >
                    <option value="">Month</option>
                    {Months[0].map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  
                </div>
              </div>
              {!check || check === "false" ? (
                <div>
                  <label htmlFor="toMonth" className="font-[550] block">
                    To <span className="text-red-500">*</span>
                  </label>
                  <div className="flex justify-between">
                  <select
                      className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                      name="toYear"
                      required
                      {...register("toYear")}
                      onChange={(e) => setSelectedtoYear(e.target.value)}
                    >
                      <option value="">Year</option>
                      {toyears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <select
                      className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                      name="toMonth"
                      required
                      {...register("toMonth")}
                    >
                      <option value="">Month</option>
                      {tomonths.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                   
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              <label htmlFor="description" className="font-[550]">
                Description
              </label>
              <div className="quill-container">
                <textarea
                  className={`w-full p-2    border-[1.5px]  rounded-md my-2 `}
                  name="description"
                  rows="4"
                  {...register("description")}
                ></textarea>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between">
              {loading ? (
                <FaSpinner size={30} className="animate-spin text-[#0052ff]" />
              ) : (
                <div>
                  {/* <button
                onClick={() => {navigate("/profile/page4-1");
                window.scroll(0, 0)}}
                  className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                >
                  Back
                </button> */}
                  <button
                    type="submit"
                    className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                  >
                    Next
                  </button>
                </div>
              )}
              <button
                className="text-[#0052ff] font-[550] flex justify-center items-center gap-1"
                onClick={skip}
              >
                Skip
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
