import React from 'react'
import AccessTable from '../components/AccessTable'


export default function AccessManagement({url,}) {
  return (
    <div className={`flex flex-col bg-white text-black rounded-xl`}>
      <AccessTable url={url} />
    </div>
  )
}
