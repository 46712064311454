import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Months from "../asserts/Months";
import countryData from "../asserts/Countrylist";
import apiUrl from "../apiConfig";

export default function ConfirmAccessControlsModal({
  functionality,
  handleResetSections,
  handleno,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleno}>
      <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform   overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw]  w-[100%] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                >
      
                  Are you sure you want to {functionality}?
                  <br />
                  <br />
                  <div className="flex justify-end  text-black">
                    {functionality === "save" ? (
                      <div className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                      ></div>
                    ) : (
                      <div className="flex gap-2 w-full flex-row-reverse">
                        <div
                          className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600"
                          onClick={handleResetSections}
                        >
                          <div className="flex gap-1 justify-center items-center">
                            <p className=" capitalize">{functionality}</p>
                          </div>
                        </div>
                        <div
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                          onClick={handleno}
                        >
                          <div className="flex gap-1 justify-center items-center">
                            <p>Cancel</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
