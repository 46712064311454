import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function NetWork({}) {
  const navigate = useNavigate();
  const subtab = {
    Invitations: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
        />
      </svg>
    ),
    // Lists: (
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     fill="none"
    //     viewBox="0 0 24 24"
    //     strokeWidth={1.5}
    //     stroke="currentColor"
    //     className="size-6"
    //   >
    //     <path
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
    //     />
    //     <path
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       d="M6 6h.008v.008H6V6Z"
    //     />
    //   </svg>
    // ),
    Connections: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
        />
      </svg>
    ),
    Tags: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 6h.008v.008H6V6Z"
        />
      </svg>
    ),
  };

  const location = useLocation();
  const [subsettting, setsubsetting] = useState(
    location.pathname.split("/")[2]
  );

  useEffect(() => {
    setsubsetting(location.pathname.split("/")[2]);
  }, [location.pathname]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div
      className={`flex justify-center w-full min-h-[90vh]  
    }`}
    >
      <div
        className={`max-w-screen-xl lg:w-[100%] w-full h-[100%] justify-between flex lg:flex-row flex-col gap-4 lg:p-4
     `}
      >
        <div className=" w-full min-h-[90vh]  flex lg:flex-row gap-4 flex-col">
          <div className="lg:w-[25%] w-[100%] border-[1px] bg-white h-fit md:rounded-md  lg:flex-col flex-row flex    overflow-x-auto ">
            <div className=" hidden lg:block">
              <div className=" text-lg font-[550]  py-2 px-4 ">Network</div>
            </div>
            <hr className="" />
            <div className=" lg:py-2 flex lg:flex-col flex-row w-full">
              {Object.keys(subtab).map((user, key) => (
                <li
                  key={key}
                  className={` lg:text-base text-sm list-none flex justify-center items-center lg:justify-start  users-center w-1/3 md:w-full md:gap-2 gap-1 py-2 px-4 cursor-pointer hover:bg-blue-100 ${
                    subsettting.charAt(0).toUpperCase() +
                      subsettting.slice(1) ===
                    user
                      ? // ? " bg-blue-200 lg:border-r-2 border-b-2 lg:border-b-0 border-[#0052ff] "
                        " bg-blue-100 lg:border-r-[1.5px] lg:border-b-0 border-b-[1.5px] border-[#0052ff] "
                      : ""
                  } `}
                  onClick={() => {
                    setsubsetting(user);
                    navigate("/network/" + user.toLowerCase());
                  }}
                >
                  {subtab[user]} {user}
                </li>
              ))}
            </div>
          </div>
          <div className="w-full ">
            <Outlet />
            {/* {rendercomponent(subsettting)} */}
          </div>
        </div>
      </div>
    </div>
  );
}
