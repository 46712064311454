import React, { useEffect,useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import {toast} from "react-toastify"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import defaultSkills from "../asserts/Skills";
import { ImBin } from "react-icons/im";
import apiUrl from "../apiConfig";
import { FaSpinner } from "react-icons/fa";
import { logincontext } from "../contexts/Logincontext";

export default function EditSkillsModal({
  handleOpenSkillsModal,
}) {

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);


  const cancelButtonRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(true);
  const [iniskills, setiniskills] = useState(defaultSkills);
  const [skills, setskills] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const addlist = (value) => {
    // // console.log(value);
    // // console.log(skills)
    if (!skills.includes(value)) {
      setskills([...skills, value]);
      setiniskills(iniskills.filter((ite) => ite !== value));
    }
  }

  const delitem = (itemToDelete) => {
    const updatedSkills = skills.filter((item) => item !== itemToDelete);
    setskills(updatedSkills);
    setiniskills([...iniskills, itemToDelete]);
  };
  const [err, seterr] = useState("");
  const handleNewSkillChange = (e) => {
    const isValidInput = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/.test(
      e.target.value
    );
    if (!isValidInput) {
      // handle invalid input
      seterr(
        "skill can only contain alphanumeric characters, spaces, underscores, and hyphens"
      );
    } else {
      seterr("");
    }
    setNewSkill(e.target.value);
  };

  const handleAddNewSkill = () => {
    if (!err && newSkill.trim() !== "" && !skills.includes(newSkill)) {
      setskills([...skills, newSkill]);
      setNewSkill("");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    handleAddNewSkill();
  };
  useEffect(()=>{
    setskills(loggedInUser.complete_profile.profile.professionalDetails.skills);
    setiniskills(defaultSkills.filter((skill) => !loggedInUser.complete_profile.profile.professionalDetails.skills.includes(skill)))
  },[])
  // useEffect(() => {
  //   const getprofile = async () => {
  //     try {
  //       const response = await axios.post(apiUrl.profile.getprofile + token);
  //       //// // // console,log(response.status);
  //       setskills(response.data.existing_profile.skills);
  //       setiniskills(skills.filter((skill) => !response.data.existing_profile.skills.includes(skill)))
  //     } catch (err) {
  //       if (err.response.status === 401) {
  //         // toast.error("You have to Login");
  //         // navigate("/login")
  //       }
  //       //// // // console,log(err);
  //     }
  //   };

  //   getprofile();
  //   //// // // console,log("working");
  // }, []);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [check, setcheck] = useState(false);
  const submitbut = async () => {
    // // // console,log(skills);
    setloading(true);
    const reqbody = {
      skills: skills,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      //// // // console,log(response);
      if (response.status === 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        handleOpenSkillsModal(skills);
      }
    } catch (err) {
      //// // // console,log(err);
    } finally {
      setloading(false);
    }
  };
  
  const closemodal = () => {
      handleOpenSkillsModal();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closemodal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4 bg-white text-black`}
                >
                  <div className="flex w-full items-center justify-between py-4 ">
                    <div className=" text-2xl font-[550]">Edit Skills</div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="cursor-pointer"
                    />
                  </div>

                  <div>
                    <div>
                      <div className="py-3 text-2xl font-[550] font-sans">
                        What are some of your skills?
                      </div>
                      <div className="text-sm">
                        We recommend at least 4 skills
                      </div>
                      <br />
                      <div className="w-full justify-center flex items-center gap-2">
                        <form onSubmit={handleFormSubmit}>
                          <input
                            type="text"
                            className={`p-2 border-[1.5px] rounded-md mx-3 bg-white text-black`}
                            placeholder="Type a new skill"
                            value={newSkill}
                            onChange={handleNewSkillChange}
                          />
                          <button
                            className="duration-300  font-[550] py-1 px-4 rounded-xl"
                            type="submit"
                          >
                            Add
                          </button>
                        </form>
                      </div>
                      {err && <p className="text-red-500 text-sm">{err}</p>}
                      <div
                        className={`w-full h-[40vh] border-[1.5px] rounded-2xl my-2 overflow-y-auto `}
                      >
                        <ul className="flex flex-wrap p-4">
                          {skills.map((item, key) => (
                            <li
                              className="text-black flex items-center text-xs md:text-sm duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-black hover:bg-[#80808025] m-1"
                              key={key}
                              onClick={() => delitem(item)}
                            >
                              <ImBin />
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <br />
                      <hr />
                      <br />
                      <div className="w-full justify-center flex items-center gap-2"></div>
                      <br />
                      <ul className="w-full flex flex-wrap gap-2">
                        {iniskills.map((item, key) => (
                          <li
                            onClick={() => addlist(item)}
                            className="text-black  text-xs md:text-sm duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-black hover:bg-[#80808025] md:my-0"
                            key={key}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                      <br />

                      <div className="flex flex-row-reverse pt-2">
                        {loading ? (
                          <FaSpinner
                            size={30}
                            className="text-[#0052ff] animate-spin"
                          />
                        ) : (
                          <div>
                            {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                            <button
                              onClick={submitbut}
                              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
