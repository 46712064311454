import { logincontext } from "./Logincontext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiUrl from "../apiConfig";

function Userloginstore({ children }) {
  const [loginerror, setloginerror] = useState("");
  const [UserloginStatus, setUserloginStatus] = useState(false);
  const [otp, setOtp] = useState("");
  const [loggedInUser, setLoggedInUser] = useState({});

  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(apiUrl.profile.getprofile + token);
      if (response.status === 200) {
        setLoggedInUser(response.data);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    if (Object.keys(loggedInUser).length === 0) {
      fetchUserProfile();
    }
  }, []);

  
  const VerifyOTP = async (userobj, writtenOtp) => {
    try {
      const response = await axios.post(apiUrl.verifyotp, {
        ...userobj,
        uotp: writtenOtp,
      });
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("refreshtoken", response.data.refreshtoken);
        // // // console,log(response.data.profile.profilestatus)
        // console.log(response.data);
        
        if(response.data.type === 'admin'){
          navigate(`/admin/${response.data.existing_admin.email}`)
        }
       navigate("/signup/phoneno");
        return true;
      }
    } catch (err) {
        // console.log(err);
      return false;
    }
  };
  const Signupuser = async (userobj) => {
    try {
      const response = await axios.post(apiUrl.usersignup, userobj);
      // console.log(response);
      if (response.status === 200) {
        setOtp(response.data.otp);
        return false;
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          return [err.response.status, err.response.data.error];
        } else {
          return [err.response.status, err.response.data.error];
        }
      } 
    }
  };


  
  
  const Loginuser = async (userobj, url) => {
    try {
      const response = await axios.post(apiUrl.login, userobj);
      if (response.status === 200) {
        // // console.log(response.data);
        setUserloginStatus(true);
        setloginerror("");
        let token = response.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("refreshtoken", response.data.refreshtoken);
        if (response.data.type === "admin") {
          navigate(`/admin/${response.data.existing_admin.email}`);
        } else {
          // fetchUserProfile();
          // // console.log(response.data.complete_profile.user.profileUrl);
          // console.log(response.data)
          setLoggedInUser(response.data)
          navigate("/" + response.data.complete_profile.user.profileUrl);
        }
      }
    } catch (err) {
      // console.log(err)
      if (err.response) {
        if (err.response.status === 404) {
          return ("Enter correct mail id / password");
        } else if (err.response.status === 401) {


          return ("Enter correct mail id / password");
        } else {
          return ("internal server error");
        }
      } else {

      }
    }
  };
  const SignupEmployer = async (user) => {
    try {
      const response = await axios.post(
        apiUrl.employer.signupemployeruser,
        user
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", "recruiter");
        navigate("/recruiter");
      }
    } catch (error) {

    }
  };

  const LoginEmployer = async (user) => {

    try {
      const response = await axios.post(
        apiUrl.employer.loginemployeruser,
        user
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", "recruiter");
        navigate("/recruiter");
      }
    } catch (error) {
    }
  };

  const Logoutuser = () => {
    localStorage.clear();
    setUserloginStatus(false);
    navigate("/");
  };

  const changeloggedInUser = (user) => {
    // // console.log(user);
    setLoggedInUser(user);
  };

  return (
    <logincontext.Provider
      value={[
        loginerror,
        UserloginStatus,
        loggedInUser,
        Loginuser,
        Signupuser,
        VerifyOTP,
        Logoutuser,
        setloginerror,
        SignupEmployer,
        LoginEmployer,
        changeloggedInUser
      ]}
    >
      {children}
    </logincontext.Provider>
  );
}
export default Userloginstore;
