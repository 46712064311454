import React from "react";
import Post from "./Post";

export default function PostsOutline({ posts, loggedInUser }) {
  return (
    <div className=" flex flex-col gap-4">
      {" "}
      {posts &&
        [...new Set(posts.map((post) => post._id))]
          .map((id) => posts.find((post) => post._id === id))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((post, index) =>
            post ? (
              <Post
                key={post._id || index}
                post={post}
                loggedInUser={loggedInUser}
              />
            ) : (
              <div key={index} className="loading-placeholder">
                Loading...
              </div>
            )
          )}
    </div>
  );
}
