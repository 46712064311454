import { IoMdClose, IoMdInformationCircleOutline } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { TextInput } from "keep-react";
import { FaSpinner } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import apiUrl from "../apiConfig";
import { toast } from "react-toastify";

export default function AddSectionModal({ handleSectionModal }) {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const token = localStorage.getItem("token");
  const [error, seterror] = useState("");
  const handleAddSections = async (data) => {
    // // // console,log(data);
    // console.log(data);
    setLoading(true);
    if(!data.sectionName){
      seterror("Title cannot be empty. Please enter title of the section")
      setLoading(false);
      return
    }
    try {
      const response = await axios.post(
        apiUrl.documents.addsection + token,
        data
      );
      if (response.status === 200) {
        setLoading(false);
        handleSectionModal(data.sectionName);
        // // // console,log(response.data);
        // toast.success('Section added successfully');
      }
    } catch (error) {
      // // // console,log(error.response.data);
      seterror(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() =>handleSectionModal(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                  >
                    <div className="flex w-full items-end justify-between py-2 cursor-pointer mb-1">
                      <div className="text-2xl font-[550]">
                      Add New Section 
                      </div>
                      <IoMdClose
                        size={30}
                        onClick={() =>handleSectionModal(null)}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      />
                    </div>
                      {/* <div className="text pb-2">
                        
                          Create a new section to organize your documents
                          effectively. Simply name your section, then click
                          save to confirm.
                        
                      </div> */}
                    

                    <form onSubmit={handleSubmit(handleAddSections)}>
                      <div className="pb-2">
                        {/* Register the input field with useForm */}
                        {/* <TextInput
                    id="input"
                    placeholder="Section name"
                    color="gray"
                    {...register("sectionName", { required: true })}
                  /> */}
                         <label className="font-[550] ">Name</label>
                        <input
                          className={`${error && "border-red-500"} w-full p-2 bg-white focus:outline-none text-black border-[1.5px] border-black rounded-md `}
                          // placeholder="Section name"
                          {...register("sectionName")}
                        />
                      </div>
                      {
                        error && <div className="text-red-500 flex gap-1 items-center"><IoMdInformationCircleOutline/> {error}</div>
                      }
                      <div className="flex  flex-row-reverse">
                        {loading ? (
                          <FaSpinner
                            size={30}
                            className="animate-spin text-[#0052ff] mr-2"
                          />
                        ) : (
                          <button
                            type="submit"
                            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
