import React, { useState, useEffect, Fragment } from "react";
import { IoMdClose } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import apiUrl from "../../apiConfig";
import axios from "axios";
import { useForm } from "react-hook-form"; // Correctly import useForm
import { useNavigate } from "react-router-dom";

export default function RemoveGroupMemberModal({
  handleRemoveMemberModal,
  group,
  user,
}) {
  const { handleSubmit, register } = useForm(); // Use handleSubmit from useForm
  const [error, setError] = useState("");

  const submitForm = async (data) => {
    const reqbody = {
      userId: user.userId,
      groupId: group.groupId,
    };
    // console.log(reqbody);
    try {
      const response = await axios.post(apiUrl.feed.removegroupmember, reqbody);
      if (response.status === 200) {
        setError("");
        // console.log(response.data)
        handleRemoveMemberModal(user, user);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
    }
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => handleRemoveMemberModal(user, null)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl text-left transition-all mx-4">
                <form
                  className="bg-white text-black md:w-[40vw] max-w-screen-lg w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto rounded-xl border-[1.5px] p-4"
                  onSubmit={handleSubmit(submitForm)} // Use handleSubmit with submitForm
                >
                  <div className="flex w-full items-start justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">
                      Remove Group Member - {user.name}
                    </div>
                    <IoMdClose
                      size={40}
                      onClick={() => handleRemoveMemberModal(user, null)}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <div className="text-xl">{group.name}</div>

                  
                  {error && <p className="text-red-500">{error}</p>}
                  <div className="flex flex-row-reverse pt-4">
                    <button
                      type="submit"
                      className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                    >
                      Remove
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
