import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Checkbox, Label } from "keep-react";
import { set, useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { useNavigate } from "react-router-dom";

export default function AddNewTagModal({ handleOpenTagModal, token,param,setParam,loggedInUser }) {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const [tagName, setTagName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleAddNewTag = async () => {
    
    try {
      const response = await axios.post(apiUrl.tags.addnewtag + token, {
        TagName: tagName,
      });
      if (response.status === 200) {
        setTagName("");
        setMessage("New Tag created successfully");
        // setParam(!param);
        handleOpenTagModal(tagName);
      }
    } catch (error) {
      //// console.log(error);
      setError(error.response.data.error);
    }
  }
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => handleOpenTagModal(null)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div className=" md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4 bg-white">
                  <div>
                    <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                      <div className="text-2xl font-[550]">
                        Add New Tag
                      </div>

                      <IoMdClose
                        size={30}
                        onClick={() => handleOpenTagModal(null)}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      />
                    </div>

                    
                    <form className="" onSubmit={handleSubmit(handleAddNewTag)}>
                      <div className="py-4 px-2">
                        <div className=" max-h-[32vh] md:max-h-[65vh] w-full overflow-y-auto">
                          <label className="font-[550]">Name</label>
                          <input
                          type="text"
                          placeholder="Tag Name"
                          className="w-full border border-gray-400 p-2 rounded-lg"
                          {...register("TagName", { required: true })}
                          value={tagName}
                          onChange={(e) => setTagName(e.target.value)}
                          />
                          </div>
                      </div>
                   
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    {message && (
                      <p className="text-green-500 text-sm">{message}</p>
                    )}
                    <div className="flex justify-end my-2">
                      <button
                        className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        type="submit"
                      >
                        Add
                      </button>
                    </div>
                    
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
