import React, { useState, useEffect, Fragment, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import apiUrl from "../apiConfig";

export default function PublicInterestModal({
  open,
  setOpen,
  url,
  token,
  feature,
  defemail
}) {
  // console,log(defemail);
  const [email, setEmail] = useState(defemail);
  const [message, setMessage] = useState("");

  const fetchEmailfromUrl = async () => {
    // try {
    //   const response = await axios.post(apiUrl.getuserdetails + token);
    //   // // console,log(response.data.existingUser.email)
    //   setEmail(response.data.existingUser.email);
    // } catch (error) {
    //   // // console,log(error);
    // }
  };

  const check = async () => {
    try {
      const response = await axios.post(
        apiUrl.publicinterest.checkfeaturepublicinterest + token,
        {
          feature: feature,
        }
      );
      if (response.status === 200) {
        if (response.data.message === "You have already sent the request") {
          setMessage(`You have already expressed interest in Aspireup ${feature}. We will notify you when it's released`);
          return true;
        }
        return false;
      }
    } catch (error) {
      // // console,log(error);
    }
  };

  useEffect(() => {
    if (!check()) {
      fetchEmailfromUrl();
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        apiUrl.publicinterest.updatepublicinterest + token,
        {
          feature: feature,
          email: email,
        }
      );
      if (response.status === 200) {
        setOpen(false);
      }
    } catch (error) {
      // // console,log(error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl text-left transition-all mx-4">
                <div className="bg-white text-black md:w-[40vw] max-w-screen-lg w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto rounded-xl border-[1.5px] p-4">
                  <div
                    className="flex w-full items-start justify-between py-4 cursor-pointer "
                    onClick={() => setOpen(false)}
                  >
                    {message ? <div className="text-xl font-[550]">{message}</div> : <div className="text-2xl font-[550]">
                      {feature}
                      <div className="text-sm mt-2">Thank you for your interest in Aspireup {feature}! Please submit your email below to be notified when it’s released.</div>
                    </div>}
                    
                    <button
                      type="button"
                      autoFocus
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      <IoMdClose size={20} />
                    </button>
                  </div>
                  {message ? (
                    <div></div>
                  ) : (
                    <div>
                      <label htmlFor="Email" className="text-black font-[550]">
                        Email
                      </label>
                      <input
                        type="email"
                        className="p-2 border-[1.5px] rounded-md my-2 w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button
                        className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-2"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
