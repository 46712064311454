import axios from "axios";
import React, { useState, useEffect,useContext } from "react";
import apiUrl from "../apiConfig";
import Visibilitychange from "./VisibilitychangeModal";
import Switch from "@mui/material/Switch";
import { Accordion } from "keep-react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FaSpinner } from "react-icons/fa";
import { Label, Radio } from "keep-react";
import { logincontext } from "../contexts/Logincontext";


export default function ChangeVisibility({ email }) {
  const token = localStorage.getItem("token");
  const [visibility, setVisibility] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [name, setName] = useState("public");
  const [hideName, setHideName] = useState("nothing");
  const [hideEmail, setHideEmail] = useState("none");
  const [hidePhone, setHidePhone] = useState("no-one");
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [visibilityName, setVisibilityName] = useState("");
  const [savedMessage, setSavedMessage] = useState("");




    const [
      loginerror,
      UserloginStatus,
      loggedInUser,
      Loginuser,
      Signupuser,
      VerifyOTP,
      Logoutuser,
      setloginerror,
      SignupEmployer,
      LoginEmployer,
  changeloggedInUser,
    ] = useContext(logincontext);


    useEffect(() => {
      if(loggedInUser?.complete_profile?.profile?.isPublic){
        setVisibilityName("Public")
      }
      else{
        setVisibilityName("Private")
      }
      setHideName(loggedInUser?.complete_profile?.profile?.namePrivacy)
      if (loggedInUser?.complete_profile?.profile?.namePrivacy === "firstname") {
        setDisplayName(`${loggedInUser?.complete_profile?.user?.Firstname.charAt(0)} ${loggedInUser?.complete_profile?.user?.Lastname}`);
      } else if (loggedInUser?.complete_profile?.profile?.namePrivacy === "lastname") {
        setDisplayName(`${loggedInUser?.complete_profile?.user?.Firstname} ${loggedInUser?.complete_profile?.user?.Lastname.charAt(0)}`);
      }else{
        setDisplayName(`${loggedInUser?.complete_profile?.user?.Firstname} ${loggedInUser?.complete_profile?.user?.Lastname}`)
      }
    },[])


  const setCloseModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };


  const handleVisibilityChange = () => {
    if (visibilityName === "Public") {
      setVisibilityName("Not Public");
    } else {
      setVisibilityName("Public");
    }
  };


  const handleNameChange = (hideOption) => {
    setHideName(hideOption);
    switch (hideOption) {
      case "firstname":
        setDisplayName(`${loggedInUser?.complete_profile?.user?.Firstname.charAt(0)} ${loggedInUser?.complete_profile?.user?.Lastname}`);
        break;
      case "lastname":
        setDisplayName(`${loggedInUser?.complete_profile?.user?.Firstname} ${loggedInUser?.complete_profile?.user?.Lastname.charAt(0)}`);
        break;
      case "fullname":
      default:
        setDisplayName(`${loggedInUser?.complete_profile?.user?.Firstname} ${loggedInUser?.complete_profile?.user?.Lastname}`);
        break;
    }
  };

  const handleSubmitForm = async () => {
    const reqbody = {
      isPublic: visibilityName == "Public" ? true : false,
      namePrivacy : hideName,
    };
    // // // console,log(visibilityName);
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      const response1 = await axios.post(apiUrl.edituserdetails + token, {
        Displayname : displayName
      });
      if (response.status === 200 && response1.status === 200) {
        setSavedMessage("All changes saved successfully");
        changeloggedInUser({
          ...loggedInUser,
          complete_profile : {
            ...loggedInUser.complete_profile,
            profile : {
              ...loggedInUser.complete_profile.profile,
              namePrivacy: hideName,
              isPublic : visibilityName == "Public" ? true : false,
            }
          }
        })
      }
    } catch (error) {
      // // // console,log(error);
    }
  };

  return (
    <div className="bg-white w-full rounded-xl p-4 text-black">
      <div className="text-xl font-[550]">Privacy Settings</div>
      <br />
      <Accordion
        flush={true}
        openFirstPanel={true}
        className="flex flex-col gap-2"
      >
        <Accordion.Panel className="border border-black rounded">
          <Accordion.Container>
            <Accordion.Title className="w-[95%]">
              <div className="flex justify-between">
                <div className="text-black text-base">Name</div>
                {displayName ? (
                  <div className="text-black text-base font-normal">
                    {displayName}
                  </div>
                ) : (
                  <FaSpinner
                    className="animate-spin text-[#0052ff] mr-2"
                    size={20}
                  />
                )}
              </div>
            </Accordion.Title>
            <MdKeyboardArrowRight size={20} />
          </Accordion.Container>
          <Accordion.Content>
            <form className="flex flex-col gap-1 text-black px-4 md:w-1/4 w-full">
              <div>
                <fieldset
                htmlFor="firstname"
                  className="items-center gap-2"
                >
                  <Label
                    htmlFor="firstname"
                    className="flex text-black font-normal justify-between"
                  >
                    Hide First Name
                    <Radio
                      id="firstname"
                      name="name"
                      className="text-black"
                      checked={hideName === "firstname"}
                      onChange={() => handleNameChange("firstname")}
                    />
                  </Label>
                </fieldset>
              </div>
              <div>
                <fieldset className="items-center gap-2">
                  <Label
                    htmlFor="lastname"
                    className="flex text-black font-normal justify-between"
                  >
                    Hide Last Name
                    <Radio
                      id="lastname"
                      name="name"
                      className="text-black"
                      checked={hideName === "lastname"}
                      onChange={() => handleNameChange("lastname")}
                    />
                  </Label>
                </fieldset>
              </div>
              <div>
                <fieldset className="items-center gap-2">
                  <Label
                    htmlFor="nothing"
                    className="flex text-black font-normal justify-between"
                  >
                    Do not hide
                    <Radio
                      id="nothing"
                      name="name"
                      className="text-black"
                      checked={hideName === "nothing"}
                      onChange={() => handleNameChange("nothing")}
                    />
                  </Label>
                </fieldset>
              </div>
            </form>
          </Accordion.Content>
        </Accordion.Panel>
        {/* <Accordion.Panel className="border border-black rounded">
          <Accordion.Container>
            <Accordion.Title className="w-[95%] ">
              <div className="flex justify-between">
                <div className="text-black text-base">Visibility</div>
                {visibilityName ? (
                  <div className="text-black text-base font-normal">
                    {visibilityName}
                  </div>
                ) : (
                  <FaSpinner
                    className="animate-spin text-[#0052ff] mr-2"
                    size={20}
                  />
                )}
              </div>
            </Accordion.Title>
            <MdKeyboardArrowRight size={20} />
          </Accordion.Container>
          <Accordion.Content>
            <div className="flex flex-col  md:w-1/4 w-full">
              <div className="flex justify-between p-2 items-center">
                <div className="flex flex-col justify-center h-full">
                  <div className="text-black font-normal">
                    Public visibility
                  </div>
                </div>
                <div>
                  <Switch
                    checked={visibilityName === "Public"}
                    onClick={handleVisibilityChange}
                  />
                </div>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Panel> */}
      </Accordion>
      <br />
      {savedMessage != "" ? (
        <div className="flex items-center text-green-700 pt-2">
          <TiTick />
          {savedMessage}
        </div>
      ) : (
        <div className="pt-2"> </div>
      )}
      <div className="flex justify-end">
        <button
          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
          onClick={handleSubmitForm}
        >
          Save
        </button>
      </div>
    </div>
  );
}
