import React, { useState } from "react";
import { FaRegComment, FaRegHeart, FaHeart, FaComment } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Button, Modal } from "keep-react";
import { LuPencilLine } from "react-icons/lu";
import axios from "axios";
import EditProjectModal2 from "./EditProjectModal2";
import apiUrl from "../apiConfig";
import DeleteModal from "./DeleteModal";
import { RxOpenInNewWindow } from "react-icons/rx";
import DashSecOptions from "./DashSecOptions";
import { useEffect } from "react";
import { useRef } from "react";

export default function Projects({ props }) {
  const [like, setlike] = useState(true);
  const [comment, setcomment] = useState(false);
  const [showprojectmodal, setshowprojectmodal] = useState(false);

  const [exp, setexp] = useState({});
  const [desc, setdesc] = useState(false);
  const compdesc = (role) => {
    if (desc) {
      return role.description;
    } else {
      return role.description.slice(0, 200);
    }
  };

  const [rolenumber, setrolenumber] = useState(0);
  const [indexnumber,setindexnumber] = useState(0);
  const getMonthNumber = (monthName) => {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };

    return months[monthName] || "Invalid month";
  };
  const gettotalmonthsyears = (month1, year1, month2, year2) => {
    // Parse the start and end dates, handling invalid input gracefully
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });
    const currentYear = currentDate.getFullYear();

    const startMonth = month1 || currentMonth;
    const startYear = year1 || currentYear;
    const startDate = new Date(startYear, getMonthNumber(startMonth) - 1, 1);
    const endDate = new Date(year2, getMonthNumber(month2) - 1, 1);
    // Ensure valid dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return "Invalid date input";
    }

    // Calculate the difference in years and months
    let years = startDate.getFullYear() - endDate.getFullYear();
    let months = startDate.getMonth() - endDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    // Return the difference as a string
    if (years === 0) {
      return `${months} ${months === 1 ? "month" : "months"}`;
    }
    if (months === 0) {
      return `${years} ${years === 1 ? "year" : "years"}`;
    }
    return `${years} ${years === 1 ? "year" : "years"} and ${months} ${
      months === 1 ? "month" : "months"
    }`;
  };

  const setinglike = () => {
    setlike(!like);
  };
  const commentadd = () => {
    setcomment(!comment);
  };
  const [showModal, setShowModal] = useState(false);
  const onClick = () => {
    // // // console,log(props);
    setShowModal(!showModal);
  };

  const handleDeleteProject = async () => {
    try {
      const reqbody = {
        projects: props.loggedInUser.complete_profile.profile.professionalDetails.projects.filter((item, index) => index !== indexnumber),
      };
      // console.log(reqbody,rolenumber)
      const response = await axios.post(
        apiUrl.profile.editprofile + props.token,
        reqbody
      );
      // // // console,log(response);
      if (response.status === 200) {
        // alert("Deleted Successfully");
        setShowModal(!showModal);
        // Reload the page after successful deletion
        // window.location.reload();
        // props.setapi(!props.api);

        const isProfileStatusChange  = props.loggedInUser.complete_profile.profile.professionalDetails.projects.length == 1 ? true : false
        props.changeloggedInUser({
          ...props.loggedInUser,
          profile_status : {
            ...props.loggedInUser.profile_status,
            Projects : !isProfileStatusChange
          },
          complete_profile: {
            ...props.loggedInUser.complete_profile,
            profile: {
              ...props.loggedInUser.complete_profile.profile,
              professionalDetails: {
                ...props.loggedInUser.complete_profile.profile.professionalDetails,
                projects: props.loggedInUser.complete_profile.profile.professionalDetails.projects.filter((item, index) => index !== indexnumber),
              },
            },
          },
        })
      } else {
        // // // console,log(response.message);
      }
    } catch (error) {
      // // // console,log(error.response.body);
    }
  };

  const handleDelete = (roleId) => {
    setindexnumber(props.loggedInUser.complete_profile.profile.professionalDetails.projects.findIndex((item) => item._id === roleId));
    setrolenumber(props.item.roles.findIndex((item) => item._id === roleId));
    setShowModal(!showModal);
  }

  const handleEdit = (roleId) => {
    const number = props.item.roles.findIndex((item) => item._id === roleId);
    setrolenumber(number);
    const exp1 = {
      company: props.item.company,
      ...props.item.roles[number],
    };
    setexp(exp1);
    handleOpenEditProjectModal(null);
  };

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  const updateDivHeight = () => {
    if (divRef.current) {
      const height = divRef.current.offsetHeight;
      setDivHeight(height);
    }
  };

  useEffect(() => {
    // Calculate height on mount and when roles change
    updateDivHeight();

    // Add a resize event listener to handle responsiveness
    window.addEventListener("resize", updateDivHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, [props.item.roles]);

  const handleOpenEditProjectModal = (project) => {
    setshowprojectmodal(!showprojectmodal);
    if (project !== null) {
      props.changeloggedInUser({
        ...props.loggedInUser,
        complete_profile: {
          ...props.loggedInUser.complete_profile,
          profile: {
            ...props.loggedInUser.complete_profile.profile,
            professionalDetails: {
              ...props.loggedInUser.complete_profile.profile.professionalDetails,
              projects: props.loggedInUser.complete_profile.profile.professionalDetails.projects.map((item) => {
                if (item._id === project._id) {
                  return project;
                }
                return item;
              }),
            },
          },
        },
      })
    }
  }

  return (
    <div className="pt-2">
      {showprojectmodal && (
        <EditProjectModal2
          handleOpenEditProjectModal={handleOpenEditProjectModal}
          values={exp}
          api={props.api}
          setapi={props.setapi}
        />
      )}

      {showModal && (
        <DeleteModal
          onClick={onClick}
          handleDeleteEducation={handleDeleteProject}
          inst={props.item.roles[rolenumber].Title}
          name={"project"}
        />
      )}
      <div className="flex justify-between w-[95%] ">
        <div className="flex gap-2 flex-col  w-full ">
          <div className=" flex gap-2 items-center">
            <div className="md:h-[50px] h-[40px] aspect-square">
              {props.image && (
                <img
                  src={props.image}
                  alt="Company Logo"
                  className={
                    props.image.startsWith("https://")
                      ? " rounded-md "
                      : " rounded-md  border-[1.5px] p-1 border-black "
                  }
                />
              )}
            </div>
            <div className=" flex flex-col  justify-center h-full ">
              <p className="font-[550]">{props.item.company} </p>
              <p className="text-sm text-gray-400">
                {gettotalmonthsyears(
                  props.item.roles[0].toMonth,
                  props.item.roles[0].toYear,
                  props.item.roles[props.item.roles.length - 1].fromMonth,
                  props.item.roles[props.item.roles.length - 1].fromYear
                )}
              </p>
            </div>
          </div>
          <div className="text-md w-full relative flex gap-2 ">
            <div className="w-[40px] md:w-[50px] relative">
              <div className=" absolute h-full border-[1px]  left-1/2 "></div>
            </div>
            <div className="flex flex-col gap-0 w-full">
              <div className=" flex flex-col gap-4 w-full ">
                {props.item.roles.map((role, key) => (
                  <div
                    key={key}
                    className=" flex justify-between items-start w-full relative"
                  >
                    {key === props.item.roles.length - 1 && (
                      <div
                        style={{
                          // height: `85%`, // Apply the calculated height here if needed
                          top: `${divHeight / 2 + 1.5}px`, // Dynamically apply the top value
                        }}
                        className="absolute border-[5px] sm:h-[91%] h-[84%]  border-white left-[-27px] md:left-[-32px]"
                      ></div>
                    )}

                    <div className="flex flex-col gap-0  ">
                      <div ref={divRef} className=" flex relative">
                        <div className="absolute left-[-25px] top-1/2 md:left-[-30px] border-[1px]  w-[20px] md:w-[25px]  " />
                        <h1 className="text-sm font-[550]  px-1">
                          {role.Title}
                        </h1>
                      </div>
                      <div>
                        <p className=" text-sm px-1">
                          {" "}
                          {role.skills.length !== 0 && (
                            <span className="font-[550]">Skills: </span>
                          )}
                          {role.skills.map((item, key) => (
                            <span key={key}>
                              {item}
                              {item === role.skills[role.skills.length - 1]
                                ? "."
                                : ","}{" "}
                            </span>
                          ))}
                        </p>
                        <p className="text-sm text-gray-400 px-1">
                          {role.fromMonth} {role.fromYear} -{" "}
                          {!role.toMonth ? (
                            <span>Present</span>
                          ) : (
                            <span>
                              {role.toMonth} {role.toYear}
                            </span>
                          )}
                        </p>
                        {/* <p>Description: </p> */}
                        <p className="text-sm text-justify w-full   px-1">
                          {compdesc(role)}
                          {role.description.length >= 200 && (
                            <button
                              onClick={() => setdesc(!desc)}
                              className="text-gray-400"
                            >
                              {desc ? " ...show less" : " ...show more"}
                            </button>
                          )}{" "}
                        </p>
                        <div className="py-2 w-fit px-1">
                          {role.link && (
                            <a
                              className=" border-gray-100 flex justify-center items-center gap-2 hover:bg-slate-100 hover:shadow-lg text-sm duration-300 cursor-pointer border-2 p-2 rounded-2xl"
                              target="_blank"
                              href={
                                role.link.startsWith("https://")
                                  ? role.link
                                  : "https://" + role.link
                              }
                            >
                              <span>View project</span>
                              <RxOpenInNewWindow />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    {!props.publicview && (
                      <div className="">
                        <DashSecOptions
                          edititem={() => handleEdit(role._id)}
                          deleteitem={() => {
                            // // console,log(key, props.item.roles[key].school);
                            handleDelete(role._id);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.length - 1 !== props.key && (
         <hr className="text-center w-full mt-2" />
      )}
    </div>
  );
}

{
  /* <div className="w-full flex justify-around my-4 p-2">
  {like ? (
    <div
      className="flex flex-row justify-center items-center gap-2 cursor-pointer"
      onClick={setinglike}
    >
      <FaRegHeart size={20} />
      <p className=" hidden sm:flex text-lg ">Like</p>
    </div>
  ) : (
    <div
      className="flex flex-row justify-center items-center gap-2 cursor-pointer"
      onClick={setinglike}
    >
      <FaHeart size={20} color="red" />
      <p className=" hidden sm:flex text-lg ">Unlike</p>
    </div>
  )}

  <div className="flex flex-row justify-center items-center gap-2 cursor-pointer" onClick={commentadd}>
      {
          comment ? 
          
          <FaComment size={20}/>
          :
          <FaRegComment size={20} />
      }
    <p className=" hidden sm:flex text-lg">Comment</p>
  </div>
</div>

{
  comment && 
  <form action="" className="flex flex-col justify-center items-center gap-6">
      <textarea name="comment" id="comment" cols="20" rows="5" className="w-full border-2 border-black rounded-xl p-4 ">

      </textarea>
      <button
      type="submit"
      className="bg-blue-800 hover:bg-blue-900 duration-300 text-white font-[550] py-1 px-4 rounded-xl"
    >
      Submit
    </button>
  </form>
} */
}
