import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import axios from "axios";
import apiUrl from "../apiConfig";
import Spinnerpage from "./Spinnerpage";

export default function Educationreview({}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [edudetails, seteducationdetails] = useState([]);

  const deleteEducation = async (index) => {
    // // // console,log(index);
    const updatedEducationData = edudetails.filter((_, i) => i !== index);
    const reqbody = {
      education: updatedEducationData,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      if (response.status === 200) {
        // // // console,log(response.data.existing_profile.projects);
        seteducationdetails(updatedEducationData);
      }
    } catch (error) {}
  };
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const geteducation = async () => {
      try {
        setLoading(true);
        const response = await axios.post(apiUrl.profile.getprofile + token);
        if (response.status === 200) {
          // // // console,log(response.data.existing_profile.projects);
          // console.log(
          //   response.data.complete_profile.profile.professionalDetails.education
          // );
          seteducationdetails(
            response.data.complete_profile.profile.professionalDetails.education
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const postProfileStatus = async () => {
      try {
        const reqbody = {
          profilestatus: "/signup/education-review",
        };
        const response = await axios.post(
          apiUrl.profile.editprofile + `${token}`,
          reqbody
        );
        if (response.status === 200) {
        }
      } catch (error) {
        //// console.log(error);
      }
    };

    postProfileStatus();

    geteducation();
  }, []);

  const editeducation = () => {
    navigate(`/signup/add-education`);
  };
  const submitform = async (data) => {
    navigate(`/signup/add-project`);
    window.scroll(0, 0);
  };

  return (
    <div>
      <div className=" h-1 w-full flex">
        <div className="h-full bg-[#0052ff] w-[50%]"></div>
        <div className="h-full "></div>
      </div>
      <div className="m-2 p-2">
        <h2 className="py-3 text-2xl font-[550] font-sans text-center">
          Education Review
        </h2>
        <ul>
          {loading ? (
            <Spinnerpage notfullpage={true} />
          ) : edudetails.length !== 0 ? (
            edudetails.map((education, index) => (
              <li
                key={index}
                className="p-4 m-2  rounded-md  flex flex-wrap justify-between "
              >
                <div className="w-[80%]">
                  <div className="text-[0.95rem] font-[550]">
                    {education.school}
                  </div>
                  <div className=" text-sm">
                    {education.levelofedu} · {education.field}
                  </div>

                  <div className="text-sm">{education.city}</div>
                  <p className="text-sm text-gray-400 ">
                    {education.from} - {education.to}
                  </p>
                </div>
                <div className="gap-3 flex">
                  {/* <button onClick={() => editEducation(index)}>
                  <AiFillEdit size={25} />
                </button> */}
                  <button onClick={() => deleteEducation(index)}>
                    <MdDeleteForever color="red" size={25} />
                  </button>
                </div>
              </li>
            ))
          ) : (
            <div className=" text-center w-full text-gray-500 py-4">
              Add atleast one education to continue
            </div>
          )}
        </ul>

        <div className="flex justify-between">
          <button
            className="text-[#0052ff] hover:text-[#014cec] font-[550] flex justify-center items-center gap-1"
            onClick={editeducation}
          >
            <IoIosAddCircleOutline size={20} /> Add Education
          </button>

          <button
            className={`  font-[550] py-1 px-4 rounded-3xl my-4 md:my-0 ${
              edudetails.length !== 0
                ? " bg-[#0052ff] text-white   hover:bg-[#014cec]"
                : "bg-gray-600 text-white   hover:bg-gray-500 cursor-not-allowed"
            }`}
            onClick={edudetails.length !== 0 ? submitform : null}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
