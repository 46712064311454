import React, { useEffect, useState, useContext } from "react";
import { FaCamera, FaRegAddressCard } from "react-icons/fa6";
import { FaHackerrank, FaLinkedin } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiCodechef } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import dummyDP from "../../asserts/dummyDp.png";
import coverphoto from "../../asserts/coverphoto.png";
import AddResumeModal from "../AddResumeModal";
import ProfilepicModal from "../ProfilepicModal";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { LuPencilLine, LuPlusCircle } from "react-icons/lu";
import { LuMinusCircle } from "react-icons/lu";
import EditSocialAcc from "../EditSocialAcc";
import { IoCamera } from "react-icons/io5";
import ComponentOrderModal from "../ComponentOrderModal";
import DropDown from "../DropDown";
import { useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import LinkedInImage from "../../images/LinkedIn.jpeg";
import GitHubImage from "../../images/GitHub.jpeg";
import XImage from "../../images/X.jpeg";
import Connectionsdropdown from "../NetworkComponents/Connectionsdropdown";
import { WebSocketContext } from "../../contexts/WebSocketContext";
import DefaultCompany from "../../images/DefaultCompany.png";
import DefaultEducation from "../../images/DefaultEducation.png";
import instagram from "../../images/instagram.png";
import discord from "../../images/discord.png";
import Website from "../../images/website.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import RemoveConnectionModal from "../NetworkComponents/RemoveConnectionModal";
import DeleteModal from "../DeleteModal";
import { set } from "react-hook-form";
import getConnectionLabel from "../../asserts/NoofConnections";
import { MdOutlineGroup } from "react-icons/md";
import { logincontext } from "../../contexts/Logincontext";
import Spinnerpage from "../Spinnerpage";

export default function PersonalDetails({
  toggleaddsection,
  handleEditProfile,
  dash,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  //To Be Deleted
  // const [handleEditProfile, sethandleEditProfile] = useState(false);
  const [api, setapi] = useState(false);
  // const [toggleaddsection, settoggleaddsection] = useState(false);
  const [url, seturl] = useState("");
  const [manageCompModal, setmanageCompModal] = useState(false);
  const [openCompModal, setopenCompModal] = useState(false);
  const [coverpicmodal, setcoverpicmodal] = useState(false);
  const [profilepicmodal, setprofilepicmodal] = useState(false);
  const [image, setimage] = useState("");
  const [connectionres, setconnectionres] = useState("loading");
  const fetchData = async () => {
    try {
      const response = await axios.post(apiUrl.feed.getusersdetails + token, {
        ids: [dash.complete_profile.newUserId],
      });
      if(response.status == 200){
        // console.log(response.data)
        setconnectionres(response.data.profiles[0].connectionStatus);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const buttontextstyle = "";
  // const buttonstyle = 'bg-[#0052ff] text-white   hover:bg-[#014cec]  py-1 px-4 rounded-3xl mx-2'
  const buttonstyle =
    "bg-[#0052ff] text-white text-xs md:text-sm hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0";
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [showaddresumemodal, setShowaddresumemodal] = useState(false);
  const [openSocialInputs, setOpenSocialInputs] = useState(false);
  const [social, setsocial] = useState({});
  const [socialUrl, setsocialUrl] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const Location = useLocation();
  const toggleProfile = () => {
    setprofilepicmodal(!profilepicmodal);
    setapi(!api);
  };
  const toggleCover = () => {
    setcoverpicmodal(!coverpicmodal);
    setapi(!api);
  };
  const handleViewResume = () => {
    // newTab.focus();
    navigate(`/${url}/resume`);
  };
  const { setSelectedUser } = useContext(WebSocketContext);
  const [editpic, seteditpic] = useState(false);
  const handleAddResume = () => {
    setShowaddresumemodal(!showaddresumemodal);
    setapi(!api);
  };
  const [editsocial, seteditsocial] = useState(false);
  const toggelSocial = () => {
    seteditsocial(!editsocial);
  };

  const connectapi = async () => {
    try {
      const url = Location.pathname.split("/")[1];
      const resp = await axios.post(apiUrl.connections.request + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setapi(!api);
      }
    } catch (err) {
      // // console,log(err.message);
    }
  };

  const disconnect = async () => {
    try {
      const url = Location.pathname.split("/")[1];
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setapi(!api);
        setdisconmodal(!disconmodal);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  const accept = async () => {
    try {
      const url = Location.pathname.split("/")[1];
      const resp = await axios.post(apiUrl.connections.accept + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setapi(!api);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  function encrypt(string1, string2, shift) {
    // Use a unique delimiter that won't appear in the original strings
    const delimiter = "|";

    // Concatenate the two strings with the delimiter
    let concatenatedString = string1 + delimiter + string2;

    // Encrypt the concatenated string with a shift cipher
    let encryptedString = "";

    for (let i = 0; i < concatenatedString.length; i++) {
      let charCode = concatenatedString.charCodeAt(i);

      // Shift the character code
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 65 + shift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 97 + shift) % 26) + 97
        );
      } else {
        encryptedString += concatenatedString[i]; // Non-alphabetical characters
      }
    }

    return encryptedString;
  }

  const handleMessage = async () => {
    try {
      console.log(loggedInUser);
      const chaturl = encrypt(dash.UserId, loggedInUser.complete_profile.UserId, 7);
      setSelectedUser(dash);
      navigate(`/chats/${chaturl}`);
    } catch (error) {
      // // console,log(error);
    }
  };
  const reject = async () => {
    try {
      const url = Location.pathname.split("/")[1];
      // // // console,log(url)
      const resp = await axios.post(apiUrl.connections.reject + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setapi(!api);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };
  const [formattedDate, setFormattedDate] = useState("");
  useEffect(() => {
    const editdatejoined = () => {
      const date = new Date(dash?.complete_profile?.user?.createdAt);

      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      setFormattedDate(`${month}, ${year}`);
    };
    editdatejoined();
  }, [dash]);

  function sortByFromYearDesc(educationArray) {
    educationArray.sort(function (a, b) {
      return Number(b.fromYear) - Number(a.fromYear);
    });
    return educationArray[0];
  }

  function sortByToYearDescExperience(experienceArray) {}

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const findimage = (name, type) => {
    const company = companies.find((obj) => obj.Name === name);
    const comimage = company ? company.s3Link : DefaultCompany;
    const university = universities.find((obj) => obj.Name === name);
    const schimage = university ? university.s3Link : DefaultEducation;
    return type === "job" ? comimage : schimage;
  };

  const handleOpenSocialInputs = () => {
    setError("");
    setMessage("");
    setsocial({});
    setsocialUrl("");
    setOpenSocialInputs(!openSocialInputs);
  };

  const validateSocialUrl = () => {
    let regex;
    switch (social) {
      case "LinkedIn":
        regex = /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/;
        break;
      case "Github":
        regex = /^https:\/\/github\.com\/[A-Za-z0-9_-]+$/;
        break;
      case "Twitter":
        regex = /^(https:\/\/(?:x\.com|twitter\.com)\/[A-Za-z0-9_-]+)$/;
        break;
      case "Instagram":
        regex = /^https:\/\/instagram\.com\/[A-Za-z0-9_.-]+$/;
        break;
      case "Discord":
        regex = /^https:\/\/discord\.com\/[A-Za-z0-9_-]+$/;
        break;
      case "Website":
        regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
        break;
    }
    return regex.test(socialUrl);
  };
  const addSocialUrl = async () => {
    if (validateSocialUrl()) {
      let reqbody = {
        LinkedIn: dash?.complete_profile?.user?.socialaccounts?.LinkedIn
          ? dash?.complete_profile?.user?.socialaccounts?.LinkedIn
          : "",
        Github: dash?.complete_profile?.user?.socialaccounts?.Github
          ? dash?.complete_profile?.user?.socialaccounts?.Github
          : "",
        Twitter: dash?.complete_profile?.user?.socialaccounts?.Twitter
          ? dash?.complete_profile?.user?.socialaccounts?.Twitter
          : "",
        Instagram: dash?.complete_profile?.user?.socialaccounts?.Instagram
          ? dash?.complete_profile?.user?.socialaccounts?.Instagram
          : "",
        Discord: dash?.complete_profile?.user?.socialaccounts?.Discord
          ? dash?.complete_profile?.user?.socialaccounts?.Discord
          : "",
        Website: dash?.complete_profile?.user?.socialaccounts?.Website
          ? dash?.complete_profile?.user?.socialaccounts?.Website
          : "",
      };
      reqbody[social] = socialUrl;
      try {
        const resp = await axios.post(apiUrl.profile.editprofile + token, {
          socialaccounts: reqbody,
        });
        if (resp && resp.status === 200) {
          // setOpenSocialInputs(!openSocialInputs);
          setError("");
          setMessage("Social accounts updated successfully");
          setapi(!api);
        }
      } catch (err) {
        // // // console,log("err", err.message);
      }
    } else {
      setMessage("");
      setError(`Invalid ${social} URL`);
    }
  };

  const [disconmodal, setdisconmodal] = useState(false);

  return (
    <div>
      {showaddresumemodal && (
        <AddResumeModal
          showaddresumemodal={showaddresumemodal}
          setShowaddresumemodal={setShowaddresumemodal}
        />
      )}
      {dash?.isloggedinuser && profilepicmodal && (
        <ProfilepicModal
          toggleProfile={toggleProfile}
          dummyDP={dummyDP}
          image={image}
          setimage={setimage}
          title="Profile picture"
          setapi={setapi}
          api={api}
        />
      )}

      {dash?.isloggedinuser && openCompModal && (
        <ComponentOrderModal
          manageCompModal={() => setopenCompModal(!openCompModal)}
          url={url}
          dash={dash}
        />
      )}
      {editsocial && (
        <EditSocialAcc
          loggedInUser={loggedInUser}
          changeloggedInUser={changeloggedInUser}
          handleEditProfile={toggelSocial}
          openModal={editsocial}
          api={api}
          setapi={setapi}
        />
      )}
      {disconmodal && (
        <DeleteModal
          onClick={() => setdisconmodal(!disconmodal)}
          handleDeleteEducation={disconnect}
          inst={dash.FullName.DisplayName}
          name={"remove connection"}
        />
      )}
      <div className="relative border-[1px] md:rounded-md overflow-x-hidden">
        <div className={`w-full p-2 z-0  md:rounded-md  bg-white text-black`}>
          <div className="flex flex-col md:flex-row rounded">
            <div
              className={`md:w-[25%] w-[30%]   z-0 flex  items-center cursor-pointer justify-center rounded-full  bg-white text-black`}
              onClick={dash?.isloggedinuser ? toggleProfile : () => {}}
              onMouseEnter={() => seteditpic(true)}
              onMouseLeave={() => seteditpic(false)}
            >
              {editpic && dash?.isloggedinuser && (
                <div className=" md:w-[24%] w-[30%] rounded-md  aspect-square z-[4] absolute bg-[#29282860] text-white">
                  {" "}
                  <div className="w-full h-full flex flex-col justify-center items-center">
                    <FaCamera />
                    <div>Edit Picture</div>
                  </div>
                </div>
              )}
              {dash?.complete_profile?.profile?.profilePic != "" && (
                <div>
                  <img
                    src={dash?.complete_profile?.profile?.profilePic}
                    className=" w-full h-full rounded-lg aspect-square z-10 border-2 border-white bg-white"
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className=" md:w-[75%] w-full flex flex-col justify-between  md:px-2 ">
              <div className="z-0 w-full ">
                <div className="flex flex-row justify-between  w-full z-0 items-center flex-wrap">
                  <div className="w-full">
                    <div className="flex  justify-between w-full items-center ">
                      <div className="md:text-2xl text-xl capitalize font-[550]">
                       
                        {dash?.complete_profile?.profile?.namePrivacy ===
                        "firstname"
                          ? `${dash?.complete_profile?.user?.Firstname?.charAt(
                              0
                            )} ${dash?.complete_profile?.user?.Lastname}`
                          : dash?.complete_profile?.profile?.namePrivacy ===
                            "lastname"
                          ? `${
                              dash?.complete_profile?.user?.Firstname
                            } ${dash?.complete_profile?.user?.Lastname?.charAt(
                              0
                            )}`
                          : dash?.complete_profile?.profile?.namePrivacy ===
                            "nothing"
                          ? `${dash?.complete_profile?.user?.Firstname} ${dash?.complete_profile?.user?.Lastname}`
                          : `${dash?.complete_profile?.user?.Firstname} ${dash?.complete_profile?.user?.Lastname}`}
                      </div>
                      {dash?.isloggedinuser && (
                        <div
                          className=" p-2 bg-white rounded-full cursor-pointer hover:bg-[#f0f1f4]"
                          onClick={handleEditProfile}
                        >
                          <LuPencilLine />
                        </div>
                      )}
                    </div>
                    <div className="text-gray-500 ">
                      {dash?.complete_profile?.profile?.headline?.trim() ? (
                        dash?.complete_profile?.profile?.headline
                      ) : (
                        <br />
                      )}
                    </div>
                    {/* 
                    <h2 className="text-md">{dash.Location.City}</h2> */}
                  </div>
                </div>
              </div>
              {/* <button onClick={() => // // console,log(dash)} >button</button> */}

              <div className="flex flex-col gap-y-2 py-2 h-full items-start  ">
                <div className=" h-full  ">
                  {dash?.complete_profile?.user?.createdAt && (
                    <div className="text-sm flex items-center gap-1 mt-2">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                        />
                      </svg>
                      <div>Joined {formattedDate}</div>
                    </div>
                  )}

                  {/* <span className="md:hidden px-1">·</span> */}
                  {dash?.complete_profile?.profile?.connections
                    ?.connections && (
                    <div className="text-sm flex items-center gap-1 mt-2">
                      <div className="text-sm flex items-center gap-1">
                        {" "}
                        <MdOutlineGroup
                          className="size-5 text-center focus:outline-none"
                          aria-hidden="true"
                        />
                        {getConnectionLabel(
                          dash?.complete_profile?.profile?.connections
                            ?.connections?.length
                        )}
                      </div>
                    </div>
                  )}

                  {dash?.complete_profile?.user?.address?.city &&
                    dash?.complete_profile?.user?.address?.city?.trim() !==
                      "" && (
                      <div className="text-sm flex items-center gap-1 mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5 "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                          />
                        </svg>
                        <div>{dash?.complete_profile?.user?.address?.city}</div>
                      </div>
                    )}
                  {dash?.complete_profile?.profile?.professionalDetails
                    ?.experience &&
                    dash?.complete_profile?.profile?.professionalDetails
                      ?.experience?.length !== 0 && (
                      <div className="text-sm flex items-center gap-1 w-full mt-2">
                        <img
                          src={findimage(
                            sortByFromYearDesc(
                              dash?.complete_profile?.profile
                                ?.professionalDetails?.experience || []
                            )?.company,
                            "job"
                          )}
                          alt=""
                          className="size-5 rounded-sm"
                        />
                        <span className="truncate block ">
                          {
                            sortByFromYearDesc(
                              dash?.complete_profile?.profile
                                ?.professionalDetails?.experience || []
                            )?.company
                          }
                        </span>
                      </div>
                    )}

                  {dash?.complete_profile?.profile?.professionalDetails
                    ?.education &&
                    dash?.complete_profile?.profile?.professionalDetails
                      ?.education?.length !== 0 && (
                      <div className="text-sm flex items-center gap-1 w-full mt-2">
                        <img
                          src={findimage(
                            sortByFromYearDesc(
                              dash?.complete_profile?.profile
                                ?.professionalDetails?.education || []
                            )?.school,
                            "school"
                          )}
                          alt=""
                          className="size-5 rounded-sm"
                        />
                        <span className="truncate block ">
                          {
                            sortByFromYearDesc(
                              dash?.complete_profile?.profile
                                ?.professionalDetails?.education || []
                            )?.school
                          }
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex z-0  w-full justify-between flex-wrap flex-col">
            <div className=" text-center flex w-full h-fit md:items-center gap-1 md:py-2 ">
              {token &&
                !dash?.isloggedinuser &&
                (connectionres === "notConnected" ? (
                  <div
                    className=" text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl "
                    onClick={connectapi}
                  >
                    <div className="flex gap-1 justify-center items-center">
                      <p className={buttontextstyle}>Connect</p>
                    </div>
                  </div>
                ) : connectionres === "connected" ? (
                  <div className="flex gap-4 items-center">
                    <div
                      className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl "
                      onClick={handleMessage}
                    >
                      <p className={buttontextstyle}>Message</p>
                    </div>
                  </div>
                ) : connectionres === "pending" ? (
                  <div
                    className=" text-red-500 border-[1.5px] border-red-500 hover:bg-red-100  cursor-pointer font-[550] py-1 md:px-4 px-2 text-sm md:text-left text-center rounded-3xl "
                    onClick={reject}
                  >
                    <div className="flex gap-1 justify-center items-center">
                      <p className={buttontextstyle}>withdraw request</p>
                    </div>
                  </div>
                ) : connectionres === "loading" ? (<Spinnerpage notfullpage={true}/>) : (
                  <div className=" flex gap-4">
                    <div
                      className=" text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl "
                      onClick={accept}
                    >
                      <div className="flex gap-1 justify-center items-center">
                        <p className={buttontextstyle}>Accept</p>
                      </div>
                    </div>
                    <div
                      className=" text-red-500 border-[1.5px] border-red-500 hover:bg-red-100  cursor-pointer font-[550] py-1 md:px-4 px-2 text-sm md:text-left text-center rounded-3xl "
                      onClick={reject}
                    >
                      <div className="flex gap-1 justify-center items-center">
                        <p className={buttontextstyle}>Reject</p>
                      </div>
                    </div>
                  </div>
                ))}

              {dash?.isloggedinuser && (
                <div className={buttonstyle} onClick={toggleaddsection}>
                  <div className="flex gap-1 justify-center items-center">
                    <p className={buttontextstyle}>Add Section</p>
                  </div>
                </div>
              )}

              {dash?.isloggedinuser && (
                <DropDown
                  manageCompModal={() => setopenCompModal(!openCompModal)}
                />
              )}
            </div>
            <div>
              {dash?.complete_profile?.user?.socialaccounts && (
                <div>
                  <hr className="" />
                  <div className=" flex md:gap-5 gap-3  lg:py-4 md:py-6 p-2   items-center">
                    {/* {dash.Resume != "" && (
                    <div className="flex flex-col items-center gap-1 hover:text-blue-600">
                      <div
                        className={`${
                          dash.Resume === "" ? "hidden" : "block"
                        } bg-white border rounded-full border-gray-300 p-1`}
                      >
                        <FaRegAddressCard
                          size={30}
                          className=" cursor-pointer p-1"
                          onClick={handleViewResume}
                        />
                      </div>
                      <div className="text-xs font-[550]">Resume</div>
                    </div>
                  )} */}

                    {dash?.complete_profile?.user?.socialaccounts?.LinkedIn && (
                      <a
                        href={
                          dash?.complete_profile?.user?.socialaccounts?.LinkedIn
                        }
                        className="flex flex-col items-center gap-1 hover:text-blue-600"
                        target="__blank_"
                      >
                        <div
                          className={`${
                            dash?.complete_profile?.user?.socialaccounts
                              ?.LinkedIn
                              ? "block"
                              : "hidden"
                          } bg-white md:border md:rounded-md md:border-gray-300 md:p-1`}
                        >
                          {/* <FaLinkedin size={25} /> */}
                          <img
                            src={LinkedInImage}
                            alt="LinkedIn Image"
                            className="md:p-1 md:rounded-md rounded-sm size-5 md:size-8"
                            // width={30}
                          />
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          LinkedIn
                        </div>
                      </a>
                    )}

                    {dash?.complete_profile?.user?.socialaccounts?.Twitter && (
                      <a
                        href={
                          dash?.complete_profile?.user?.socialaccounts?.Twitter
                        }
                        className="flex flex-col items-center gap-1 hover:text-blue-600"
                        target="__blank_"
                      >
                        <div
                          className={`${
                            dash?.complete_profile?.user?.socialaccounts
                              ?.Twitter
                              ? "block"
                              : "hidden"
                          } bg-white md:border md:rounded-md md:border-gray-300 md:p-1`}
                        >
                          {/* <FaLinkedin size={25} /> */}
                          <img
                            src={XImage}
                            alt="X Image"
                            className="md:p-1 md:rounded-md rounded-sm size-5 md:size-8"
                            // width={30}
                          />
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          X (Twitter)
                        </div>
                      </a>
                    )}

                    {dash?.complete_profile?.user?.socialaccounts?.Website && (
                      <a
                        href={
                          dash?.complete_profile?.user?.socialaccounts?.Website
                        }
                        className="flex flex-col items-center gap-1 hover:text-blue-600"
                        target="__blank_"
                      >
                        <div
                          className={`${
                            dash?.complete_profile?.user?.socialaccounts
                              ?.Website
                              ? "block"
                              : "hidden"
                          } bg-white md:border md:rounded-md md:border-gray-300 md:p-1`}
                        >
                          {/* <FaLinkedin size={25} /> */}
                          <img
                            src={Website}
                            alt="Website Image"
                            className="md:p-1 md:rounded-md rounded-sm size-5 md:size-8"
                            // width={30}
                          />
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          Website
                        </div>
                      </a>
                    )}

                    {dash?.complete_profile?.user?.socialaccounts?.GitHub && (
                      <a
                        href={
                          dash?.complete_profile?.user?.socialaccounts?.GitHub
                        }
                        className="flex flex-col items-center gap-1 hover:text-blue-600"
                        target="__blank_"
                      >
                        <div
                          className={`${
                            dash?.complete_profile?.user?.socialaccounts?.GitHub
                              ? "block"
                              : "hidden"
                          } bg-white md:border md:rounded-md md:border-gray-300 md:p-1`}
                        >
                          {/* <FaLinkedin size={25} /> */}
                          <img
                            src={GitHubImage}
                            alt="GitHub Image"
                            className="md:p-1 md:rounded-md rounded-sm size-5 md:size-8"
                            // width={30}
                          />
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          GitHub
                        </div>
                      </a>
                    )}

                    {dash?.complete_profile?.user?.socialaccounts
                      ?.Instagram && (
                      <a
                        href={
                          dash?.complete_profile?.user?.socialaccounts
                            ?.Instagram
                        }
                        className="flex flex-col items-center gap-1 hover:text-blue-600"
                        target="__blank_"
                      >
                        <div
                          className={`${
                            dash?.complete_profile?.user?.socialaccounts
                              ?.Instagram
                              ? "block"
                              : "hidden"
                          } bg-white md:border md:rounded-md md:border-gray-300 md:p-1`}
                        >
                          {/* <FaLinkedin size={25} /> */}
                          <img
                            src={instagram}
                            alt="Instagram Image"
                            className="md:p-1 md:rounded-md rounded-sm size-5 md:size-8"
                            // width={30}
                          />
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          Instagram
                        </div>
                      </a>
                    )}

                    {dash?.complete_profile?.user?.socialaccounts?.Discord && (
                      <a
                        href={
                          dash?.complete_profile?.user?.socialaccounts?.Discord
                        }
                        className="flex flex-col items-center gap-1 hover:text-blue-600"
                        target="__blank_"
                      >
                        <div
                          className={`${
                            dash?.complete_profile?.user?.socialaccounts
                              ?.Discord
                              ? "block"
                              : "hidden"
                          } bg-white md:border md:rounded-md md:border-gray-300 md:p-1`}
                        >
                          {/* <FaLinkedin size={25} /> */}
                          <img
                            src={discord}
                            alt="Discord Image"
                            className="md:p-1 md:rounded-md rounded-sm size-5 md:size-8"
                            // width={30}
                          />
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          Discord
                        </div>
                      </a>
                    )}

                    {dash?.isloggedinuser && (
                      <div className="flex flex-col items-center gap-1 hover:text-blue-600">
                        <div className={` bg-white md:p-1`} target="blank">
                          {openSocialInputs ? (
                            <LuMinusCircle
                              size={20}
                              className=" cursor-pointer size-5 md:size-6"
                              onClick={toggelSocial}
                              // onClick={handleOpenSocialInputs}
                            />
                          ) : (
                            <LuPlusCircle
                              size={20}
                              className=" cursor-pointer size-5 md:size-6"
                              onClick={toggelSocial}
                              // onClick={()=> }
                            />
                          )}
                        </div>
                        <div className="text-xs font-[550] md:block hidden">
                          {openSocialInputs ? "Collapse" : "Add"}
                        </div>
                      </div>
                    )}
                  </div>
                  {openSocialInputs && (
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col">
                        <label htmlFor="Social" className=" font-[550]">
                          Social Profile Name
                        </label>
                        <select
                          className={`w-full p-2 border-[1.5px]  rounded-md bg-white text-black`}
                          name="Social"
                          // required

                          value={social}
                          onChange={(e) => setsocial(e.target.value)}
                        >
                          <option value="">Select a Social Media</option>
                          {!dash?.complete_profile?.user?.socialaccounts
                            .LinkedIn && (
                            <option value="LinkedIn">LinkedIn</option>
                          )}
                          {!dash?.complete_profile?.user?.socialaccounts
                            .Twitter && (
                            <option value="Twitter">(X)Twitter</option>
                          )}
                          {!dash?.complete_profile?.user?.socialaccounts
                            .Website && (
                            <option value="Website">Website</option>
                          )}
                          {!dash?.complete_profile?.user?.socialaccounts
                            .GitHub && <option value="Github">Github</option>}
                          {!dash?.complete_profile?.user?.socialaccounts
                            .Instagram && (
                            <option value="Instagram">Instagram</option>
                          )}
                          {!dash?.complete_profile?.user?.socialaccounts
                            .Discord && (
                            <option value="Discord">Discord</option>
                          )}
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="Social" className=" font-[550]">
                          URL
                        </label>
                        <input
                          type="text"
                          placeholder="Enter URL"
                          className="w-full p-2 border-[1.5px]  rounded-md"
                          value={socialUrl}
                          onChange={(e) => setsocialUrl(e.target.value)}
                        />
                      </div>
                      {error && <p className="text-red-500">{error}</p>}
                      {message && <p className="text-green-500">{message}</p>}
                      <div className="flex flex-col items-end gap-1 hover:text-blue-600">
                        <div
                          className="duration-300 font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025] h-fit"
                          onClick={addSocialUrl}
                        >
                          Add Social Link
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
