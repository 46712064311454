import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import apiUrl from "../../apiConfig";
import io from "socket.io-client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChatDetails from "./ChatArea";
import { WebSocketContext } from "../../contexts/WebSocketContext";
import { Outlet } from "react-router-dom";
import Spinnerpage from "../Spinnerpage";
import { set } from "react-hook-form";
const ActiveChats = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfilePic, setCurrentUserProfilePic] = useState("");
  // const socket = useRef(null);
  const token = localStorage.getItem("token");
  const [chatid, setchatid] = useState(null);
  const [isOnline, setIsOnline] = useState(false);
  const [lastOnline, setLastOnline] = useState(new Date());
  const {
    socket,
    selectedUser,
    setSelectedUser,
    changeUser,
    setNumberOfNotifications,
    connectSocket,
  } = useContext(WebSocketContext);
  const navigate = useNavigate();
  const location = useLocation();

  function decrypt(encryptedString, shift) {
    // Decrypt the string with the inverse shift cipher
    let decryptedString = "";

    for (let i = 0; i < encryptedString.length; i++) {
      let charCode = encryptedString.charCodeAt(i);

      // Shift the character code back
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        decryptedString += String.fromCharCode(
          ((charCode - 65 - shift + 26) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        decryptedString += String.fromCharCode(
          ((charCode - 97 - shift + 26) % 26) + 97
        );
      } else {
        decryptedString += encryptedString[i]; // Non-alphabetical characters
      }
    }

    // Split the decrypted string back into the two original strings using the delimiter
    const delimiter = "|";
    const [string1, string2] = decryptedString.split(delimiter);

    return { string1, string2 };
  }

  let { chaturl } = useParams();

  useEffect(() => {
    if (socket) {
      if (!socket.connected) {
        connectSocket();
      }
    }
  }, []);

  const [usersload, setusersload] = useState(false);
  const fetchUsers = async () => {
    if (location.pathname === "/chats") {
      // setusersload(true);
    }
    try {
      const response = await axios.post(apiUrl.chats.getactivechats + token, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let activechatusers = [];

      if (chaturl) {
        let { string1: userId, string2: current } = decrypt(chaturl, 7);
        //// // console,log(userId, current);
        let isPresent = false;
        if (response.data.activechats) {
          isPresent = response.data.activechats
            .map((user) => user.UserId)
            .includes(userId);
        }
        //// // console,log(isPresent);
        //// // console,log(apiUrl.profile.getprofilefromid + userId);
        if (!isPresent) {
          console.log("Calling");
          const response1 = await axios.post(
            apiUrl.profile.getprofilefromid + userId
          );
          //// // console,log(response1.data);
          const obj = {
            Name:
              response1.data.user.Firstname +
              " " +
              response1.data.user.Lastname,
            UserId: response1.data.profile.UserId,
            currentRole: response1.data.profile.headline,
            profilepic: response1.data.profile.profilePic,
            url: response1.data.user.profileUrl,
            isOnline: false,
            unSeen: 0,
          };
          activechatusers.push(obj);
        }
      }
      if (response.data.activechats) {
        for (const user of response.data.activechats) {
          activechatusers.push(user);
        }
        setCurrentUser(response.data.currentUserId);
        setCurrentUserProfilePic(response.data.currentUserProfilePic);
      }
      setUsers(activechatusers);
      if (location.pathname === "/chats") {
        setusersload(false);
      }
      let notifications = 0;
      if (selectedUser) {
        activechatusers.forEach((user) => {
          if (user.UserId !== selectedUser.UserId) {
            notifications += user.unSeen || 0; // Add unSeen value or 0 if undefined
          }
        });
      } else {
        activechatusers.forEach((user) => {
          notifications += user.unSeen;
        });
      }
      setNumberOfNotifications(notifications);
    } catch (error) {
      // console.error("Failed to fetch users:", error);
    }
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchUsers();
    setLoading(false);
  }, [chaturl]);

  useEffect(() => {
    setLoading(true);
    if (socket) {
      socket.on("message_reply", (newMessage) => {
        // console,log("working");
        fetchUsers();
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.UserId === newMessage.sender
              ? { ...user, unSeen: user.unSeen + 1 }
              : user
          )
        );
      });
    }
    setLoading(false);
  }, [socket]);

  const [activeChats, setActiveChats] = useState([]);

  function encrypt(string1, string2, shift) {
    // Use a unique delimiter that won't appear in the original strings
    const delimiter = "|";

    // Concatenate the two strings with the delimiter
    let concatenatedString = string1 + delimiter + string2;

    // Encrypt the concatenated string with a shift cipher
    let encryptedString = "";

    for (let i = 0; i < concatenatedString.length; i++) {
      let charCode = concatenatedString.charCodeAt(i);

      // Shift the character code
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 65 + shift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 97 + shift) % 26) + 97
        );
      } else {
        encryptedString += concatenatedString[i]; // Non-alphabetical characters
      }
    }

    return encryptedString;
  }

  const scrollref = useRef(null);

  useEffect(() => {
    if (scrollref.current) {
      scrollref.current.scrollTo(0, scrollref.current.scrollHeight);
    }
  }, [messages, selectedUser]);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  scrollref.current?.addEventListener("scroll", () => {
    if (
      scrollref.current.scrollTop + scrollref.current.clientHeight + 2 >=
      scrollref.current.scrollHeight
    ) {
      setScrolledToBottom(true);
    } else {
      setScrolledToBottom(false);
    }
  });

  const selectUser = async (user) => {
    user.unSeen = 0;
    setSelectedUser(user);
    changeUser(user);
    const chaturl = encrypt(user.UserId, currentUser, 7);
    //// // console,log(user);
    navigate(`${chaturl}`);
    // // // console,log(apiUrl.chats.get + user.UserId);
    // setSelectedUser(user);
  };

  // // console,log(selectedUser);

  if (loading) {
    return <Spinnerpage />;
  } else {
    return (
      <div className="flex justify-center min-h-[95vh]">
        <div className="w-full   md:max-w-screen-xl  flex md:flex-col flex-col md:p-4">
          <div className="chat-container flex border-[1px] h-full rounded-lg">
            <div
              className={`users-list  py-4 px-1 border-r ${
                selectedUser
                  ? "md:w-1/4 hidden md:inline-block"
                  : "md:w-1/4 w-[100%]"
              }`}
            >
              <h2 className="text-xl font-[550] mb-4 px-1 ">Chats</h2>

              <ul>
                <hr />
                {usersload ? (
                  <Spinnerpage />
                ) : users.length > 0 ? (
                  users
                    .filter((user) => user.UserId !== currentUser)
                    .map((user) => (
                      <div key={user.UserId}>
                        <li
                          onClick={() => selectUser(user)}
                          className={`cursor-pointer p-2 hover:bg-blue-100 flex justify-between items-center ${
                            selectedUser && selectedUser.UserId === user.UserId
                              ? "bg-blue-100 border-r-[1.5px] border-[#0052ff]"
                              : ""
                          }`}
                        >
                          <div className="flex gap-2 items-center">
                            <img
                              src={user.profilepic}
                              alt="User Profile"
                              className="rounded-sm"
                              width={30}
                              height={30}
                            />
                            <div
                              className={`  ${
                                selectedUser &&
                                selectedUser.UserId === user.UserId
                                  ? ""
                                  : user.unSeen > 0
                                  ? "font-[550]"
                                  : ""
                              }`}
                            >
                              {user.Name}
                            </div>
                          </div>
                          {selectedUser && selectedUser.UserId === user.UserId
                            ? ""
                            : user.unSeen > 0 && (
                                <div className="bg-blue-100 rounded-full p-1 text-xs font-[550]">
                                  {user.unSeen}
                                </div>
                              )}
                        </li>
                        <hr />
                      </div>
                    ))
                ) : (
                  <div className="text-center h-full flex items-center justify-center py-4 ">
                    <div className="font-[550]"> ...Loading</div>
                  </div>
                )}
              </ul>
            </div>

            <Outlet />
          </div>
        </div>
      </div>
    );
  }
};

export default ActiveChats;
