import React from "react";
import { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import apiUrl from "../apiConfig";
import ShowValidations from "./signup/showValidations";
import { logincontext } from "../contexts/Logincontext";

export default function ForgotPasswordForm({  title, email, url }) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch
  } = useForm();
  
  const [viewmailotp, setviewotp] = useState(false);
  const [viewnewpassword, setviewnewpassword] = useState(false);
  const [captcha, setcaptcha] = useState(undefined);

  const [data, setdata] = useState();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    validLength: false,
    containsUpperCase: false,
    containsLowerCase: false,
    containsSpecialChar: false,
    containsNumber: false,
  });

  const validatePassword = (password) => {
    setValidationStatus({
      validLength: password.length >= 8,
      containsUpperCase: /[A-Z]/.test(password),
      containsLowerCase: /[a-z]/.test(password),
      containsSpecialChar: /[^A-Za-z0-9]/.test(password),
      containsNumber: /\d/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    validatePassword(e.target.value);
  };

  const isAllValid = Object.values(validationStatus).every(Boolean);


  const [timer, setTimer] = useState(60);
  const [disabled, setDisabled] = useState(true);
  const [savedMessage, setSavedMessage] = useState("");

  const startTimer = () => {
    setDisabled(true);
    setTimer(60);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setDisabled(false);
    }, 60000);
  };

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
    }
  }, [timer]);

  const handleResendClick = async () => {
    startTimer();
    await ForgotPassword(data);
  };
  const showpass = () => {
    setShowPassword(!showPassword);
  };

  const [loading, setLoading] = useState(false);

  const ForgotPassword = async (userobj) => {

    try {
      const response = await axios.post(
        apiUrl.forgotpassword.sendcode,
        userobj
      );

      if (response.status === 200) {
        setcaptcha(response.data.captcha);
     
        return response.data;
      }
    } catch (err) {
      setError("email", { type: "manual", message: "Email is not registered" });
    }
  };

  const submitform = async (data) => {

    if (!data.email) {
      setError("email", { type: "manual", message: "Email is required" });
      return;
    }

    setLoading(true);
    setdata(data);
    const keys = Object.keys(data);
    // // // console,log(keys.length);
    if (keys.length < 4) {
      if (viewmailotp) {
        // console.log(captcha, data.captcha);
        if (captcha === data.captcha) {
          setviewnewpassword(!viewnewpassword);
          setviewotp(!viewmailotp);
        } else {
          setError("captcha", { type: "manual", message: "Incorrect Captcha. please check again" });

        }
      }
      if (!viewmailotp) {
        const resdata = await ForgotPassword(data);
        if (resdata) {
          startTimer();
          setviewotp(!viewmailotp);
        }
      }
    } else {
      {
        try {
          // console.log(data);
          const response = await axios.post(apiUrl.forgotpassword.forgotpassword, data);
        
          if (response.status === 200) {
            setSavedMessage("Password Changed Successfully");
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          }
        } catch (err) {
          // console.log(err.response.data.error);
          setError("password", {
            type: "manual",
            message: err.response.data.error,
          }); 
        }
      }
    }
    setLoading(false);
  };


  return (
    <div className="">
      <h1 className={`text-center pt-3 text-2xl font-[550] font-sans`}>
        Forgot password
      </h1>
      <h4 className={`py-2 text-sm  text-center `}>
        We'll send a verification code to this email
      </h4>

      <form onSubmit={handleSubmit(submitform)} className="p-4">
        <input
          type="email"
          disabled={viewmailotp || viewnewpassword}
          placeholder="Email"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2 ${
            errors.email ? "border-red-500" : ""
          }`}
          name="Email"
          {...register("email", {
            required: "Email is required",
          })}
        />
        {errors.email && (
          <p className="text-red-500 text-sm w-full text-left">
            {errors.email.message}
          </p>
        )}
        {viewmailotp && (
          <div>
            <input
              type="text"
              placeholder="Enter Captcha Here"
              className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2 ${
                errors.captcha ? "border-red-500" : ""
              }`}
              name="Captcha"
              {...register("captcha", { required: "Captcha is required" })}
            />
            {errors.captcha && (
              <p className="text-red-500 text-sm w-full text-left">
                {errors.captcha.message}
              </p>
            )}
          </div>
        )}
        {viewnewpassword && (
          <div>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                errors.password ? "border-red-500" : ""
              }`}
              {...register("password", {
                required: "Password is required",
                onChange: handlePasswordChange,
                validate: {
                  minLength: (value) =>
                    value.length >= 8 ||
                    "Password must be at least 8 characters",
                  upperCase: (value) =>
                    /[A-Z]/.test(value) ||
                    "Password must include at least one uppercase letter",
                  lowerCase: (value) =>
                    /[a-z]/.test(value) ||
                    "Password must include at least one lowercase letter",
                  specialChar: (value) =>
                    /[^A-Za-z0-9]/.test(value) ||
                    "Password must include at least one special character",
                  number: (value) =>
                    /\d/.test(value) ||
                    "Password must include at least one number",
                },
              })}
              onFocus={() => !errors.password && setShowRules(true)}
              onBlur={() => !isAllValid && setShowRules(false)}
            />
            {errors.password && (
              <p className="text-red-500 text-sm">{errors.password.message}</p>
            )}

            {ShowValidations({ showRules, validationStatus })}

            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                errors.cpassword ? "border-red-500" : ""
              }`}
              {...register("cpassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === watch("password") || "Passwords do not match",
              })}
            />
            {errors.cpassword && (
              <p className="text-red-500 text-sm">{errors.cpassword.message}</p>
            )}
            <div className="py-2">
              <input
                type="checkbox"
                name="show"
                id="show"
                onChange={showpass}
              />
              <label
                htmlFor="show"
                className="text-sm text-justify hover:cursor-pointer  px-2"
              >
                {" "}
                Show password
              </label>
            </div>
          </div>
        )}
        <div className="mt-2 w-full flex flex-row-reverse justify-between">
          {/* Render spinner if loading, otherwise render login button */}
          {loading ? (
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
          ) : (
            <button
              type="submit"
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0 h-fit"
            >
              {viewnewpassword
                ? "Confirm"
                : viewmailotp
                ? "Verify Captcha"
                : "Send Captcha"}
            </button>
          )}
          {!viewnewpassword && viewmailotp && (
            <div className="w-[60%] flex flex-col gap-1">
              <div>Not received your code? </div>
              <button
                className={`font-[550] text-left  ${
                  disabled ? "text-gray-500" : "text-[#0052ff]"
                }`}
                onClick={handleResendClick}
                disabled={disabled}
              >
                Resend code {disabled ? `(${timer}s)` : ""}
              </button>
            </div>
          )}
        </div>
      </form>
      {savedMessage != "" ? (
        <div className="flex items-center text-green-700 pt-2">
          <TiTick />
          {savedMessage}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
