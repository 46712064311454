import React, { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import DeleteModal from "./DeleteModal";
import { set } from "react-hook-form";

export default function FeedDropdown({ deletepost, currentUserId, user, edit, setedit }) {
  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {deleteModal && (
        <DeleteModal
          onClick={() => setDeleteModal(false)}
          handleDeleteEducation={() => {
            deletepost();
            setDeleteModal(false);
          }}
          inst={"post"}
          name={""}
        />
      )}
      <div>
        <Menu.Button className=" duration-300   font-[550] cursor-pointer  px-3 md:text-sm text-xs  hover:text-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 focus:outline-none"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {
              currentUserId == user &&
            <Menu.Item>
              <div
                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                onClick={() => {setedit(true)}}
              >
                Edit
              </div>
            </Menu.Item>
            }
            {
              currentUserId == user? (
                <Menu.Item>
                  <div
                    className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                    onClick={() => {setDeleteModal(true)}}
                  >
                    Delete
                  </div>
                </Menu.Item>
              ): (
                <Menu.Item>
                  <div
                    className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                    onClick={() => {}}
                  >
                    Report
                  </div>
                </Menu.Item>
              )
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
