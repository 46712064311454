import React from "react";

import { Spinner } from "keep-react";
export default function Spinnerpage({ notfullpage }) {
  return (
    // <div className='w-full h-[90vh] flex justify-center items-center '> <Spinner color="info" size="xl" /></div>
    <div
      className={`w-full  ${
        !notfullpage ? "h-[90vh]" : "h-full"
      } flex justify-center items-center`}
    >
      <div className="flex gap-4 w-20 px-2">
        <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
        <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
        <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
      </div>
    </div>
  );
}
