import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import apiUrl from "../apiConfig";
import { NavLink, useNavigate } from "react-router-dom";
import { logincontext } from "../contexts/Logincontext";

export default function GroupMembers({ memberIds, toggleMenu }) {
  const token = localStorage.getItem("token");
  const [userDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const fetchData = async () => {
    try {
      const response = await axios.post(apiUrl.feed.getusersdetails + token, {
        ids: memberIds,
      });
      // console.log(response);
      setUserDetails(response.data.profiles);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const connectapi = async (url, userId) => {
    try {
      const resp = await axios.post(apiUrl.connections.request + token, {
        url: url,
      });
      //// console.log(resp);
      if (resp && resp.status === 200) {
        const updatedUser = { ...userDetails, connectionStatus: "pending" };
        setUserDetails(updatedUser);
      }
    } catch (err) {
      // // console,log(err.message);
    }
  };

  function encrypt(string1, string2, shift) {
    // Use a unique delimiter that won't appear in the original strings
    const delimiter = "|";

    // Concatenate the two strings with the delimiter
    let concatenatedString = string1 + delimiter + string2;

    // Encrypt the concatenated string with a shift cipher
    let encryptedString = "";

    for (let i = 0; i < concatenatedString.length; i++) {
      let charCode = concatenatedString.charCodeAt(i);

      // Shift the character code
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 65 + shift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 97 + shift) % 26) + 97
        );
      } else {
        encryptedString += concatenatedString[i]; // Non-alphabetical characters
      }
    }

    return encryptedString;
  }

  const handleMessage = async (id) => {
    try {
      const chaturl = encrypt(id, loggedInUser.complete_profile.UserId, 7);
      // console.log(chaturl);
      navigate(`/chats/${chaturl}`);
    } catch (error) {
      // // console,log(error);
    }
  };

  const reject = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.reject + token, {
        url: url,
      });
      if (resp.status === 200) {
        const updatedUser = {
          ...userDetails,
          connectionStatus: "notConnected",
        };
        setUserDetails(updatedUser);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  const accept = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.accept + token, {
        url: url,
      });
      if (resp.status === 200) {
        const updatedUser = { ...userDetails, connectionStatus: "connected" };
        setUserDetails(updatedUser);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  return (
    <div className="lg:w-[60%] w-[100%] flex flex-col gap-4 rounded-md border-[1px] p-2 h-fit ">
      <div className=" text-md font-[550] flex gap-1 items-center cursor-pointer" onClick={toggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
        <div className="font-[550]">
        Group Members
        </div>
      </div>
      <div>
        {userDetails &&
          userDetails.map((user, key) => (
            <div key={key} className="py-2 flex flex-col gap-2">
              <div className="flex flex-row items-start w-full h-full  gap-2 cursor-pointer">
                <img
                  src={user.profilePic}
                  className="lg:w-[15%] md:w-[20%] w-1/4 h-auto aspect-square rounded-md"
                  alt=""
                  onClick={() => navigate("/" + user.url)}
                />
                <div
                  className="w-full flex flex-col gap-0.5 md:gap-1"
                  onClick={() => navigate("/" + user.url)}
                >
                  <div className="text-sm font-[550] group-hover:underline w-full">
                    {user.name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {user.headline ? user.headline : <br />}
                  </div>
                  <div className="flex flex-col gap-1">
                    {user.address.city.trim() !== "" && (
                      <div className="text-sm flex items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="md:size-5 size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                          />
                        </svg>
                        <div className="text-xs text-gray-500">
                          {user.address.city}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {user.connectionStatus === "notConnected" ? (
                  <div
                    className=" text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl "
                    onClick={() => connectapi(user.url)}
                  >
                    <div className="flex gap-1 justify-center items-center">
                      <p>Connect</p>
                    </div>
                  </div>
                ) : user.connectionStatus === "connected" ? (
                  <div className="flex gap-4 items-center">
                    <div
                      className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl "
                      onClick={() => handleMessage(user._id)}
                    >
                      <p>Message</p>
                    </div>

                    {/* <div
                    className=" text-red-500 border-[1.5px] border-red-500 hover:bg-red-100  cursor-pointer font-[550] py-1 md:px-4 px-2 text-sm md:text-left text-center rounded-3xl my-2 md:my-0"
                    onClick={disconnect}
                  >
                  <div className="flex gap-1 justify-center items-center">
                  <p >Remove connection</p>
                  </div>
                  </div> */}
                  </div>
                ) : user.connectionStatus === "requested" ? (
                  <div
                    className=" text-red-500 border-[1.5px] border-red-500 hover:bg-red-100  cursor-pointer font-[550] py-1 md:px-4 px-2 text-sm md:text-left text-center rounded-3xl "
                    onClick={() => reject(user.url)}
                  >
                    <div className="flex gap-1 justify-center items-center">
                      <p>withdraw request</p>
                    </div>
                  </div>
                ) : user.connectionStatus == "self" ? null : (
                  <div className=" flex gap-4">
                    <div
                      className=" text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl "
                      onClick={() => accept(user.url)}
                    >
                      <div className="flex gap-1 justify-center items-center">
                        <p>Accept</p>
                      </div>
                    </div>
                    <div
                      className=" text-red-500 border-[1.5px] border-red-500 hover:bg-red-100  cursor-pointer font-[550] py-1 md:px-4 px-2 text-sm md:text-left text-center rounded-3xl "
                      onClick={() => reject(user.url)}
                    >
                      <div className="flex gap-1 justify-center items-center">
                        <p>Reject</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <hr />
            </div>
          ))}
      </div>
    </div>
  );
}
