import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import apiUrl from "../apiConfig";
import ShowValidations from "./signup/showValidations";

export default function ChangePassword({}) {
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [showRules, setShowRules] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm();

  const token = localStorage.getItem("token");

  const [validationStatus, setValidationStatus] = useState({
    validLength: false,
    containsUpperCase: false,
    containsLowerCase: false,
    containsSpecialChar: false,
    containsNumber: false,
  });

  const validatePassword = (password) => {
    setValidationStatus({
      validLength: password.length >= 8,
      containsUpperCase: /[A-Z]/.test(password),
      containsLowerCase: /[a-z]/.test(password),
      containsSpecialChar: /[^A-Za-z0-9]/.test(password),
      containsNumber: /\d/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    validatePassword(e.target.value);
  };

  const isAllValid = Object.values(validationStatus).every(Boolean);

  const submitform = async (data) => {
    if(data.currentPassword === data.password){
      setError("password", {
        type: "manual",
        message: "New password should be different from current password",
      });
      setError("currentPassword", {
        type: "manual",
        message: "",
      });
      return;
    }

    if (isAllValid) {
      try {
        const response = await axios.post(
          apiUrl.forgotpassword.changepassword + token,
          data
        );
        if (response.status === 200) {
          setMessage("Password Changed Successfully");
        }
      } catch (error) {
        if (error.response.status === 401) {
          setError("currentPassword", {
            type: "manual",
            message: "Please enter correct current password",
          });
        }
      }
    }
  };

  return (
    <div className={` w-full  rounded-xl p-4`}>
      <form onSubmit={handleSubmit(submitform)}>
        <h1 className="text-xl font-[550] font-sans">Change Password</h1>
        <div className="mt-4">
          <label htmlFor="">Current Password</label>
          <input
            type={showPassword ? "text" : "password"}
            className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
              errors.currentPassword ? "border-red-500" : ""
            }`}
            {...register("currentPassword", {
              required: "Please enter your current password",
            })}
          />
          {errors.currentPassword && (
            <p className="text-red-500 text-sm">
              {errors.currentPassword.message}
            </p>
          )}
        </div>
        <div className="mt-4">
          <label htmlFor="">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
              errors.password ? "border-red-500" : ""
            }`}
            {...register("password", {
              required: "Password is required",
              onChange: handlePasswordChange,
              validate: {
                minLength: (value) =>
                  value.length >= 8 || "Password must be at least 8 characters",
                upperCase: (value) =>
                  /[A-Z]/.test(value) ||
                  "Password must include at least one uppercase letter",
                lowerCase: (value) =>
                  /[a-z]/.test(value) ||
                  "Password must include at least one lowercase letter",
                specialChar: (value) =>
                  /[^A-Za-z0-9]/.test(value) ||
                  "Password must include at least one special character",
                number: (value) =>
                  /\d/.test(value) ||
                  "Password must include at least one number",
              },
            })}
            onFocus={() => !errors.password && setShowRules(true)}
            onBlur={() => !isAllValid && setShowRules(false)}
          />
          {errors.password && (
            <p className="text-red-500 text-sm">{errors.password.message}</p>
          )}

          {ShowValidations({ showRules, validationStatus })}
        </div>
        <div className="mt-4">
          <label htmlFor="">Confirm Password</label>
          <input
            type={showPassword ? "text" : "password"}
            className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
              errors.cpassword ? "border-red-500" : ""
            }`}
            {...register("cpassword", {
              required: "Please confirm your password",
              validate: (value) =>
                value === watch("password") || "Passwords do not match",
            })}
          />
          {errors.cpassword && (
            <p className="text-red-500 text-sm">{errors.cpassword.message}</p>
          )}
        </div>

        {message && <p className="text-green-500">{message}</p>}

        <div className="py-1 flex items-center">
          <input
            type="checkbox"
            className="h-fit"
            onChange={() => setShowPassword(!showPassword)}
            name="showPassword"
            id="showPassword"
          />
          <label className="text-sm px-2" htmlFor="showPassword">
            Show password
          </label>
        </div>
        <div className="mt-4">
          <button
            type="submit"
            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
          >
            Save
          </button>
        </div>
      </form>
      <br />
    </div>
  );
}
