export const routes = [
    "/",
    "/signup",
    "/login",
    "/forgotpassword",
    "/accessmanagement",
    "/documents",
    "/admin/:email",
    "/admin/addlogos",
    "/globaladmin",
    "/settings",
    "/signup/phoneno",
    "/signup/resume",
    "/signup/address",
    "/signup/profilepicture",
    "/signup/add-education",
    "/signup/education-review",
    "/signup/add-project",
    "/signup/project-review",
    "/signup/add-experience",
    "/signup/job-review",
    "/signup/add-skills",
    "/signup/add-socialprofiles",
    "/deleteaccount/*",
    "/:url/*",
    "/:url/resume",
    "/jobs",
    "/jobs/:jobId",
    "/chats",
    "/chats/:chaturl",
    "/network",
    "/network/newpeople",
    "/network/invitations",
    "/network/connections",
    "/network/tags",
    "/courses",
    "/courses/dsa",
    "/post/:id",
].map((route) => route.replace(/\/$/, ""));

