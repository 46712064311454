import React from 'react'

export default function ShowValidations({ showRules, validationStatus }) {
  return showRules && (
    <ul className="text-sm px-4">
      <li
        className={
          validationStatus.validLength ? "hidden" : "text-red-500"
        }
      >
        At least 8 characters
      </li>
      <li
        className={
          validationStatus.containsUpperCase ? "hidden" : "text-red-500"
        }
      >
        At least one uppercase letter
      </li>
      <li
        className={
          validationStatus.containsLowerCase ? "hidden" : "text-red-500"
        }
      >
        At least one lowercase letter
      </li>
      <li
        className={
          validationStatus.containsSpecialChar ? "hidden" : "text-red-500"
        }
      >
        At least one special character
      </li>
      <li
        className={
          validationStatus.containsNumber ? "hidden" : "text-red-500"
        }
      >
        At least one number
      </li>
    </ul>
  );
}
