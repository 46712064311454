import React from "react";
import {  Outlet } from "react-router-dom";
export default function Profileincom({}) {
  
  return (
    <div className="flex justify-center items-center p-3 min-h-[90vh] w-full">
       <div className="max-w-screen-xl flex justify-center w-full items-center ">
          <div  className={`flex flex-col w-[100vw] lg:w-[40%] h-full  border-2 rounded-lg shadow-lgshadow-slate-300`}
          > 
           
        <Outlet/>
      </div>
      </div>
    </div>
  );
}
