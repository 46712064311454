import React, { useEffect, useState } from "react";
import apiUrl from "../apiConfig";
import axios from "axios";
import FeedDropdown from "./Feedoption";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FaRegThumbsUp } from "react-icons/fa";
import Spinnerpage from "./Spinnerpage";

export default function Post({ index, post, loggedInUser }) {
  const token = localStorage.getItem("token");
  const [liked, setliked] = useState(false);
  const [updatepost, setupdatepost] = useState(post);
  const [showcomment, setshowcomment] = useState(false);
  const [showAddcomment, setshowAddcomment] = useState(false);
  const [edit, setedit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const Navigate = useNavigate();

  useEffect(() => {
    getpost();
  }, []);
  const [isSaved, setisSaved] = useState(false);
  useEffect(() => {
    if (
      updatepost.savedby?.includes(loggedInUser?.complete_profile?.user?._id)
    ) {
      setisSaved(true);
    } else {
      setisSaved(false);
    }
  }, [updatepost]);

  const {
    register,
    handleSubmit: handlePostSubmit,
    formState: errors,
    reset,
  } = useForm();
  const {
    register: commentRegister,
    handleSubmit: handleCommentSubmit,
    formState: commentErrors,
    reset: resetComment,
  } = useForm();
  const {
    register: editcommentRegister,
    handleSubmit: handleeditCommentSubmit,
    formState: editcommentErrors,
    reset: reseteditComment,
  } = useForm();

  const [showeditcomment, setshoweditcomment] = useState(() => {
    return new Array(updatepost.comments?.length).fill(false);
  });

  const [hidepdf, sethidepdf] = useState(true);

  const getpost = async () => {
    try {
      const response = await axios.post(
        apiUrl.feed.getpost + token + "/" + post.shareableId
      );
      if (response.status === 200) {
        setupdatepost(response.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (post.likes?.includes(loggedInUser?.complete_profile?.user?._id)) {
      setliked(true);
    }
  }, [loggedInUser]);

  const [comment, setcomment] = useState("");
  const timediff = (uploadedDate) => {
    const date = new Date(uploadedDate);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffSeconds = Math.round(diff / 1000);
    const diffMinutes = Math.round(diff / 60000);
    const diffHours = Math.round(diff / 3600000);
    const diffDays = Math.round(diff / 86400000);
    const diffWeeks = Math.round(diff / 604800000);

    if (diffSeconds < 10) {
      return `now`;
    } else if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hours ago`;
    }

    if (diffDays < 7) {
      return `${diffDays} days ago`;
    }

    return `${diffWeeks} weeks ago`;
  };

  const toggleLike = async () => {
    try {
      const response = await axios.post(
        apiUrl.feed.togglelike + token + "/" + post.shareableId
      );
      if (response.status === 200) {
        setliked(!liked);
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const updatePost = async (data) => {
    try {
      const response = await axios.post(
        apiUrl.feed.updatepost + token + "/" + post.shareableId,
        {
          content: data.content,
        }
      );
      // console.log(response);
      if (response.status === 200) {
        setedit(false);
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const addcomment = async (comment) => {
    try {
      const response = await axios.post(
        apiUrl.feed.addcomment + token + "/" + post.shareableId,
        {
          comment: comment.comment,
        }
      );
      if (response.status === 200) {
        resetComment();
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const editcomment = async (comment) => {
    try {
      // console.log(comment);
      const response = await axios.put(
        apiUrl.feed.editcomment +
          token +
          "/" +
          post.shareableId +
          "/" +
          comment.commentId,
        {
          comment: comment.comment,
        }
      );
      if (response.status === 200) {
        setshoweditcomment((prev) => {
          const newstate = [...prev];
          newstate[comment.index] = !prev[comment.index];
          return newstate;
        });
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const deletecomment = async (commentId) => {
    try {
      const response = await axios.delete(
        apiUrl.feed.deletecomment +
          token +
          "/" +
          post.shareableId +
          "/" +
          commentId
      );
      if (response.status === 200) {
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const addreaction = async (commentId) => {
    try {
      const response = await axios.post(
        apiUrl.feed.addreaction +
          token +
          "/" +
          post.shareableId +
          "/" +
          commentId
      );
      if (response.status === 200) {
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const deletepost = async () => {
    try {
      const response = await axios.delete(
        apiUrl.feed.deletepost + token + "/" + post.shareableId
      );
      if (response.status === 200) {
        setupdatepost("");
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };
  const [copysuccess, setcopysuccess] = useState(false);
  const copyLink = () => {
    const url = process.env.REACT_APP_DOMAIN + "post/" + post.shareableId;
    navigator.clipboard.writeText(url);
    setcopysuccess(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setcopysuccess(false);
    }, 2000);
  }, [copysuccess]);

  if (post && !updatepost) {
    return null;
  }

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const classof = () => {
    const school = post.school;
    const schoolObj =
      loggedInUser?.complete_profile?.profile?.professionalDetails?.education?.find(
        (edu) => edu.school === school
      );
    return `class of ${schoolObj?.to?.split("-")[1]}` || "Not from this school";
  };

  const savePost = async () => {
    try {
      const response = await axios.post(
        apiUrl.feed.savepost + token + "/" + post.shareableId
      );
      if (response.status === 200) {
        // console.log(response.data);
        getpost();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div className="bg-white rounded-md border-[1px]">
      <div className=" flex flex-row items-center p-2 gap-2 ">
        <img
          src={post.authorDetails?.profilePic}
          alt=""
          className="w-[40px] h-[40px] rounded-md  cursor-pointer"
          onClick={() => {
            Navigate("/" + post.authorDetails?.url);
          }}
        />
        <div className="flex flex-col w-full h-full  justify-center">
          <div className=" flex justify-between items-end w-full ">
            <div
              className=" md:text-lg text-base font-[550] cursor-pointer"
              onClick={() => {
                Navigate("/" + post.authorDetails?.url);
              }}
            >
              {post.authorDetails?.name}
            </div>
              <FeedDropdown
                deletepost={deletepost}
                currentUserId={loggedInUser.complete_profile?.user?._id}
                user={post.author}
                edit={edit}
                setedit={setedit}
              />
          </div>

          <div className="flex items-center text-xs text-gray-500 whitespace-nowrap overflow-hidden ">
            {/* {post.authorDetails?.headline.trim() !== "" && (
              <span className="flex-shrink-0">{classof()} ·</span>
            )} */}

            <span className="flex-shrink-0 pl-1">
              {timediff(post.createdAt)}
            </span>
          </div>
        </div>
      </div>

      <div className="">
        <div className="pb-2">
          {edit ? (
            <form
              action=""
              onSubmit={handlePostSubmit(updatePost)}
              className="flex flex-col gap-2 w-full items-end px-2 py-2"
            >
              <textarea
                type="text"
                defaultValue={updatepost.content}
                className="w-full border-[1px] rounded-md p-4 resize-none overflow-hidden"
                {...register("content", { required: true })}
                style={{ height: "auto" }}
                onInput={(e) => {
                  e.target.style.height = "auto"; // Reset height to recalculate
                  e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
                }}
              />

              <button
                className="bg-[#0052ff] text-white w-fit  hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                type="submit"
              >
                Post
              </button>
            </form>
          ) : (
            <div className=" text-sm px-2 py-1 ">
              {updatepost && updatepost.content}
            </div>
          )}

          <div className=" flex justify-center">
            {post.media?.length > 0 && post.media[0].type == "image" && (
              <img
                src={post.media[0].url}
                alt=""
                className="w-full aspect-video object-cover"
              />
            )}

            {post.media?.length > 0 && post.media[0].type === "document" && (
              <div className="w-full">
                <div className="w-full p-2">
                  <div className=" w-full bg-slate-100 p-2 font-[550] capitalize rounded-md text-left flex justify-between items-center">
                    {post.media[0].url.split("/").pop()}

                    <div className="flex gap-2">
                      <div
                        onClick={() => sethidepdf(!hidepdf)}
                        className="cursor-pointer"
                      >
                        {hidepdf ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        )}
                      </div>
                      <div
                        onClick={() => window.open(post.media[0].url)}
                        className="cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                {!hidepdf && (
                  <div className="pdf-viewer-container w-full flex justify-center h-[600px]">
                    <>
                      {isLoading && <Spinnerpage />}
                      <iframe
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                          post.media[0].url
                        )}&embedded=true`}
                        width="90%"
                        height=""
                        title="PDF Preview"
                        onLoad={handleIframeLoad}
                        style={{ display: isLoading ? "none" : "block" }}
                      ></iframe>
                    </>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <br /> */}
        <hr />
        <div className=" flex flex-row items-center justify-between gap-2 px-4 py-4 text-gray-500">
          <div className=" flex gap-2">
            <div
              className="flex gap-1 border-r-[1px] pr-2 text-sm items-center cursor-pointer"
              onClick={toggleLike}
            >
              {liked ? (
                <FaRegThumbsUp className="text-[#0052ff] size-5" />
              ) : (
                <FaRegThumbsUp className="size-5" />
              )}
              <div className={liked ? "text-[#0052ff]" : "text-gray-600"}>
                {updatepost.likes?.length > 0 && updatepost.likes?.length}
              </div>
            </div>
            <div
              className="flex gap-1  border-r-[1px] pr-2 items-center cursor-pointer"
              onClick={() => setshowAddcomment(!showAddcomment)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={showAddcomment ? "currentColor" : "none"}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
                />
              </svg>
              <div className="text-sm">
                {updatepost.comments?.length > 0 && updatepost.comments?.length}
              </div>
            </div>
            {/* <div
              className="flex gap-1  border-r-[1px] pr-2 items-center cursor-pointer"
              onClick={savePost}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={isSaved ? "currentColor" : "none"}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                />
              </svg>
              <div className="text-sm">{isSaved ? "Saved" : "Save"}</div>
            </div> */}
          </div>

          <div onClick={copyLink} className="cursor-pointer flex items-center">
            {copysuccess ? (
              <div className="text-sm text-green-500 animate-fade-in-out">
                Link Copied
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5 transition-transform duration-300 ease-in-out hover:scale-110"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                />
              </svg>
            )}
          </div>
        </div>

        {showAddcomment && (
          <div className=" border-t-[1px] ">
            <div className=" flex flex-row items-center px-2 py-2 gap-2 w-full">
              <img
                src={loggedInUser?.complete_profile?.profile?.profilePic}
                alt=""
                className="w-[40px] h-[40px] rounded-md ml-2 my-2"
              />
              <form
                className="flex flex-row items-center pr-2 py-2 gap-2 w-full"
                onSubmit={handleCommentSubmit(addcomment)}
              >
                <input
                  type="text"
                  placeholder="Add a comment..."
                  className=" rounded-3xl px-4 bg-gray-100 w-[90%] h-[40px] focus:outline-none"
                  {...commentRegister("comment", { required: true })}
                />
                <button
                  className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                  type="submit"
                >
                  Post
                </button>
              </form>
            </div>

            {updatepost?.comments &&
              updatepost.comments
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, !showcomment ? 2 : updatepost.comments.length)
                .map((comment, index) => (
                  <div className="flex items-start p-2" key={index}>
                    <img
                      src={comment.authorDetails?.profilePic}
                      alt=""
                      className="w-[25px] h-[25px] rounded-md m-2"
                    />
                    <div className=" w-full">
                      <div className="flex items-end gap-2 ">
                        <div className=" text-sm font-[550]">
                          {comment.authorDetails?.name}
                        </div>

                        <div className=" text-xs text-gray-600">
                          {timediff(comment.createdAt)}
                        </div>
                      </div>
                      {showeditcomment[index] ? (
                        <form
                          action=""
                          onSubmit={handleeditCommentSubmit((data) =>
                            editcomment({ ...data, index })
                          )}
                          className="flex flex-col gap-2 w-full items-end px-2 py-2"
                        >
                          <textarea
                            type="text"
                            defaultValue={comment.content}
                            className="w-full border-[1px] rounded-md p-1 resize-none overflow-hidden text-xs"
                            {...editcommentRegister("comment", {
                              required: true,
                            })}
                            style={{ height: "auto" }}
                            onInput={(e) => {
                              e.target.style.height = "auto"; // Reset height to recalculate
                              e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
                            }}
                          />
                          <input
                            type="text"
                            {...editcommentRegister("commentId", {
                              value: comment._id,
                            })}
                            hidden
                          />
                          <div className=" flex gap-2 items-center">
                            <button
                              className="bg-gray-600 text-white w-fit  hover:bg-gray-600  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0 text-xs"
                              onClick={() =>
                                setshoweditcomment((prev) => {
                                  const newstate = [...prev];
                                  newstate[index] = !prev[index];
                                  return newstate;
                                })
                              }
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-[#0052ff] text-white w-fit  hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0 text-xs"
                              type="submit"
                            >
                              Post
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div className=" text-xs  ">{comment.content}</div>
                      )}
                      {!showeditcomment[index] && (
                        <div className="flex gap-1 items-center cursor-pointer">
                          <div
                            className={` text-xs  pt-1 ${
                              comment.reaction.includes(
                                loggedInUser.complete_profile?.user?._id
                              )
                                ? "font-[550] text-[#0052ff]"
                                : "text-gray-600"
                            }`}
                            onClick={() => {
                              addreaction(comment._id);
                            }}
                          >
                            {comment.reaction.length != 0 &&
                              comment.reaction.length}{" "}
                            {comment.reaction.includes(
                              loggedInUser.complete_profile?.user?._id
                            )
                              ? "Liked"
                              : "Like"}
                          </div>

                          {comment.authorDetails._id ===
                            loggedInUser.complete_profile?.user?._id && (
                            <div
                              className=" text-xs text-gray-600 pt-1 cursor-pointer"
                              onClick={() => {
                                setshoweditcomment((prev) => {
                                  const newstate = [...prev];
                                  newstate[index] = !prev[index];
                                  return newstate;
                                });
                                reseteditComment();
                              }}
                            >
                              · Edit
                            </div>
                          )}
                          {comment.authorDetails._id ===
                            loggedInUser.complete_profile?.user?._id && (
                            <div
                              className=" text-xs text-gray-600 pt-1 cursor-pointer"
                              onClick={() => deletecomment(comment._id)}
                            >
                              · Delete
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            {updatepost.comments?.length > 2 && (
              <div className=" flex justify-center items-center">
                <div
                  className="text-sm text-gray-600 cursor-pointer pb-2"
                  onClick={() => setshowcomment(!showcomment)}
                >
                  {!showcomment &&
                    `View all ${updatepost.comments?.length} comments`}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
