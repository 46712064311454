import React, { useState, useEffect,useContext } from "react";
import { GoPlus } from "react-icons/go";
import Awards from "../Awards";
import apiUrl from "../../apiConfig";
import axios from "axios";
import DefaultCompany from "../../images/DefaultAwards.png";
import { logincontext } from "../../contexts/Logincontext";

import { IoIosArrowUp } from "react-icons/io";
export default function AwardsComponent({
  publicview,
  handleAwardsModal,
  dash,
  api,
  setapi,
  visibleItemsAwards,
  showallAwards,
  setshowallAwards,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
  }, []);

  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;

    experience.forEach((item) => {
      const role = {
        Title: item.Title,
        issuemonth: item.issuedDate ? item.issuedDate.split("-")[0] : " ",
        issueyear: item.issuedDate ? item.issuedDate.split("-")[1] : " ",
        Description: item.Description,
        link: item.link,
        _id: item._id,
      };
      if (lastCompany && lastCompany.Issuer === item.Issuer) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          Issuer: item.Issuer,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });

    return result;
  };

  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const sortAwardsByFrom = (experience) => {
    return experience.sort((a, b) => {
      if (!a.issuedDate) return 1;
      if (!b.issuedDate) return -1;
      const [monthA, yearA] = a.issuedDate.split("-");
      const [monthB, yearB] = b.issuedDate.split("-");
      const dateA = new Date(yearA, monthMap[monthA]);
      const dateB = new Date(yearB, monthMap[monthB]);
      return dateB - dateA; // Sorting in decreasing order
    });
  };

  const sortedAward = sortAwardsByFrom(
    dash?.complete_profile?.profile?.professionalDetails?.achievements
  );

  const transformedExperience = transformExperienceData(sortedAward);
  const visiblejobitem = showallAwards
    ? transformedExperience
    : transformedExperience.slice(0, 2);
  return (
    <div
      className={`w-full border-[1px] px-4 py-3 md:pt-5 md:px-6 md:rounded-md  bg-white text-black`}
    >
      <div className="text-lg font-[550] flex justify-between ">
        Achievements{" "}
        {dash?.isloggedinuser && (
          <div className="flex gap-2">
            {" "}
            <div
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
              onClick={() => handleAwardsModal(null)}
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      <div>
        {visiblejobitem.length != 0 &&
          visiblejobitem.map((item, key) => {
            const company = companies.find((obj) => obj.Name === item.Issuer);
            // Get the image link if the university is found
            const image = company ? company.s3Link : DefaultCompany;
            return (
              <Awards
                key={key}
                props={{
                  token: token,
                  item: item,
                  loggedInUser: loggedInUser,
                  changeloggedInUser: changeloggedInUser,
                  image: image,
                  publicview: !dash?.isloggedinuser,
                  api: api,
                  setapi: setapi,
                  length: transformedExperience.length,
                  key: key,
                }}
              />
            );
          })}
      </div>
      {transformedExperience.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center border-slate-300">
          <button
            className="border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setshowallAwards(!showallAwards)}
          >
            {showallAwards
              ? "Show Less"
              : `Show All ${transformedExperience.length}`}{" "}
            <IoIosArrowUp
              className={showallAwards ? "rotate-0" : "rotate-180"}
            />
          </button>
        </div>
      )}
    </div>
  );
}
