import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import axios from "axios";
import apiUrl from "../apiConfig";
import Spinnerpage from "./Spinnerpage";
export default function JobReview({  }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [jobDetails, setJobDetails] = useState([]);

  const editJob = (index) => {
    navigate(`/signup/add-skills/`);
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const geteducation = async () => {
      try {
        setLoading(true);
        const response = await axios.post(apiUrl.profile.getprofile + token);
        if (response.status === 200) {
          // // // console,log(response.data.existing_profile.projects);
          setJobDetails(response.data.complete_profile.profile.professionalDetails.experience);
        }
      } catch (err) {
        // console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const postProfileStatus = async () => {
      try {
        const reqbody = {
          profilestatus: "/signup/job-review",
        };
        const response = await axios.post(
          apiUrl.profile.editprofile + `${token}`,
          reqbody
        );
        if (response.status === 200) {
        }
      } catch (error) {
        //// console.log(error);
      }
    };

    postProfileStatus();

    geteducation();
  }, []);

  const deleteJob = (index) => {
    const updatedJobData = jobDetails.filter((_, i) => i !== index);
    setJobDetails(updatedJobData);
  };

  const submitform = async (data) => {
    const reqbody = {
      experience: jobDetails,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      //// // // console,log(response)
      if (response.status === 200) {
        // //// // // console,log(response.body);
        navigate(`/signup/add-skills`);
      }
    } catch (err) {
      //// // // console,log(err);
    }
    window.scroll(0, 0);
  };

  return (
    <div>
      <div className="h-1 w-full flex">
        <div className="h-full bg-[#0052ff] w-[75%]"></div>
        <div className="h-full  "></div>
      </div>
      <div className="m-2 p-2">
        <h2 className="py-3 text-2xl font-[550] font-sans text-center">
          Job Review
        </h2>
        <br />
        <ul>
          {loading ? (
            <Spinnerpage notfullpage={true} />
          ) : jobDetails.length !== 0 ? (
            jobDetails.map((job, index) => (
              <li
                key={index}
                className="p-4 m-2 rounded-md flex flex-wrap justify-between "
              >
                <div className="text-md">
                  <div className=" flex flex-col gap-0">
                    <h1 className=" font-[550]">{job.jobTitle}</h1>
                    <p className="text-sm">{job.company} </p>
                    <p className="text-sm text-gray-400">
                      {job.from} -{" "}
                      {!job.to ? (
                        <span>Present</span>
                      ) : (
                        <span>
                          {job.to}
                        </span>
                      )}
                    </p>
                    <p>{job.description}</p>
                  </div>
                </div>
                <div className="gap-3 flex">
                  {/* <button onClick={() => editJob(index)}>
                  <AiFillEdit size={25} />
                </button> */}
                  <button onClick={() => deleteJob(index)}>
                    <MdDeleteForever color="red" size={25} />
                  </button>
                </div>
              </li>
            ))
          ) : (
            <p className="text-center">No Job Details Added</p>
          )}
        </ul>
        <br />
        <br />
        <div className="flex justify-between">
          <button
            className="text-[#0052ff] hover:text-[#014cec] font-[550] flex justify-center items-center gap-1"
            onClick={() => navigate(`/signup/add-experience/`)}
          >
            <IoIosAddCircleOutline size={20} /> Add Job
          </button>
          <button
            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl "
            onClick={submitform}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
