const host = process.env.REACT_APP_Backend_Host_Link;
const websockethost = process.env.REACT_APP_Backend_Host_Link;
const apiUrl = {
  login: host + "api/users/userslogin",
  senddeleteaccountmessage: host + "api/users/senddeleteaccountmessage/",
  sendeleteaccountconfirmationmessage:
    host + "api/users/senddeleteaccountconfirmationmessage/",
  deleteaccount: host + "api/users/deleteaccount",
  getuserdetails: host + "api/users/getuserdetails/",
  edituserdetails: host + "api/users/edituserdetails/",
  usersignup: host + "api/users/userssignup",
  adminsignup: host + "api/admin/adminsignup",
  verifyotp: host + "api/users/verifyOTP",
  checktoken: host + "api/chats/checktoken/",
  getcountry: host + "api/users/getcountry",
  profile: {
    sendsms: host + "api/users/editphone/",
    addresume: host + "api/profile/addresume/",
    getresume: host + "api/profile/getresume/",
    addimage: host + "api/profile/addimage/",
    getimage: host + "api/profile/getimage/",
    getcovimage: host + "api/profile/getcovimage/",
    getprofile: host + "api/profile/getprofile/",
    editprofile: host + "api/profile/editprofile/",
    edituserSchema: host + "api/profile/edituserSchema/",
    editmultipleinprofile: host + "api/profile/editmultipleinprofile/",
    deleteprofile: host + "api/profile/deleteprofile/",
    addexp: host + "api/profile/addeduexp/",
    editexp: host + "api/profile/editprojeduexp/",
    getprofilefromid: host + "api/profile/getprofilefromid/",
    geturlanddp: host + "api/profile/geturlanddp/",
    getrandomprofiles: host + "api/profile/getrandomprofiles/",
    getprofilesectionsstatus: host + "api/profile/getprofilesectionsstatus/",
    getprofiletileinformation: host + "api/profile/getprofiletileinformation/",
  },
  forgotpassword: {
    sendcode: host + "api/users/reqotp/",
    forgotpassword: host + "api/users/forgotpassword/",
    changepassword: host + "api/users/changepassword/",
  },
  documents: {
    adddocument: host + "api/documents/adddocument/",
    deletedocument: host + "api/profile/deletedocument/",
    deletesection: host + "api/profile/deletesection/",
    getdocuments: host + "api/documents/getdocuments/",
    ordersection: host + "api/documents/updatesections/",
    addsection: host + "api/profile/addsection/",
    editsection: host + "api/profile/editsection/",
    createresume: host + "api/documents/createresume/",
  },
  url: {
    geturl: host + "api/users/geturl/",
    seturl: host + "api/users/seturl/",
    checkurl: host + "api/users/checkurl/",
    sendusermessage: host + "api/users/sendusermessage/",
    geturlusingid: host + "api/users/geturlusingid/",
  },
  universities: {
    getuniversities: host + "api/universities/getuniversities",
    adduniversities: host + "api/universities/adduniversities",
  },
  companies: {
    getcompanies: host + "api/companies/getcompanies",
    addcompanies: host + "api/companies/addcompanies",
  },
  admin: {
    getallusersdata: host + "api/admin/getallusersdata",
    addlogo: host + "api/admin/addlogo",
  },
  connections: {
    request: host + "api/profile/requestconnection/",
    accept: host + "api/profile/acceptconnection/",
    reject: host + "api/profile/rejectconnection/",
    disconnect: host + "api/profile/disconnectconnection/",
    getdetails: host + "api/connections/getdetails/",
    getrequests: host + "api/profile/getrequests/",
    getnewreqs: host + "api/connections/getnewreqs/",
    getnewpeople: host + "api/connections/getnewpeople/",
    getsearchedconnections: host + "api/profile/getsearchedconnections/",
    getconnections: host + "api/profile/getconnections/",
    withdrawrequest: host + "api/profile/withdrawrequest/",
  },
  jobposting: {
    edit: host + "api/jobposting/edit/",
    getjobs: host + "api/jobposting/getjobs",
    getjob: host + "api/jobposting/getjobbyid/",
    getjobsperuser: host + "api/jobposting/getjobsperuser/",
  },
  location: {
    getcountry: host + "api/location/getcountry",
  },
  chats: {
    get: host + "api/chats/get/",
    getactivechats: host + "api/chats/getactivechats/",
    getnewchats: host + "api/chats/getnewchats/",
    makeunseenzero: host + "api/chats/makeunseenzero/",
    deletechat: host + "api/chats/deletechat/",
    editmessage: host + "api/chats/editmessage/",
    deletemessage: host + "api/chats/deletemessage/",
  },
  websocket: {
    connection: websockethost,
  },
  filters: {
    getFilteredProfiles: host + "api/filters/getfilteredprofiles/",
  },
  jobapplications: {
    addapplication: host + "api/jobapplications/addapplication/",
    getapplications: host + "api/jobapplications/getapplications/",
    getappliedjobs: host + "api/jobapplications/getappliedjobs/",
    withdrawapplication: host + "api/jobapplications/withdrawapplication/",
  },
  employer: {
    signupemployeruser: host + "api/employerusers/signupemployeruser",
    loginemployeruser: host + "api/employerusers/loginemployeruser",
    getemployercompany: host + "api/employerusers/getemployerusercompany/",
  },
  publicinterest: {
    updatepublicinterest: host + "api/publicinterest/updatepublicinterest/",
    checkfeaturepublicinterest:
      host + "api/publicinterest/checkfeaturelinkedwithuser/",
  },
  waitlist: {
    addwaitlist: host + "api/waitlist/addwaitlist/",
    getwaitlist: host + "api/waitlist/getwaitlist/",
  },
  addorg: {
    addorganization: host + "api/org/addorganization/",
  },
  tags: {
    gettags: host + "api/tags/gettags/",
    addtag: host + "api/profile/addtag/",
    addusertotag: host + "api/profile/addusertotag/",
    deletetag: host + "api/profile/deletetag/",
    untaguser: host + "api/profile/untaguser/",
    addnewtag: host + "api/profile/addnewtag/",
    edittag: host + "api/tags/edittag/",
  },
  lists: {
    getlists: host + "api/lists/getlists/",
    addlist: host + "api/lists/addlist/",
    addusertolist: host + "api/lists/addusertolist/",
    deletelist: host + "api/lists/deletelist/",
    untaguser: host + "api/lists/unlistuser/",
    addnewlist: host + "api/lists/addnewlist/",
    editlist: host + "api/lists/editlist/",
  },
  views: {
    addviewcount: host + "api/analytics/addviewcount/",
    getviewcount: host + "api/analytics/getviewcount/",
  },
  globaladmin: {
    getalladmins: host + "api/globaladmin/getalladmins",
    deleteadmin: host + "api/globaladmin/deleteadmin",
    changeadminpassword: host + "api/globaladmin/changeadminpassword",
    addnewadmin: host + "api/globaladmin/addnewadmin",
    getallaccesscodes: host + "api/globaladmin/getallaccesscodes",
    deleteaccesscode: host + "api/globaladmin/deleteaccesscode",
  },
  feed: {
    // write
    createpost: host + "api/feed/createpost/",
    createpostmedia: host + "api/feed/createpostmedia/",
    updatepost: host + "api/feed/updatepost/",
    deletepost: host + "api/feed/deletepost/",

    // read
    getposts: host + "api/feed/getposts/",
    getpost: host + "api/feed/getpost/",

    // likes
    togglelike: host + "api/feed/togglelike/",

    // comments
    addcomment: host + "api/feed/addcomment/",
    editcomment: host + "api/feed/editcomment/",
    deletecomment: host + "api/feed/deletecomment/",
    addreaction: host + "api/feed/addreaction/",

    reportpost: host + "api/feed/reportpost/",

    savepost: host + "api/feed/savepost/",

    getsections: host + "api/feed/getsections/",
    getallsections : host + "api/feed/getallsections/",
    addsection: host + "api/feed/createsection",
    addgroup : host + "api/feed/creategroup",
    removegroupmember : host + "api/feed/removegroupmember",
    getrequests : host + "api/feed/getrequests",
    rejectgrouprequest : host + "api/feed/rejectgrouprequest",
    acceptgrouprequest : host + "api/feed/joingroup",
    requesttogroup : host + "api/feed/requesttogroup/",

    getschooldetails: host + "api/feed/getschooldetails/",
    getusersdetails: host + "api/feed/getusersdetails/",
  },
};

export default apiUrl;
