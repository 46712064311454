import React, { useEffect, useState, useContext, useParams } from "react";
import axios from "axios";
import EditProfileModel from "../components/EditProfileModal";
import EditEduModel from "../components/AddEduModal";
import EditExpModel from "../components/AddExpModal";
import EditSkillsModal from "../components/AddSkillsModal";
import EditProjectModal from "../components/AddProjectModal";
import createEmptyProfile from "../asserts/ProfileModel";
import "../App.css";
import PersonalDetails from "../components/DashBoardComponents/PersonalDetails";
import ProjectsComponent from "../components/DashBoardComponents/ProjectsComponent";
import SkillsComponent from "../components/DashBoardComponents/SkillsComponent";
import ExperienceComponent from "../components/DashBoardComponents/ExperienceComponent";
import EducationComponent from "../components/DashBoardComponents/EducationComponent";
import apiUrl from "../apiConfig";
import { useNavigate } from "react-router-dom";
import URLComponent from "../components/URLcomponent";
import AddSectionModal from "../components/AddSectionDashModal";
import { useLocation } from "react-router-dom";
import SummaryComponent from "../components/DashBoardComponents/SummaryComponent";
import Spinnerpage from "../components/Spinnerpage";
import EditSummaryModal from "../components/EditSummaryModal";
import CertificationComponent from "../components/DashBoardComponents/CertificationComponent";
import AwardsComponent from "../components/DashBoardComponents/AwardsComponent";
import AddCertificationModal from "../components/AddCertificationModal";
import AddAawardsModal from "../components/AddAawardsModal";
import VolExpcomponent from "../components/VolExpcomponent";
import AddVolExpModal from "../components/AddVolExpModal";
import AnalyticsComponent from "../components/AnalyticsComponent";

import { logincontext } from "../contexts/Logincontext";

const Layout = ({
  curradd,
  setcuradd,
  url,
  seturl,
  api,
  setapi,
  curraddsubmit,
}) => {
  const [publicview, setpublicview] = useState(false);
  const [vieweditprofile, setvieweditprofile] = useState(false);
  const [showeditexperience, setshoweditexperience] = useState(false);
  const [showediteducation, setshowediteducation] = useState(false);
  const [showskills, setshowskills] = useState(false);
  const [dash, setdash] = useState(createEmptyProfile);
  const [showAllJobs, setShowAllJobs] = useState(false);
  const [showAllVolExp, setShowAllVolExp] = useState(false);
  const [showAllEducation, setShowAllEducation] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showprojectmodal, setshowprojectmodal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [profilepicmodal, setprofilepicmodal] = useState(false);
  const [coverpicmodal, setcoverpicmodal] = useState(false);
  const [image, setimage] = useState(createEmptyProfile.Profilepic);
  const [cov, setcov] = useState(createEmptyProfile.Coverpic);
  const [collegeprofiles, setCollegeProfiles] = useState([]);

  const [openCompModal, setopenCompModal] = useState(false);

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const manageCompModal = () => {
    setopenCompModal(!openCompModal);
  };
  const urlsubmit = async (data) => {
    // const da = {
    //   url: data,
    // };
    // try {
    //   const response = await axios.post(apiUrl.url.seturl + token, da);
    //   // // // console,log(response);

    //   seturl(response.data.details.url);
    //   setapi(!api);
    //   navigate("/" + response.data.details.url);
    // } catch (err) {
    //   // // // console,log(err.response.data.message);
    // }
    seturl(data);
    navigate("/" + data);
    // // // // console,log(data);
  };

  const [getprofile, setgetprofile] = useState(false);
  const navigate = useNavigate();
  const openModal = () => setShowModal(!showModal);
  const getMonthNumber = (monthName) => {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };

    return months[monthName] || "Invalid month";
  };

  function sortByFromYearDesc(educationArray) {
  educationArray.sort((a, b) => {
    const [monthA, yearA] = a.from.split("-");
    const [monthB, yearB] = b.from.split("-");

    // Compare years first
    if (Number(yearB.trim()) === Number(yearA.trim())) {
      // If years are equal, compare months
      return getMonthNumber(monthB.trim()) - getMonthNumber(monthA.trim());
    }

    return Number(yearB.trim()) - Number(yearA.trim());
  });

  return educationArray;
}


  function sortbyyearcert(educationArray) {
    educationArray.sort(function (a, b) {
      if (Number(b.issueyear) === Number(a.issueyear)) {
        return (
          Number(getMonthNumber(b.issuemonth)) -
          Number(getMonthNumber(a.issuemonth))
        );
      }
      return Number(b.issueyear) - Number(a.issueyear);
    });
    return educationArray;
  }

  const [showsummarymodal, setshowsummarymodal] = useState(false);
  const [showcertificationmodal, setshowcertificationmodal] = useState(false);
  const [showawardsmodal, setshowawardsmodal] = useState(false);
  const [showallAwards, setshowallAwards] = useState(false);
  const [showallCertificates, setshowallCertificates] = useState(false);
  const [showvolexpmodal, setShowvolexpmodal] = useState(false);

  const visibleItemsJobs = showAllJobs
    ? sortByFromYearDesc(
        loggedInUser?.complete_profile?.profile?.professionalDetails.experience
      )
    : [];
  const visibleItemsVol = showAllVolExp
    ? sortByFromYearDesc(
        loggedInUser?.complete_profile?.profile?.professionalDetails
          .volunteerExperience
      )
    : [];
  const visibleItemsprojects = showAllProjects
    ? sortByFromYearDesc(
        loggedInUser?.complete_profile?.profile?.professionalDetails.projects
      )
    : [];
  const visibleItemsEducation = showAllEducation
    ? sortByFromYearDesc(
        loggedInUser?.complete_profile?.profile?.professionalDetails.education
      )
    : [];
  const visibleItemsAwards = showallAwards
    ? sortByFromYearDesc(
        loggedInUser?.complete_profile?.profile?.professionalDetails
          .achievements
      )
    : [];
  const visibleItemsCertificates = showallCertificates
    ? sortbyyearcert(
        loggedInUser?.complete_profile?.profile?.professionalDetails
          .certifications
      )
    : [];

  const token = localStorage.getItem("token");
  const refreshtoken = localStorage.getItem("refreshtoken");
  const Location = useLocation();
  const [viewprof, setviewprof] = useState(false);
  const [loading, setloading] = useState(true);
  const [connectionres, setconnectionres] = useState({});

  const [selectedUser, setselectedUser] = useState({});
  const [isloggedInUser, setIsLoggedInUser] = useState(false);
  const currenturl = window.location.pathname;

  //// // // console,log(token);
  const [connections, setconnections] = useState(0);

  const getProfile = async () => {
    const url = Location.pathname.slice(1);
    try {
      const reqbody = {
        refreshtoken: refreshtoken,
        url: url,
      };
      // // // console,log("dashboard: ", reqbody, { token: token });
      const response = await axios.post(
        apiUrl.profile?.getprofile + token,
        reqbody
      );

      if (response.status === 401) {
        // localStorage.removeItem("token");
        // navigate("/");
      } else if (response.status === 200) {
        // console.log(response.data);
        setviewprof(true);
        setconnections(response.data.noofconnections);
        setdash(response.data.existing_profile);
        setgetprofile(response.data.existing_profile);
        setpublicview(response.data.ispublic);
        setimage(response.data.existing_profile?.Profilepic);
        setCollegeProfiles(response.data.edushortlist);
        if (response.data.existing_profile?.profilestatus != "/") {
          postProfileStatus();
        }
        // // // console,log(response.data.token);
      }
    } catch (err) {
      // // // console,log("error encountered");
      // localStorage.removeItem("token");
      // navigate("/login");
    }
  };

  const getPublicProfile = async () => {
    setloading(true);
    const url = Location.pathname.slice(1);
    try {
      const reqbody = {
        refreshtoken: refreshtoken,
        url: url,
      };
      // // // console,log("dashboard: ", reqbody, { token: token });
      const response = await axios.post(
        apiUrl.profile?.getprofile + token,
        reqbody
      );

      if (response.status === 401) {
        // localStorage.removeItem("token");
        // navigate("/");
      } else if (response.status === 200) {
        // console.log(response.data);
        setselectedUser(response.data);
        setviewprof(true);
        setconnections(response.data.noofconnections);
        setdash(response.data);
        setgetprofile(response.data.existing_profile);
        setpublicview(response.data.ispublic);
        setimage(response.data.existing_profile?.Profilepic);
        setcov(response.data.existing_profile?.Coverpic);
        setCollegeProfiles(response.data.edushortlist);
        if (response.data.existing_profile?.profilestatus != "/") {
          postProfileStatus();
        }
        // // // console,log(response.data.token);
      }
    } catch (err) {
      // // // console,log("error encountered");
      // localStorage.removeItem("token");
      // navigate("/login");
    }
    setloading(false);
  };



  const postProfileStatus = async () => {
    try {
      const reqbody = {
        profilestatus: "/",
      };
      const response = await axios.post(
        apiUrl.profile?.editprofile + `${token}`,
        reqbody
      );
      if (response.status === 200) {
      }
    } catch (error) {}
  };

 

  useEffect(() => {
    setloading(true);
    // console.log(Location.pathname.split("/")[1]);
    if (loggedInUser) {
      if (
        Location.pathname.split("/")[1] !=
        loggedInUser?.complete_profile?.user?.profileUrl
      ) {
        // console.log("Different");
        getPublicProfile();
      } else {
        setIsLoggedInUser(true);
        setpublicview(false);
        setdash(loggedInUser);
        setselectedUser({});
        // console.log("Same");
      }
    }
    setloading(false);
  }, [currenturl]);

  useEffect(()=> {
    if(loggedInUser){
      if (
        Location.pathname.split("/")[1] ==
        loggedInUser?.complete_profile?.user?.profileUrl
      ){
        setdash(loggedInUser);
      }
    }
  },[loggedInUser])

  const [addsection, setaddsection] = useState(false);
  const toggleaddsection = () => {
    setaddsection(!addsection);
  };

  const updateAnalytics = async () => {
    try {
      const resp = await axios.post(apiUrl.views.addviewcount + token, {
        url: Location.pathname.split("/")[1],
      });
      if (resp && resp.status === 200) {
        // // console.log(resp.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      // // console.log("updating")
      updateAnalytics();
    }
  }, []);

  const handleOpenSkillsModal = (skills) => {
    setshowskills(!showskills);
    if (skills) {
      changeloggedInUser({
        ...loggedInUser,
        profile_status: {
          ...loggedInUser?.profile_status,
          Skills: skills == null || skills.length == 0 ? false : true,
        },
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              skills: skills,
            },
          },
        },
      });
    }
  };

  const handleOpenEducationModal = (education) => {
    setshowediteducation(!showediteducation);
    // console.log(education);
    if (education !== null) {
      changeloggedInUser({
        ...loggedInUser,
        profile_status: {
          ...loggedInUser?.profile_status,
          Education: education == null || education.length == 0 ? false : true,
        },
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              education: education,
            },
          },
        },
      });
    }
  };

  const handleOpenExperienceModal = (experience) => {
    setshoweditexperience(!showeditexperience);
    // // console.log(education)
    if (experience !== null) {
      changeloggedInUser({
        ...loggedInUser,
        profile_status: {
          ...loggedInUser?.profile_status,
          Experience:
            experience == null || experience.length == 0 ? false : true,
        },
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              experience: experience,
            },
          },
        },
      });
    }
  };

  const handleOpenProjectsModal = (project) => {
    setshowprojectmodal(!showprojectmodal);
    // console.log(project);
    if (project !== null) {
      changeloggedInUser({
        ...loggedInUser,
        profile_status: {
          ...loggedInUser?.profile_status,
          Projects: project == null || project.length == 0 ? false : true,
        },
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              projects: project,
            },
          },
        },
      });
    }
  };

  const handleAwardsModal = (award) => {
    setshowawardsmodal(!showawardsmodal);
    if (award !== null) {
      changeloggedInUser({
        ...loggedInUser,
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              achievements: award,
            },
          },
        },
      });
    }
  };

  const handleCertificationModal = (certification) => {
    setshowcertificationmodal(!showcertificationmodal);
    if (certification !== null) {
      changeloggedInUser({
        ...loggedInUser,
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              certifications: certification,
            },
          },
        },
      });
    }
  };

  const handleVolunteeringModal = (volunteering) => {
    setShowvolexpmodal(!showvolexpmodal);
    if (volunteering !== null) {
      changeloggedInUser({
        ...loggedInUser,
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            professionalDetails: {
              ...loggedInUser?.complete_profile?.profile?.professionalDetails,
              volunteerExperience: volunteering,
            },
          },
        },
      });
    }
  };

  const manageSummarymodal = (summary) => {
    setshowsummarymodal(!showsummarymodal);
    // console.log(summary);
    if (summary !== null) {
      changeloggedInUser({
        ...loggedInUser,
        profile_status: {
          ...loggedInUser?.profile_status,
          Summary: summary == "" ? false : true,
        },
        complete_profile: {
          ...loggedInUser?.complete_profile,
          profile: {
            ...loggedInUser?.complete_profile?.profile,
            summary: summary,
          },
        },
      });
    }
  };

  const renderComponent = (componentId) => {
    switch (componentId) {
      case "projects":
        return (
          dash?.complete_profile?.profile?.professionalDetails.projects &&
          dash?.complete_profile?.profile?.professionalDetails.projects
            .length !== 0 && (
            <ProjectsComponent
              setshowprojectmodal={setshowprojectmodal}
              showprojectmodal={showprojectmodal}
              visibleItemsprojects={visibleItemsprojects}
              setShowAllProjects={setShowAllProjects}
              showAllProjects={showAllProjects}
              publicview={publicview}
              selectedUser={selectedUser}
              api={api}
              setapi={setapi}
              dash={dash}
            />
          )
        );
      case "skills":
        return (
          dash?.complete_profile?.profile?.professionalDetails?.skills &&
          dash?.complete_profile?.profile?.professionalDetails?.skills
            .length !== 0 && (
            <SkillsComponent
              dash={dash}
              publicview={publicview}
              handleOpenSkillsModal={handleOpenSkillsModal}
              selectedUser={selectedUser}
            />
          )
        );
      case "experience":
        return (
          dash?.complete_profile?.profile?.professionalDetails?.experience &&
          dash?.complete_profile?.profile?.professionalDetails?.experience
            .length !== 0 && (
            <ExperienceComponent
              dash={dash}
              setshoweditexperience={setshoweditexperience}
              showAllJobs={showAllJobs}
              setShowAllJobs={setShowAllJobs}
              showeditexperience={showeditexperience}
              visibleItemsJobs={visibleItemsJobs}
              publicview={publicview}
              selectedUser={selectedUser}
              api={api}
              setapi={setapi}
            />
          )
        );
      case "education":
        return (
          dash?.complete_profile?.profile?.professionalDetails?.education &&
          dash?.complete_profile?.profile?.professionalDetails?.education
            .length !== 0 && (
            <EducationComponent
              setshowediteducation={setshowediteducation}
              showAllEducation={showAllEducation}
              setShowAllEducation={setShowAllEducation}
              showediteducation={showediteducation}
              visibleItemsEducation={visibleItemsEducation}
              publicview={publicview}
              selectedUser={selectedUser}
              api={api}
              setapi={setapi}
              dash={dash}
            />
          )
        );
      case "volunteerExperience":
        return (
          dash?.complete_profile?.profile?.professionalDetails
            ?.volunteerExperience &&
          dash?.complete_profile?.profile?.professionalDetails
            ?.volunteerExperience.length !== 0 && (
            <VolExpcomponent
              dash={dash}
              publicview={publicview}
              selectedUser={selectedUser}
              showvolexpmodal={showvolexpmodal}
              setShowvolexpmodal={setShowvolexpmodal}
              showAllVolExp={showAllVolExp}
              setShowAllVolExp={setShowAllVolExp}
              api={api}
              setapi={setapi}
              visibleItemsVol={visibleItemsVol}
            />
          )
        );
      case "certifications":
        return (
          dash?.complete_profile?.profile?.professionalDetails
            ?.certifications &&
          dash?.complete_profile?.profile?.professionalDetails?.certifications
            .length !== 0 && (
            <CertificationComponent
              publicview={publicview}
              selectedUser={selectedUser}
              handleCertificationModal={handleCertificationModal}
              dash={dash}
              api={api}
              setapi={setapi}
              visibleItemsCertificates={visibleItemsCertificates}
              showallCertificates={showallCertificates}
              setshowallCertificates={setshowallCertificates}
            />
          )
        );
      case "achievements":
        return (
          dash?.complete_profile?.profile?.professionalDetails?.achievements &&
          dash?.complete_profile?.profile?.professionalDetails?.achievements
            .length !== 0 && (
            <AwardsComponent
              publicview={publicview}
              selectedUser={selectedUser}
              handleAwardsModal={handleAwardsModal}
              dash={dash}
              api={api}
              setapi={setapi}
              visibleItemsAwards={visibleItemsAwards}
              showallAwards={showallAwards}
              setshowallAwards={setshowallAwards}
            />
          )
        );
      default:
        return null;
    }
  };

  const handleEditProfile = () => {
    setvieweditprofile(!vieweditprofile);
  };

  const handleProjectsModal = () => {
    setvieweditprofile(!vieweditprofile);
  };

  if (loading || !dash) {
    return <Spinnerpage />;
  } else if (viewprof && token === null) {
    return navigate("/login");
  } else {
    return (
      <div>
        <div>
          {showsummarymodal && (
            <EditSummaryModal
              manageSummarymodal={manageSummarymodal}
              dash={dash}
              api={api}
              setapi={setapi}
            />
          )}
          {showvolexpmodal && (
            <AddVolExpModal handleVolunteeringModal={handleVolunteeringModal} />
          )}
   
          {showcertificationmodal && (
            <AddCertificationModal
              handleCertificationModal={handleCertificationModal}
            />
          )}
          {showawardsmodal && (
            <AddAawardsModal handleAwardsModal={handleAwardsModal} />
          )}
          {vieweditprofile && (
            <EditProfileModel
              vieweditprofile={vieweditprofile}
              setvieweditprofile={setvieweditprofile}
              api={api}
              setapi={setapi}
            />
          )}
          {addsection && (
            <AddSectionModal
              toggleaddsection={toggleaddsection}
              toggleproject={() => setshowprojectmodal(!showprojectmodal)}
              toggleeducation={() => setshowediteducation(!showediteducation)}
              toggleexperience={() =>
                setshoweditexperience(!showeditexperience)
              }
              toggleskills={() => setshowskills(!showskills)}
              togglesummary={() => manageSummarymodal(null)}
              handleAwardsModal={handleAwardsModal}
              handleCertificationModal={() => handleCertificationModal(null)}
              handleVolExpModal={() => {
                setShowvolexpmodal(!showvolexpmodal);
              }}
            />
          )}
          {showprojectmodal && (
            <EditProjectModal
              handleOpenProjectsModal={handleOpenProjectsModal}
            />
          )}
          {showediteducation && (
            <EditEduModel handleOpenEducationModal={handleOpenEducationModal} />
          )}
          {showeditexperience && (
            <EditExpModel
              handleOpenExperienceModal={handleOpenExperienceModal}
            />
          )}
          {showskills && (
            <EditSkillsModal handleOpenSkillsModal={handleOpenSkillsModal} />
          )}
          <div></div>
        </div>
        <div className={`flex justify-center min-h-screen bg-white text-black`}>
          <div className="w-full md:max-w-screen-xl md:w-[100%] flex md:flex-row flex-col gap-x-4 md:p-4">
            <div className="rounded-md md:w-[70%] w-[100%]">
              <div className={`md:pb-2 md:space-y-4 space-y-2 z-0 `}>
                {dash?.complete_profile && (
                  <PersonalDetails
                    dash={dash}
                    handleEditProfile={handleEditProfile}
                    // openModal={openModal}
                    // api={api}
                    // setapi={setapi}
                    toggleaddsection={toggleaddsection}
                    // url={url}
                    // manageCompModal={manageCompModal}
                    // openCompModal={openCompModal}
                    // coverpicmodal={coverpicmodal}
                    // setcoverpicmodal={setcoverpicmodal}
                    // profilepicmodal={profilepicmodal}
                    // setprofilepicmodal={setprofilepicmodal}
                    // image={image}
                    // setimage={setimage}
                    // cov={cov}
                    // setcov={setcov}
                    // connectionres={connectionres}
                    // connections={connections}
                  />
                )}

                {dash?.complete_profile?.profile?.summary && (
                  <SummaryComponent
                    dash={dash}
                    manageSummarymodal={manageSummarymodal}
                  />
                )}

                {dash?.complete_profile?.profile?.professionalDetails &&
                  Object.entries(
                    dash?.complete_profile?.profile?.professionalDetails
                  ).map(([item, isEnabled], key) => (
                    <div key={key}>{renderComponent(item)}</div>
                  ))}
                {/* {Object.keys(loggedInUser?.complete_profile?.profile?.professionalDetails} */}
              </div>
            </div>

            <div className="md:w-[30%] w-[100%] h-full  md:space-y-4 space-y-2">

              {dash?.isloggedinuser && (
                  <div>
                    <div className="">
                      <URLComponent
                      dash={dash}
                        urlsubmit={urlsubmit}
                        curraddsubmit={curraddsubmit}
                        url={url}
                        curradd={curradd}
                        connectionres={connectionres}
                        api={api}
                        setapi={setapi}
                      />
                    </div>

                    <div className="my-4">
                      <AnalyticsComponent
                        urlsubmit={urlsubmit}
                        curraddsubmit={curraddsubmit}
                        dash={dash}
                        url={url}
                        curradd={curradd}
                        connectionres={connectionres}
                        api={api}
                        setapi={setapi}
                      />
                    </div>
                  </div>
                )}
              {/* <CollegeSuggestedProfiles
                edushortlist={collegeprofiles}
                seturl={seturl}
              /> */}

              {/* {publicview && (
                <textarea className="resize-y rounded-md border border-gray-300 p-2 w-full text-black h-[25%]" placeholder="Enter your notes..."></textarea>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Layout;
