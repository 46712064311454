import React, { useEffect, useState,useContext } from "react";
import axios from "axios";
import apiUrl from "../apiConfig";
import { useNavigate } from "react-router-dom";
import DefaultCompany from "../images/DefaultCompany.png";
import DefaultEducation from "../images/DefaultEducation.png";
import ProfileTile from "../components/NetworkComponents/ProfileTile";
import Spinnerpage from "../components/Spinnerpage";
import { logincontext } from "../contexts/Logincontext";


export default function NewPeople() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);


  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To check if there's more data to fetch
  const token = localStorage.getItem("token");

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        apiUrl.profile.getrandomprofiles + token,
        {
          page: page,
          limit: 10,
        }
      );
      // console,log(response.data);
      // const profiles = response.data.profiles;
      // console,log(profiles);
      // const profilelist = [];
      // for (let i = 0; i < profiles.length; i++) {
      //   const profile = profiles[i];
      //   const newProfile = {
      //     profilepic: profile.Profilepic,
      //     Name: profile.FullName.DisplayName,
      //     currentRole: profile.currentRole,
      //     latestEducation: profile.LatestEducation,
      //     latestExperience: profile.LatestExperience,
      //     Location: profile.Location,
      //     url: profile.url,
      //   };
      //   profilelist.push(newProfile);
      // }
      // console,log(profilelist);
      setData((prevData) => [...prevData, ...response.data.profiles]);
      if (response.data.profiles.length < 10) {
        setHasMore(false); // If less than 10 items are fetched, there's no more data
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(hasMore){
      fetchData();
    }else{
      return;
    }
  }, [page,hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 2 <
          document.documentElement.offsetHeight ||
        loading
      ) {
        return;
      }
      if (hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const findimage = (name, type) => {
    const company = companies.find((obj) => obj.Name === name);
    const comimage = company ? company.s3Link : DefaultCompany;
    const university = universities.find((obj) => obj.Name === name);
    const schimage = university ? university.s3Link : DefaultEducation;
    return type === "job" ? comimage : schimage;
  };

  const handleVisitProfile = async (userId) => {
    try {
      const response = await axios.post(apiUrl.url.geturlusingid, {
        UserId: userId,
      });
      // // // console,log(response.data.existing_urlmodel);
      if (response.status === 200) {
        // seturl(response.data.existing_urlmodel.url);
        navigate("/" + response.data.existing_urlmodel.url);
      }
    } catch (error) {}
  };

  const navigate = useNavigate();

  const [requestssent, setRequestsSent] = useState([]);
  const connectapi = async (url,userId) => {
    try {
      const resp = await axios.post(apiUrl.connections.request + token, {
        url: url,
      });
      //// console.log(resp);
      if (resp && resp.status === 200) {
        
        setRequestsSent([...requestssent, url]);
        // setCollegeProfiles(edushortlist.filter((item) => item.url !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile : {
            ...loggedInUser.complete_profile,
            connections : {
              ...loggedInUser.connections,
              sent : [...loggedInUser.connections.sent, userId]
            }
          }
        })
        
        // setrefresh(!refresh);
      }
    } catch (err) {
      // // console,log(err.message);
    }
  };

  const withdrawapi = async (url,userId) => {
    try {
      const resp = await axios.post(apiUrl.connections.withdrawrequest + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setRequestsSent(requestssent.filter((item) => item !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile : {
            ...loggedInUser.complete_profile,
            profile : {
              ...loggedInUser.complete_profile.profile,
            connections : {
              ...loggedInUser.connections,
              sent : loggedInUser.complete_profile.profile.connections.sent.filter(id => id !== userId)
          }
        }
        }
        });
      }
    } catch (err) {
      // // console,log(err.message);
      // console.log(err);
    }
  };


  const disconnect = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setRequestsSent(requestssent.filter((item) => item !== url));
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  return (
    <div className="flex justify-center ">
      <div className="w-full   md:max-w-screen-xl  flex md:flex-col flex-col py-2 px-4 md:rounded-md border-[1px]">
        <div className="text-lg font-[550] pb-4">Expand your Network</div>
        {data.map((user, index) => (
          <div
            key={index}
            className={` ${
              data.length - 1 === index ? "" : "border-b-[1px]"
            } p-2 h-full flex md:flex-row flex-col justify-between md:items-start py-2 w-full`}
          >
            <ProfileTile user={user} findimage={findimage} />
            {requestssent.includes(user.url) ? (
              <button className="text-white border-[1.5px] bg-[#0052ff]   cursor-pointer font-[550] py-[0.125rem] md:px-4 px-3 text-xs md:text-sm md:text-left text-center rounded-3xl my-2 md:my-0 h-fit w-fit"
              onClick={() => withdrawapi(user.url,user.UserId)}>
                Requested
              </button>
            ) : (
              <button
                className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-[0.125rem] md:px-4 px-3 text-xs md:text-sm md:text-left text-center rounded-3xl my-2 md:my-0 h-fit w-fit"
                onClick={() => connectapi(user.url,user.UserId)}
              >
                Connect
              </button>
            )}
          </div>
        ))}
        {hasMore && loading && (
          <div>
            <div className="w-full flex py-4 justify-center items-center">
              <div className="flex space-x-2 w-20">
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
              </div>
            </div>
          </div>
        )}
        {/* {!hasMore && <div>No more data to display</div>} */}
      </div>
    </div>
  );
}
