import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

import MailOtp from "./MailOtp";
import WaitlistModal from "./WaitlistModal";
import { logincontext } from "../contexts/Logincontext";
import ShowValidations from "./signup/showValidations";

const Register = () => {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const [viewMailOtp, setViewMailOtp] = useState(false);
  const [waitlistModal, setWaitlistModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [data, setdata] = useState({});
  const [validationStatus, setValidationStatus] = useState({
    validLength: false,
    containsUpperCase: false,
    containsLowerCase: false,
    containsSpecialChar: false,
    containsNumber: false,
  });

  const validatePassword = (password) => {
    setValidationStatus({
      validLength: password.length >= 8,
      containsUpperCase: /[A-Z]/.test(password),
      containsLowerCase: /[a-z]/.test(password),
      containsSpecialChar: /[^A-Za-z0-9]/.test(password),
      containsNumber: /\d/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    validatePassword(e.target.value);
  };

  const isAllValid = Object.values(validationStatus).every(Boolean);

  const submitForm = async (data) => {
    setLoading(!loading);

    const { cpassword, ...userdata } = data;

    // // // console,log(userdata);
    setdata(userdata);
    const resdata = await Signupuser(userdata);
    // // console,log(resdata);
    // console.log(resdata);

    if (resdata) {
      setLoading(false);
      const field = resdata[1].field;
      if (resdata[0] === 500) {
        setError(field, { type: "manual", message: resdata[1].message });
        return;
      }
      setError(field, { type: "manual", message: resdata[1].message });
      window.scroll(0, 0);
      return;
    }

    setViewMailOtp(true);
  };

  return (
    <div>
      {viewMailOtp && (
        <MailOtp
        viewmailotp={viewMailOtp}
        setviewotp={setViewMailOtp}
          data={data}
          setdata={setdata}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {waitlistModal && (
        <WaitlistModal
          vieweditprofile={waitlistModal}
          setvieweditprofile={setWaitlistModal}
        />
      )}
      <div className="py-3">
        <h1 className="text-center text-2xl font-[550] font-sans">
          Create your Aspireup profile
        </h1>
      </div>
      <div className="px-4">
      <hr />
      </div>
      {errors.network && (
        <p className="text-red-500 text-sm">{errors.network.message}</p>
      )}
      <form onSubmit={handleSubmit(submitForm)} className="p-4">
        <input
          type="text"
          placeholder="First Name"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.Firstname ? "border-red-500" : ""
          }`}
          {...register("Firstname", { required: "First Name is required" })}
        />
        {errors.Firstname && (
          <p className="text-red-500 text-sm">{errors.Firstname.message}</p>
        )}

        <input
          type="text"
          placeholder="Last Name"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.Lastname ? "border-red-500" : ""
          }`}
          {...register("Lastname", { required: "Last Name is required" })}
        />
        {errors.Lastname && (
          <p className="text-red-500 text-sm">{errors.Lastname.message}</p>
        )}

        <input
          type="email"
          placeholder="Email"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.email ? "border-red-500" : ""
          }`}
          {...register("email", {
            required: "Email is required",
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" },
          })}
        />
        {errors.email && (
          <p className="text-red-500 text-sm">{errors.email.message}</p>
        )}

        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.password ? "border-red-500" : ""
          }`}
          {...register("password", {
            required: "Password is required",
            onChange: handlePasswordChange,
            validate: {
              minLength: (value) =>
                value.length >= 8 || "Password must be at least 8 characters",
              upperCase: (value) =>
                /[A-Z]/.test(value) ||
                "Password must include at least one uppercase letter",
              lowerCase: (value) =>
                /[a-z]/.test(value) ||
                "Password must include at least one lowercase letter",
              specialChar: (value) =>
                /[^A-Za-z0-9]/.test(value) ||
                "Password must include at least one special character",
              number: (value) =>
                /\d/.test(value) || "Password must include at least one number",
            },
          })}
          onFocus={() =>  !errors.password && setShowRules(true)}
          onBlur={() => !isAllValid && setShowRules(false)}
        />
        {errors.password && (
          <p className="text-red-500 text-sm">{errors.password.message}</p>
        )}

        {ShowValidations({ showRules, validationStatus })}

        <input
          type={showPassword ? "text" : "password"}
          placeholder="Confirm Password"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.cpassword ? "border-red-500" : ""
          }`}
          {...register("cpassword", {
            required: "Please confirm your password",
            validate: (value) =>
              value === watch("password") || "Passwords do not match",
          })}
        />
        {errors.cpassword && (
          <p className="text-red-500 text-sm">{errors.cpassword.message}</p>
        )}
        <div className="py-1 flex items-center">
          <input
            type="checkbox"
            className="h-fit"
            onChange={() => setShowPassword(!showPassword)}
            name="showPassword"
            id="showPassword"
          />
          <label className="text-sm px-2" htmlFor="showPassword">
            Show password
          </label>
        </div>
       

        <div className="pt-2 mb-6 flex items-start">
          <input type="checkbox" required id="tnc" className="h-fit mt-1" />
          <label className="text-sm px-2" htmlFor="tnc">
            By signing up you agree to Aspireup's Terms of Service and Privacy
            Policy.
          </label>
        </div>
        <hr />
        <div className="mt-6 flex md:flex-row flex-col justify-between px-4 md:items-center gap-2">
          <div className="text-sm md:flex-col flex flex-row gap-1">
            Already have an account?
            <NavLink
              to="/login"
              onClick={() => window.scroll(0, 0)}
              className="text-[#0052ff] hover:text-[#014cec] font-[550] hover:underline"
            >
              Sign in
            </NavLink>
          </div>
          {loading ? (
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
          ) : (
            <button
              type="submit"
              className="bg-[#0052ff] text-white hover:bg-[#014cec] font-[550] py-1 px-4 rounded-3xl h-fit w-fit"
            >
              Register
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Register;
