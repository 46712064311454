import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useContext, useState, useRef } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Landing from "./pages/Landing";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Profile1 from "./components/Profiles/Profile1";
import Profileincom from "./pages/Profileincom";
import Profile3 from "./components/Profiles/Profile3";
import Profile4 from "./components/Profiles/Profile4";
import Profile5 from "./components/Profiles/Profile5";
import Educationreview from "./components/Educationreview";
import JobReview from "./components/JobReview";
import Profile6 from "./components/Profiles/Profile6";
import Profile4_1 from "./components/Profiles/Profile4_1";
import ProjectReview from "./components/ProjectReview";
import "react-toastify/dist/ReactToastify.css";
import ProfileSocial from "./components/Profiles/ProfileSocial";
import Profile0 from "./components/Profiles/Profile0";
import ForgotPassword from "./pages/ForgotPassword";
import AccessManagement from "./pages/AccessManagement";
import ViewResume from "./pages/ViewResume";
import axios from "axios";
import apiUrl from "./apiConfig";
import Documents from "./pages/Documents";
import OuterDashBoard from "./pages/OuterDashBoard";
import Settings from "./pages/Settings";
import AdminPage from "./pages/AdminPage";
import LandingPage from "./pages/LandingpageMain";
import Courses from "./pages/Courses";
import CourseDesign from "./pages/CourseDesign";
import NetWork from "./pages/NetWork";
import SelectedJob from "./components/SelectedJob";
import Spinnerpage from "./components/Spinnerpage";
import ActiveChats from "./components/Chats/ActiveChats";
import ChatDetails from "./components/Chats/ChatArea";
import { WebSocketContext } from "./contexts/WebSocketContext";
import Chatsdefault from "./components/Chats/Chatsdefault";
import RecruiterNavbar from "./components/Recruiter/RecruiterNavbar";
import NewPeople from "./pages/NewPeople";
import Bugreport from "./components/Bugreport";
import AccountDeletionFeedback from "./pages/AccountDeletionFeedback";
import ReqSent from "./components/NetworkComponents/ReqSent";
import Connections from "./components/NetworkComponents/Connections";
import Tags from "./components/NetworkComponents/Tags";
import OuterTag from "./components/NetworkComponents/OuterTag";
import Profile3_1 from "./components/Profiles/Profile3_1";
import AdminAddLogos from "./components/AdminAddLogos";
import GlobalAdmin from "./pages/GlobalAdmin";
import Lists from "./components/NetworkComponents/Lists";
import { logincontext } from "./contexts/Logincontext";
import SinglePostPage from "./pages/SinglePostPage";
import Feed from "./pages/Feed";
import PostsOutline from "./components/PostsOutline";
import { routes } from "./jsonfiles/routes";
import Sections from "./pages/Admin/Sections";
export default function App() {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  
    const [selectedSchool, setSelectedSchool] = useState();

  const curraddsubmit = async (data) => {
    const da = {
      address: data,
    };
    try {
      const response = await axios.post(apiUrl.edituserSchema + token, da);
      if (response.status === 200) {
        setcuradd(da);
        setapi(!api);
        // // // console,log("address updated");
      }
    } catch (err) {}
  };

  const Location = useLocation();
  const [url, seturl] = useState("");
  const [email, setemail] = useState("");
  const [curradd, setcuradd] = useState({});
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const [api, setapi] = useState(false);
  const [posts, setPosts] = useState([]);

  const { socket, connectSocket, disconnectSocket } =
    useContext(WebSocketContext);

  useEffect(() => {
    // const fetchurl = async () => {
    //   {
    //     setloading(true);
    //     try {

    //       const reqbody = {
    //         refreshtoken: refreshtoken,
    //         url: Location.pathname.split("/")[1],
    //       };
    //       const response = await axios.post(apiUrl.url.geturl + token, reqbody);
    //       if (response && response.status === 200) {
    //         seturl(response.data.url);
    //         if (socket) {
    //           if (!socket.connected) {
    //             connectSocket();
    //           }
    //         } else {
    //           connectSocket();
    //         }
          
    //         if (token && Location.pathname === "/") {
    //           navigate(response.data.url.url);
    //         }
    //         setemail(response.data.url.email);
    //         setcuradd(response.data.url.address);
    //         setloading(false);
    //       }
    //     } catch (err) {
    //     }
    //     setloading(false);
    //   }
    // };

    // const fetchurlwot = async () => {
    //   {
    //     setloading(true);
    //     try {
    //       const reqbody = {
    //         refreshtoken: refreshtoken,
    //         url: Location.pathname.split("/")[1],
    //       };
    //       const response = await axios.post(apiUrl.url.geturl, reqbody);
    //       if (response && response.status === 200) {
    //         setUserId(response.data.url.UserId);
    //         seturl(response.data.url.url);
    //         setcuradd(response.data.url.address);
    //       }
    //     } catch (err) {
    //     }
    //     setloading(false);
    //   }
    // };

    if (type === "admin") {
    } else {
      if (token && loggedInUser?.complete_profile?.user?.profileUrl) {
        seturl(loggedInUser?.complete_profile?.user?.profileUrl);
      } else{
        
      }
      
    }
  }, [loggedInUser]);


  useEffect(() => {
    if (token) {
      if (socket) {
        if (!socket.connected) {
          connectSocket();
        }
      } else {
        connectSocket();
      }
    }
  }, [token]);

  const [refresh, setrefresh] = useState(false);

  const disconnect = async (url) => {
    // console.log(url);
    try {
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        changeloggedInUser({
          ...loggedInUser,
          complete_profile: {
            ...loggedInUser.complete_profile,
            profile: {
              ...loggedInUser.complete_profile,
              connections: {
                ...loggedInUser.complete_profile.connections,
                connections:
                  loggedInUser.complete_profile.connections.connections.filter(
                    (con) => con.url !== url
                  ),
              },
            },
          },
        });
      }
    } catch (err) {}
  };




  return (
    <div
      className={` min-h-screen w-full min-w-[300px] text-black light-mode `}
    >
      <div className="  z-999 relative bg-black">
        {Location.pathname.startsWith("/recruiter") || type === "recruiter" ? (
          <RecruiterNavbar
            url={url}
            api={api}
            seturl={seturl}
            setapi={setapi}
          />
        ) : (
          <Navbar url={url} api={api} seturl={seturl} setapi={setapi} selectedSchool={selectedSchool} />
        )}

        <div className="border-b border-[1.5px] border-gray-200"></div>
      </div>

      <div className="mt-11" style={{ minHeight: "calc(100vh-3rem)" }}>
        <Routes>
          <Route element={<Landing />} path="/" />

          <Route
            element={<Feed posts={posts} setPosts={setPosts} selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} />}
            path="/feed"
          >
            <Route element={<SinglePostPage />} path="post/:id" />
            <Route
              element={
                <PostsOutline posts={posts} loggedInUser={loggedInUser} />
              }
              path=":schoolurl"
            >
               <Route
              element={
                <PostsOutline posts={posts} loggedInUser={loggedInUser} />
              }
              path=":groupid"
            ></Route>
            </Route>
          </Route>
          <Route element={<Signup url={url} />} path="/signup" />
          <Route element={<LandingPage />} path="/learning/dsa" />
          <Route element={<Login url={url} />} path="/login" />
          <Route
            element={<ForgotPassword url={url} />}
            path="/forgotpassword"
          />
          <Route
            element={<AccessManagement url={url} />}
            path="/accessmanagement"
          />
          <Route element={<Documents url={url} />} path="/documents" />
          <Route element={<AdminPage url={url} />} path="/admin/:email" />
          <Route element={<AdminAddLogos url={url} />} path="/admin/addlogos" />
          <Route element={<Sections url={url} />} path="/admin/sections" />
          <Route path="/globaladmin" element={<GlobalAdmin />} />
          <Route
            element={<Settings url={url} email={email} />}
            path="/settings"
          />

          <Route element={<Profileincom />} path="/signup">
            <Route path="phoneno" element={<Profile0 />} />
            <Route path="resume" element={<Profile1 />} />

            <Route path="address" element={<Profile3 />} />
            <Route path="profilepicture" element={<Profile3_1 />} />
            <Route path="add-education" element={<Profile4 />} />
            <Route path="education-review" element={<Educationreview />} />
            <Route path="add-project" element={<Profile4_1 />} />

            <Route path="project-review" element={<ProjectReview />} />
            {/* sdfsdf */}
            <Route path="add-experience" element={<Profile5 />} />
            <Route path="job-review" element={<JobReview />} />
            <Route path="add-skills" element={<Profile6 url={url} />} />

            <Route
              path="add-socialprofiles"
              element={<ProfileSocial url={url} seturl={seturl} />}
            />
          </Route>
          <Route
            path="/deleteaccount/*"
            element={<AccountDeletionFeedback />}
          />
          <Route element={<OuterDashBoard />} path={`:currenturl/*`}>
            <Route
              element={
                <Dashboard
                  curradd={curradd}
                  setcuradd={setcuradd}
                  url={url}
                  seturl={seturl}
                  api={api}
                  setapi={setapi}
                  curraddsubmit={curraddsubmit}
                />
              }
              path=""
            />
            <Route element={<ViewResume url={url} />} path="resume" />
            <Route element={<Spinnerpage />} path="*" />
          </Route>

          <Route element={<ActiveChats />} path="/chats">
            <Route element={<Chatsdefault />} path="" />
            <Route element={<ChatDetails />} path=":chaturl" />
            <Route element={<Chatsdefault />} path="*" />
          </Route>
          <Route
            element={
              <NetWork
                seturl={seturl}
                refresh={refresh}
                setrefresh={setrefresh}
              />
            }
            path="/network"
          >
            <Route
              element={
                <NewPeople
                  seturl={seturl}
                  refresh={refresh}
                  setrefresh={setrefresh}
                />
              }
              path="newpeople"
            />
            <Route
              element={<ReqSent refresh={refresh} setrefresh={setrefresh} />}
              path="invitations"
            />
            <Route
              element={
                <Connections
                  disconnect={disconnect}
                  refresh={refresh}
                  setrefresh={setrefresh}
                />
              }
              path="connections"
            />
            <Route
              element={<OuterTag refresh={refresh} setrefresh={setrefresh} />}
              path="tags"
            />
            {/* <Route element={<Lists/>} path="lists"/> */}
          </Route>
          <Route element={<Courses />} path="courses">
            <Route element={<CourseDesign />} path="dsa" />
          </Route>

          <Route element={<SinglePostPage />} path="/post/:id" />

          <Route element={<Spinnerpage />} path="*" />
        </Routes>
      </div>

      {Location.pathname !== "/login" && Location.pathname !== "/signup" && (
        <Footer />
      )}

      <Bugreport />
    </div>
  );
}
