import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../apiConfig";
import DefaultEducation from "../../images/DefaultEducation.png";
import { Accordion } from "keep-react";
import { MdKeyboardArrowRight } from "react-icons/md";
import AddSectionModal from "../../components/Admin/AddSectionModal";
import dummyDP from "../../asserts/dummyDp.png";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddGroupModal from "../../components/Admin/AddGroupModal";
import RemoveGroupMemberModal from "../../components/Admin/RemoveGroupMemberModal";

export default function Sections() {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedSchoolImage, setSelectedSchoolImage] = useState("");
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [lengthexceeded, setlengthexceeded] = useState(false);
  const [sections, setSections] = useState([]);
  const [independentGroups, setIndependentGroups] = useState([]);
  const [openAddSectionModal, setOpenAddSectionModal] = useState(false);
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openAddRemoveMemberModal, setOpenAddRemoveMemberModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  const [requests, setRequests] = useState([]);
  const [members, setMembers] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities);
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setSchools(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const fetchGroupsAndSections = async (school) => {
    try {
      const response = await axios.post(apiUrl.feed.getallsections + token, {
        school: school,
      });
      if (response.status == 200) {
        // console.log(response.data);
        setSections(response.data.sections);
        setIndependentGroups(response.data.independentGroups);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSchoolChange = (e) => {
    const inputSchool = e.target.value;
    if (inputSchool.length > 100) {
      setlengthexceeded(true);
      return;
    } else {
      setlengthexceeded(false);
    }
    setSelectedSchool(inputSchool);
    // Filter schools based on input
    // You would replace usSchools with the array containing your school names
    // For the purpose of this example, let's assume we have a dummy array called usSchools

    const filtered1 = schools.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchool.toLowerCase())
    );

    const filtered2 = schools.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchool.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchool === "") {
      setSelectedSchoolImage("");
    }
    if (finalFiltered.length > 0) {
      // setSelectedSchoolImage(filtered[0].s3Link);
    } else {
      setSelectedSchoolImage(DefaultEducation);
    }
    setFilteredSchools(finalFiltered);
  };

  const handleSchoolSelect = (obj) => {
    setSelectedSchool(obj.Name);
    setSelectedSchoolImage(obj.s3Link);
    setFilteredSchools([]);
    fetchGroupsAndSections(obj.Name);
  };

  const handleAddSectionModal = (obj) => {
    setOpenAddSectionModal(!openAddSectionModal);
    // console.log(sections);
    // console.log(obj);
    if (obj != null) {
      sections.push(obj);
    }
  };

  const handleAddGroupModal = (obj) => {
    setOpenAddGroupModal(!openAddGroupModal);
    if (obj != null) {
      const sectionIndex = sections.findIndex(
        (section) => section.sectionId === obj.sectionId
      );
      if (sectionIndex > -1) {
        sections[sectionIndex].groups.push(obj);
      }
    }
  };

  const handleViewRequests = async (group) => {
    try {
      setSelectedGroup(group);
      const response = await axios.post(apiUrl.feed.getrequests, {
        groupId: group.groupId,
      });
      if (response.status == 200) {
        // console.log(response.data);
        setRequests(response.data.requests);
        setMembers(response.data.members);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleReject = async (userId) => {
    try {
      const response = await axios.post(apiUrl.feed.rejectgrouprequest, {
        userId: userId,
        groupId: selectedGroup.groupId,
      });
      if (response.status == 200) {
        setRequests(requests.filter((item) => item.userId !== userId));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleAccept = async (userId) => {
    try {
      const response = await axios.post(apiUrl.feed.acceptgrouprequest, {
        userId: userId,
        groupId: selectedGroup.groupId,
      });
      if (response.status == 200) {
        setRequests(requests.filter((item) => item.userId !== userId));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRemoveMemberModal = async (user,status) => {
    console.log(user,selectedGroup,status)
    setSelectedUser(user);
    setOpenAddRemoveMemberModal(!openAddRemoveMemberModal);
      if(status){
        setMembers(members.filter((item)=>item.userId!==selectedUser.userId))
      }
  }

  return (
    <div className={`flex justify-center min-h-screen bg-[#ffffff]`}>
      <div className="w-full md:max-w-screen-xl md:w-[100%] px-4 ">
        <div className="flex my-4 gap-4 text-xl font-[550]">
          Sections And Groups
        </div>
        <form className="w-3/4">
          <div>
            <label htmlFor="school">
              School <span className="text-red-500">*</span>
            </label>
            <div className="w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 bg-white text-black">
              {selectedSchoolImage && (
                <img
                  src={selectedSchoolImage}
                  alt="School Logo"
                  className="h-6 w-6 ml-2"
                />
              )}
              <input
                type="text"
                className="focus:outline-none w-[100%]"
                name="school"
                value={selectedSchool}
                onChange={handleSchoolChange}
                required
              />
            </div>
            {lengthexceeded && (
              <p className="text-red-500 text-xs">
                School name should be less than 100 characters
              </p>
            )}
            {/* Dropdown menu for filtered schools */}
            {filteredSchools.length > 0 && (
              <div
                id="schoolDropdown"
                className="absolute z-10 mt-1 bg-white border max-h-[150px] overflow-y-auto rounded-md w-[100%] shadow-lg"
              >
                {filteredSchools.map((obj, index) => (
                  <div
                    key={index}
                    className="cursor-pointer flex hover:bg-gray-100 p-2"
                    onClick={() => handleSchoolSelect(obj)}
                  >
                    <img
                      src={obj.s3Link}
                      alt="School Logo"
                      className="h-6 w-6 mr-2 rounded-sm"
                    />
                    {/* Display School logo */}
                    {obj.Name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>
        {openAddSectionModal && (
          <AddSectionModal
            open={openAddSectionModal}
            setOpen={setOpenAddSectionModal}
            handleAddSectionModal={handleAddSectionModal}
            school={selectedSchool}
          />
        )}
        {openAddGroupModal && (
          <AddGroupModal
            open={openAddGroupModal}
            setOpen={setOpenAddGroupModal}
            handleAddGroupModal={handleAddGroupModal}
            school={selectedSchool}
            section={selectedSection}
          />
        )}
        {openAddRemoveMemberModal && (<RemoveGroupMemberModal handleRemoveMemberModal={handleRemoveMemberModal} group={selectedGroup} user={selectedUser}/>)}
        <div className="flex justify-between items-center">
          {selectedSchool ? (
            <div className="flex my-4 gap-4 text-xl font-[550]">
              Sections - {selectedSchool}
            </div>
          ) : (
            <div className="flex my-4 gap-4 text-xl font-[550]">Sections</div>
          )}
          <div className="flex gap-2 items-center">
            {/* <button
              className="w-fit h-fit text-[#0052ff] duration-300 cursor-pointer mt-2 py-1 px-4 border rounded-3xl border-[#0052ff] hover:bg-blue-200"
              onClick={() => handleAddSectionModal(null)}
            >
              Add Section
            </button> */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="duration-300 font-[550] cursor-pointer px-1 md:text-sm text-xs hover:text-gray-700">
                  <BsThreeDotsVertical size={20} />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item onClick={() => handleAddSectionModal(null)}>
                      <div className="text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                        Add Section
                      </div>
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      <div className="text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                        Delete Section
                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {/* <button
                className="w-fit h-fit text-red-500 duration-300 cursor-pointer mt-2 py-1 px-4 border rounded-3xl border-red-500 hover:bg-blue-200"
                onClick={handleAddSectionModal}
              >
                Delete Section
              </button> */}
          </div>
        </div>
        {sections.length > 0 ? (
          <div>
            <Accordion
              flush={true}
              openFirstPanel={true}
              className="flex flex-col gap-2"
            >
              {sections.map((section, index) => (
                <Accordion.Panel
                  key={index}
                  className="border border-black rounded"
                >
                  <Accordion.Container>
                    <Accordion.Title className="w-[95%]">
                      <div className="flex justify-between items-center text-black">
                        {section.name}
                      </div>
                    </Accordion.Title>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="duration-300 font-[550] cursor-pointer px-1 md:text-sm text-xs hover:text-gray-700">
                          <BsThreeDotsVertical size={20} />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item
                              onClick={() => {
                                setSelectedSection(section);
                                handleAddGroupModal(null);
                              }}
                            >
                              <div className="text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                                Add Group
                              </div>
                            </Menu.Item>
                          </div>
                          <div className="py-1">
                            <Menu.Item>
                              <div className="text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                                Delete Group
                              </div>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </Accordion.Container>
                  <Accordion.Content>
                    {section.groups.length > 0 ? (
                      section.groups.map((group, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center w-1/2 my-4 gap-8 text-lg text-black ml-4"
                        >
                          <div>{group.name}</div>
                          <div className="flex gap-8 text-sm text-[#0052ff]">
                            <div
                              className="underline offset-[2px] cursor-pointer"
                              onClick={() => handleViewRequests(group)}
                            >
                              View Requests and Members
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center">
                        <div className="flex my-4 gap-4 text-lg">
                          No groups available
                        </div>
                      </div>
                    )}
                  </Accordion.Content>
                </Accordion.Panel>
              ))}
            </Accordion>
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="flex my-4 gap-4 text-xl">No sections available</div>
          </div>
        )}
        <hr />
        {selectedSchool ? (
          <div className="flex my-4 gap-4 text-xl font-[550]">
            IndependentGroups - {selectedSchool}
          </div>
        ) : (
          <div className="flex my-4 gap-4 text-xl font-[550]">
            IndependentGroups
          </div>
        )}

        {independentGroups.length > 0 ? (
          <div>
            {independentGroups.map((group, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-1/2 my-4 gap-8 text-lg text-black ml-4"
              >
                <div>{group.name}</div>
                <div className="flex gap-8 text-sm text-[#0052ff]">
                  <div
                    className="underline offset-[2px] cursor-pointer"
                    onClick={() => handleViewRequests(group)}
                  >
                    View Requests and Members
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="flex my-4 gap-4 text-xl">No Independent Groups available</div>
          </div>
        )}
        <hr />
        <div className="flex">
          <div className="w-1/2">
            {selectedGroup ? (
              <div className="flex my-4 gap-4 text-xl font-[550]">
                Requests - {selectedGroup.name}
              </div>
            ) : (
              <div className="flex my-4 gap-4 text-xl  font-[550]">
                Requests
              </div>
            )}
            {requests?.length > 0 ? (
              requests.map((user) => (
                <div
                  key={user.id} // Use a unique identifier
                  className="w-1/2 p-2 flex justify-between items-center border-[1.5px] rounded-md my-2"
                >
                  <div className="flex items-start gap-2">
                    <img
                      src={user.profilePic}
                      alt="profile"
                      className="h-12 w-12 rounded-md"
                    />
                    <div>{user.name}</div>
                  </div>
                  <div className="flex gap-4">
                    <div
                      className="border border-green-500 p-2 rounded-full hover:bg-green-100 cursor-pointer"
                      title="Accept"
                      onClick={() => handleAccept(user.userId)}
                    >
                      <FaCheck className="text-green-500 " />
                    </div>
                    <div
                      className="border border-red-500 p-2 rounded-full hover:bg-red-100 cursor-pointer"
                      title="Reject"
                      onClick={() => handleReject(user.userId)}
                    >
                      <FaTimes className="text-red-500" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center w-1/2">
                <div className="flex my-4 gap-4 text-xl">
                  No requests available
                </div>
              </div>
            )}
          </div>
          <div class="inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 dark:bg-white/10"></div>
          <div className="w-1/2 ml-4">
            {selectedGroup ? (
              <div className="flex my-4 gap-4 text-xl font-[550]">
                Members - {selectedGroup.name}
              </div>
            ) : (
              <div className="flex my-4 gap-4 text-xl  font-[550]">Members</div>
            )}
            {members?.length > 0 ? (
              members.map((user) => (
                <div
                  key={user.id} // Use a unique identifier
                  className="w-3/4 p-2 flex justify-between items-center border-[1.5px] rounded-md my-2"
                >
                  <div className="flex justify-between items-start gap-2">
                    <img
                      src={user.profilePic}
                      alt="profile"
                      className="h-12 w-12 rounded-md"
                    />
                    <div>{user.name}</div>
                   
                  </div>
                  <div
                      className="border border-red-500 p-2 rounded-full hover:bg-red-100 cursor-pointer"
                      title="Reject"
                      onClick={() => handleRemoveMemberModal(user,null)}
                    >
                      <FaTimes className="text-red-500" />
                    </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center w-1/2">
                <div className="flex my-4 gap-4 text-xl">
                  No members available
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
