import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { logincontext } from "../contexts/Logincontext";
import Post from "../components/Post";
import axios from "axios";
import apiUrl from "../apiConfig";
import Spinnerpage from "../components/Spinnerpage";
import { set, useForm } from "react-hook-form";
import Sidebar from "../components/FeedSidebar";
import PostsOutline from "../components/PostsOutline";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import GroupMembers from "../components/GroupMembers";

import { FaSpinner } from "react-icons/fa";

export default function Feed({
  posts,
  setPosts,
  selectedSchool,
  setSelectedSchool,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  const { register, handleSubmit, formState: errors, reset } = useForm();
  const token = localStorage.getItem("token");
  const { schoolurl, groupid } = useParams();
  const navigate = useNavigate();
  const [creatingPost, setCreatingPost] = useState(false);
  const [flag, setflag] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const fileUpload = useRef(null);
  const imgUpload = useRef(null);
  const [schools, setschools] = useState([]);
  const [sidebarflag, setsidebarflag] = useState(false);
  const [error, seterror] = useState("");
  const [joinSchool, setJoinSchool] = useState(false);
  const [otherGroups, setOtherGroups] = useState([]);
  const [viewMembers, setViewMembers] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [viewAllUnis, setViewAllUnis] = useState(false);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);

        // setUniversities(response.data);
      } catch (error) {
        // // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  useEffect(() => {
    if (schoolurl === undefined) {
      setPosts([]);
    }
  }, [schoolurl]);

  useEffect(() => {
    groupIdRef.current = groupid;
  }, [groupid]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataAsync = async () => {
      if (isMounted && schools.length > 0 && !selectedGroup && schoolurl) {
        const sch = await decrypt(schoolurl, groupIdRef.current);
        fetchData(sch.name, sch.groupDetails);
      }
    };

    if (schoolurl && schools.length > 0 && !selectedGroup) {
      fetchDataAsync();
    }

    return () => {
      isMounted = false; // Cleanup
    };
  }, [schools.length, selectedGroup, schoolurl]); // Use minimal dependencies

  useEffect(() => {
    let isMounted = true;

    const fetchDataAsync = async () => {
      if (isMounted && page > 1) {
        const sch = await decrypt(schoolurl, groupIdRef.current);
        fetchData(sch.name, sch.groupDetails);
      }
    };

    if (page > 1) {
      fetchDataAsync();
    }

    return () => {
      isMounted = false;
    };
  }, [page, schoolurl]); // Add `schoolurl` if it's necessary for decryption

  useEffect(() => {
    if (loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading]); // Runs only when `loading` changes

  // useEffect(() => {
  //   window.scroll(0, 0);
  // }, [selectedGroup, selectedSchool]); // Runs only when dependencies change

  useEffect(() => {
    const getSchoolData = async () => {
      const education =
        loggedInUser?.complete_profile?.profile?.professionalDetails?.education;

      // Sort and format education data
      education?.sort((a, b) => {
        const dateA = new Date(a.to);
        const dateB = new Date(b.to);
        a.formattedTo = dateA.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        });
        b.formattedTo = dateB.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        });
        return dateB - dateA; // Descending order
      });

      // Extract unique school names
      const tempschools = [];
      education?.forEach((edu) => {
        if (!tempschools.includes(edu.school)) tempschools.push(edu.school);
      });

      setschools(tempschools);

      if (schoolurl) {
        const sch = await decrypt(schoolurl);
        setSelectedSchool(sch.name);
      } else {
        setSelectedSchool(tempschools[0]);
      }
    };

    if (loggedInUser) {
      getSchoolData();
    }
  }, [loggedInUser, schoolurl]); // Only runs when `loggedInUser` or `schoolurl` changes

  const [schoolLogo, setSchoolLogo] = useState("");
  useEffect(() => {
    const schobj = universities.find((uni) => selectedSchool === uni.Name);
    // console.log(schobj);
    setSchoolLogo(schobj?.s3Link);
  }, [selectedSchool, schoolurl]);

  const getPosts = async (page, school, groupId) => {
    try {
      // setLoading(true);
      // // console.log(school);
      if (school) {
        setSelectedSchool(school);
      }
      const response = await axios.post(
        `${apiUrl.feed.getposts}${token}/${page}`,
        {
          school: [school || selectedSchool],
          groupId: groupId,
        }
      );

      // // console.log(response);
      if (parseInt(response.currentPage) < parseInt(response.totalPages)) {
        setHasMore(true);
      }
      setLoading(false);
      return response.data;
    } catch (err) {
      // // console.log(err);
      // console.log(err?.response?.data?.message);
      seterror(err?.response?.data?.message);
      setLoading(false);
      return {
        finalposts: [],
        hasMore: false,
        isAccessed: err.response.status != 410,
      };
    }
  };

  const fetchData = async (sch, group) => {
    // // console.log("fetch data", sch);
    // // console.log(hasMore, schools);
    const data = await getPosts(page, sch, group?.groupId);
    // // console.log(data);
    if (data.finalposts.length === 0 && data.isAccessed) {
      seterror("No Posts Present!");
      setHasMore(false);
    } else {
      setPosts((prev) => [...prev, ...data.finalposts]);
    }
    if (group) {
      setSelectedGroup(group);
    }
  };

  const fetchDataSchool = async (school, group) => {
    if (!school) return; // Prevent unnecessary API calls
    seterror("");
    setLoading(true);

    try {
      const data = await getPosts(1, school, group?.groupId);
      if (data.finalposts.length === 0) {
        setHasMore(false);
        setPosts([]);
      } else {
        setPosts(data.finalposts);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      seterror("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const groupIdRef = useRef(groupid);

  const handleScroll = () => {
    if (
      document.body.scrollHeight - 300 <
      window.scrollY + window.innerHeight
    ) {
      setLoading(true);
    }
  };

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 100);

    // Add event listener
    window.addEventListener("scroll", debouncedHandleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const [saveimg, setsaveimg] = useState(null);
  const [savefile, setsavefile] = useState(null);

  const createPost = async (content) => {
    try {
      setCreatingPost(true); // Set transition state

      const img = saveimg;
      const file = savefile;
      // // console.log(content);

      if (!content.content && !img && !file && !content.school) {
        setCreatingPost(false);
        return;
      }

      const formData = new FormData();

      if (img) {
        const fileName = img.name.replace(/\s+/g, "_"); // Replace spaces with underscores
        const renamedFile = new File([img], fileName, { type: img.type });
        formData.append("file", renamedFile);
      }
      if (file) {
        const fileName = file.name.replace(/\s+/g, "_"); // Replace spaces with underscores
        const renamedFile = new File([file], fileName, { type: file.type });
        formData.append("file", renamedFile);
      }

      // // console.log(formData);
      // // console.log(selectedGroup);
      formData.append("content", content.content);
      formData.append("school", selectedSchool);
      // // console.log(selectedGroup);
      formData.append("groupId", selectedGroup?.groupId);

      const resp = await axios.post(
        `${apiUrl.feed.createpost}${token}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // // console.log(resp);
      if (resp.status === 200) {
        // // console.log(resp.data.post);
        reset();
        setPosts((prev) => [resp.data.post, ...prev]);
        setImagePreview(null);
        setPdfPreview(null);
        setsaveimg(null);
        setsavefile(null);
        seterror("");
      }
    } catch (err) {
      // console.error(err);
      alert("Failed to create a post. Please try again.");
    } finally {
      setCreatingPost(false); // Reset transition state
    }
  };

  async function decrypt(url, groupid) {
    // Decrypt the string with the inverse shift cipher

    let decryptedString = "";
    if (!url) {
      return decryptedString;
    }

    const query = {
      url: url,
    };
    if (groupid) {
      query.groupid = groupid;
    }

    try {
      const resp = await axios.post(apiUrl.feed.getschooldetails, query);
      if (!resp) {
        return "";
      }
      return resp.data;
    } catch (err) {
      // // console.log(err);
      return "";
    }
  }

  const [imagePreview, setImagePreview] = useState(null);

  const handlefileUpload = () => {
    return () => {
      fileUpload.current.click();
    };
  };
  const handleimgUpload = () => {
    return () => {
      imgUpload.current.click();
    };
  };

  const handleImageChange = () => {
    const img = imgUpload.current.files[0];

    if (img) {
      setsaveimg(img);
      setImagePreview(URL.createObjectURL(img)); // Set preview URL
    }
  };

  const [pdfPreview, setPdfPreview] = useState(null); // For PDF preview

  const handleFileChange = () => {
    const file = fileUpload.current.files[0];
    if (file && file.type === "application/pdf") {
      setsavefile(file);
      const fileURL = URL.createObjectURL(file);
      setPdfPreview(fileURL);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const cancelPdfPreview = () => {
    setPdfPreview(null);
    setsavefile(null);
    if (fileUpload.current) {
      fileUpload.current.value = ""; // Reset the input
    }
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  const requestToGroup = async (groupId) => {
    setButtonLoading(true);
    try {
      const response = await axios.post(apiUrl.feed.requesttogroup + token, {
        groupId: groupId,
      });
      if (response.status === 200) {
        if (selectedGroup) {
          setSelectedGroup({ ...selectedGroup, isRequested: "Yes" });
        }
        setOtherGroups((prev) =>
          prev.map((group) =>
            group.groupId === groupId ? { ...group, isRequested: "Yes" } : group
          )
        );
      } else if (response.status === 201) {
        if (selectedGroup) {
          setSelectedGroup({ ...selectedGroup, isRequested: "No" });
        }
        setOtherGroups((prev) =>
          prev.map((group) =>
            group.groupId === groupId ? { ...group, isRequested: "No" } : group
          )
        );
      }
    } catch (error) {
      // // console.log(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex items-center min-h-screen flex-col pt-4 ">
      {/* Top Navigation Bar */}
      <div className=" w-full p-2 flex  items-center lg:hidden  rounded-md justify-end">
        <button
          className="block lg:hidden p-2 rounded-md "
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 7.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
            />
          </svg>
        </button>
      </div>

      {/* Sidebar Menu (Conditionally Rendered) */}
      {isMenuOpen && (
        <div className="absolute top-0 right-0 bg-white shadow-lg w-[80%] max-w-xs h-full z-50 overflow-hidden">
          <div className="flex flex-col items-start p-4 gap-4">
            <button
              className="self-end text-gray-500 hover:text-gray-700"
              onClick={toggleMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* <div className="text-lg font-[550]">Organisations</div>
            <hr className="w-full" /> */}
            {/* {universities &&
              universities
                .filter((uni) => schools.includes(uni.Name))
                .map((school, index) => (
                  <div key={school._id} className="flex flex-col gap-1 w-full">
                    <div
                      className={` text-xs flex items-center gap-2 cursor-pointer p-1  ${
                        selectedSchool === school.Name
                          ? "border-l-[1px] border-[#0052ff] bg-blue-50"
                          : ""
                      } `}
                      onClick={async () => {
                        setPosts([]);
                        navigate("/feed/" + school.url);
                        setSelectedSchool(school.Name);
                        fetchDataSchool(school.Name);
                        setsidebarflag(!sidebarflag);
                        seterror("");
                        setSelectedGroup(null);
                        setHasMore(true);
                        toggleMenu();
                      }}
                    >
                      <img
                        src={school.s3Link}
                        alt="Company Logo"
                        className={
                          " h-[30px] p-1 aspect-square rounded-md  border-[1.5px]  border-gray-500 "
                        }
                      />
                      <div>{school.Name}</div>
                    </div>
                    <hr />
                  </div>
                ))} */}

            <div className="text-lg font-[550]">Sections</div>
            <hr className="w-full" />

            <Sidebar
              schools={schools}
              setSelectedSchool={setSelectedSchool}
              fetchDataSchool={fetchDataSchool}
              selectedSchool={selectedSchool}
              setLoading={setLoading}
              posts={posts}
              setPosts={setPosts}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              sidebarflag={sidebarflag}
              setsidebarflag={setsidebarflag}
              otherGroups={otherGroups}
              setOtherGroups={setOtherGroups}
              universities={universities}
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu}
            />

            {selectedGroup && error != "Join the group first" && (
              <div
                className="  p-2 flex flex-col gap-1 cursor-pointer"
                onClick={() => {
                  setViewMembers(!viewMembers);
                  toggleMenu();
                }}
              >
                <hr />
                <div className="text-sm text-center underline text-[#0052ff] hover:text-blue-600 flex items-end gap-2">
                  <div className="text-3xl">
                    {selectedGroup.members.length + selectedGroup.admins.length}{" "}
                  </div>
                  members
                </div>

                <div className="text-sm font-[550]">{selectedGroup.name}</div>
                <div className="text-sm">{selectedGroup.description}</div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="w-[100%] max-w-screen-xl flex flex-row gap-2 px-4 justify-center ">
        <div className="lg:w-[20%] hidden lg:flex flex-col gap-2 ">
          {error != "Join the group first" && (
            <div className="p-2 border-[1px] rounded-md h-fit">
              <Sidebar
                schools={schools}
                setSelectedSchool={setSelectedSchool}
                fetchDataSchool={fetchDataSchool}
                selectedSchool={selectedSchool}
                setLoading={setLoading}
                posts={posts}
                setPosts={setPosts}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                sidebarflag={sidebarflag}
                setsidebarflag={setsidebarflag}
                otherGroups={otherGroups}
                setOtherGroups={setOtherGroups}
                universities={universities}
              />
            </div>
          )}
        </div>

        {viewMembers ? (
          <GroupMembers
            memberIds={selectedGroup.members}
            toggleMenu={() => setViewMembers(!viewMembers)}
          />
        ) : (
          <div className="lg:w-[60%] w-[100%] flex flex-col gap-4">
            {/* Create a post */}
           

            {error != "Join the group first" ? (
              <div className="bg-white rounded-md border-[1px]">
                <form action="" onSubmit={handleSubmit(createPost)}>
                  <div className="flex flex-row items-start px-2 py-4">
                    <img
                      src={loggedInUser?.complete_profile?.profile?.profilePic}
                      alt=""
                      className="w-[40px] h-[40px] rounded-md mr-2 "
                    />
                    <textarea
                      type="text"
                      {...register("content")}
                      style={{ height: "auto" }}
                      placeholder="What's on your mind?"
                      className="rounded-md px-4 bg-gray-100 w-[90%] h-[40px] overflow-hidden resize-none focus:outline-none py-2"
                      onInput={(e) => {
                        e.target.style.height = "auto"; // Reset height to recalculate
                        e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
                      }}
                    />
                  </div>
                  {imagePreview && (
                    <div className="w-full h-0 pb-[56.25%] relative">
                      <img
                        src={imagePreview}
                        alt="Uploaded"
                        className="absolute top-0 left-0 w-full h-full object-cover"
                      />

                      <button
                        onClick={() => {
                          setImagePreview(null);
                          setsaveimg(null);
                        }}
                        className="absolute top-2 right-2 bg-gray-300 text-white rounded-full  hover:bg-gray-200"
                        aria-label="Cancel image preview"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="black"
                          className="size-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  )}

                  {pdfPreview && (
                    <div className="relative w-full h-96 bg-gray-100 p-4 rounded-md">
                      <iframe
                        src={pdfPreview}
                        className="w-full h-full"
                        frameBorder="0"
                        title="PDF Preview"
                      ></iframe>
                      <button
                        onClick={cancelPdfPreview}
                        className="absolute top-2 right-2 bg-gray-300 text-white rounded-full  hover:bg-gray-200"
                        aria-label="Cancel image preview"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="black"
                          className="size-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                  {(pdfPreview || imagePreview) && <br />}
                  <hr />
                  <div
                    className={`flex flex-row   lg:py-3 lg:px-6 px-2 text-gray-600 ${
                      imagePreview || pdfPreview
                        ? "justify-end"
                        : "justify-between"
                    } items-center`}
                  >
                    {/* Post options */}
                    <div className="flex gap-2 w-full">
                      {imagePreview || pdfPreview ? null : (
                        <div className=" flex items-center gap-2 text-gray-600">
                          <input
                            type="file"
                            ref={fileUpload}
                            className="hidden"
                            accept="application/pdf"
                            onChange={handleFileChange}
                          />
                          <input
                            type="file"
                            ref={imgUpload}
                            className="hidden"
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                          <div
                            className="flex flex-row items-center gap-2 cursor-pointer border-r-[1px] pr-2"
                            onClick={handleimgUpload()}
                          >
                            <div className="">Image</div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                              />
                            </svg>
                          </div>
                          <div
                            className="flex flex-row items-center gap-2 cursor-pointer border-r-[1px] pr-2"
                            onClick={handlefileUpload()}
                          >
                            <div className="">Document</div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                              />
                            </svg>
                          </div>
                        </div>
                      )}

                      {/* select school drop dropdown */}
                    </div>

                    {/* Post button */}
                    <button
                      type="submit"
                      disabled={creatingPost} // Disable button during creation
                      className={`${
                        creatingPost
                          ? "bg-gray-400"
                          : "bg-[#0052ff] hover:bg-[#014cec]"
                      } text-white cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0`}
                    >
                      {creatingPost ? "Posting..." : "Post"}
                    </button>
                  </div>
                </form>
              </div>
            ) : otherGroups && otherGroups.length > 0 ? (
              <div className=" px-2 py-4">
                <div className=" text-center text-base py-4 font-[550]">
                VNR Vignana Jyothi Institute of Engineering & Technology
                </div>
                <div className="flex flex-col gap-4">
                  {otherGroups?.map((group) => (
                    <div
                      key={group._id}
                      className="p-2 rounded-md border-[1px] text-sm flex justify-between items-start"
                    >
                      <div className=" w-[80%] flex flex-col gap-2 " >
                        <div className="text-base font-[550]">{group.name}</div>
                        <div className="text-sm ">{group.description}</div>
                      </div>
                      <div className="w-[20%]">
                        {buttonLoading ? (
                          <div className="py-1 px-4 rounded-3xl my-4 md:my-0">
                          <FaSpinner className="animate-spin text-[#0052ff] mr-2 size-6" />
                          </div>
                        ) : (
                          <div>
                            {group?.isRequested == "Yes" ? (
                              <div
                                className="my-2 flex justify-end"
                                // onClick={() => requestToGroup(group.groupId)}
                              >
                                <button className=" border-gray-500 text-gray-500 border-[1px] hover:bg-gray-100  font-[550] py-1 px-4 rounded-3xl my-4 md:my-0">
                                  Requested
                                </button>
                              </div>
                            ) : (
                              <div
                                className="my-2 flex justify-end"
                                onClick={() => requestToGroup(group.groupId)}
                              >
                                <button className="bg-[#0052ff] hover:bg-[#014cec] text-white font-[550] py-1 px-4 rounded-3xl my-4 md:my-0">
                                  Join
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              error == "Join the group first" && (
                <div className=" text-center text-base py-4 font-[550]">
                  You are not part of this school/Group. please join a group.
                  <br />
                  {otherGroups?.length}
                  <br />
                  No Groups present
                </div>
              )
            )}

            {/* Posts */}

            <Outlet />

            {!error && loading ? (
              <div className="">
                {" "}
                <Spinnerpage notfullpage={true} />
              </div>
            ) : (
              error &&
              error != "Join the group first" && (
                <div className=" border-[1px] text-center w-full rounded-md p-4">
                  {error}
                </div>
              )
            )}

            <br />
          </div>
        )}

        <div className="lg:w-[20%] hidden lg:flex flex-col gap-2">
          {selectedGroup && error != "Join the group first" && (
            <div
              className=" border-[1px] rounded-md p-2 flex flex-col gap-1 cursor-pointer"
              
            >

              <div className="text-sm font-[550]">Group Description</div>
              <div className="text-sm">{selectedGroup.description}</div>
              <div className="text-sm text-center py-2 ">
                <div className="text-3xl  text-[#0052ff] hover:text-blue-600" onClick={() => setViewMembers(!viewMembers)}>
                  {selectedGroup.members.length + selectedGroup.admins.length}{" "}
                </div>
                Members
              </div>
            </div>
          )}

          {/* <div className=" border-[1px] rounded-md p-2 flex flex-col gap-2">
            {universities &&
              universities
                .filter((uni) => schools.includes(uni.Name))
                .map((school, index) => (
                  <div key={school._id} className="flex flex-col gap-1">
                    <div
                      className={` text-xs flex items-center gap-2 cursor-pointer p-1  ${
                        selectedSchool === school.Name
                          ? "border-l-[1px] border-[#0052ff]"
                          : ""
                      } `}
                      onClick={async () => {
                        setPosts([]);
                        navigate("/feed/" + school.url);
                        setSelectedSchool(school.Name);
                        fetchDataSchool(school.Name);
                        setsidebarflag(!sidebarflag);
                        seterror("");
                        setSelectedGroup(null);
                        setHasMore(true);
                      }}
                    >
                      <img
                        src={school.s3Link}
                        alt="Company Logo"
                        className={
                          " h-[30px] p-1 aspect-square rounded-md  border-[1.5px]  border-gray-500 "
                        }
                      />
                      <div>{school.Name}</div>
                    </div>
                    {index !== schools.length - 1 && <hr />}
                  </div>
                ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}
