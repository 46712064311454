import React, { useState, useEffect,useContext } from "react";
import { GoPlus } from "react-icons/go";
import axios from "axios";
import apiUrl from "../../apiConfig";
import TimelineComponent from "../EduExp";
import { IoIosArrowUp } from "react-icons/io";
import DefaultCompany from "../../images/DefaultCompany.png";
import { logincontext } from "../../contexts/Logincontext";

export default function ExperienceComponent({
  dash,
  setshoweditexperience,
  // showAllJobs,
  // setShowAllJobs,
  showeditexperience,
  visibleItemsJobs,
  publicview,
  api,
  setapi,
}) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const token = localStorage.getItem("token");
  const [showAllJobs, setShowAllJobs] = useState(false);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;
    experience.forEach((item) => {
      const role = {
        jobTitle: item.jobTitle,
        city: item.city,
        country: item.country,
        fromMonth: item.from.split("-")[0],
        fromYear: item.from.split("-")[1],
        description: item.description,
        toMonth:item.to ? item.to.split("-")[0] : item.to,
        toYear: item.to ? item.to.split("-")[1] : item.to,
        _id: item._id,

      };
      if (lastCompany && lastCompany.company === item.company) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          company: item.company,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });
  
    return result;
  };

  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const sortExperienceByFrom = (experience) => {
    return experience.sort((a, b) => {
      const [monthA, yearA] = a.from.split("-");
      const [monthB, yearB] = b.from.split("-");
      const dateA = new Date(yearA, monthMap[monthA]);
      const dateB = new Date(yearB, monthMap[monthB]);
      return dateB - dateA; // Sorting in decreasing order
    });
  };

  const sortedExperience = sortExperienceByFrom(dash?.complete_profile?.profile?.professionalDetails?.experience || []);
  const transformedExperience = transformExperienceData(sortedExperience);
  

  const visiblejobitem = showAllJobs? transformedExperience : transformedExperience.slice(0, 2);

  return (
    <div
      className={`w-full border-[1px] md:pt-5 md:px-6 px-4 py-3 md:rounded-md bg-white text-black`}
    >
      <div className="text-lg font-[550] flex justify-between">
        Experience{" "}
        {dash?.isloggedinuser && (
          <div className="flex gap-4">
            <div
              onClick={() => setshoweditexperience(!showeditexperience)}
              className="w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      {dash?.complete_profile?.profile?.professionalDetails?.experience && dash?.complete_profile?.profile?.professionalDetails?.experience.length !== 0 ? (
        visiblejobitem.map((item, key) => {
         
          const company = companies.find((obj) => obj.Name === item.company);
          const image = company ? company.s3Link : DefaultCompany;
   
          return (
            <TimelineComponent
              key={key}
              props={{
                token: token,
                item: item,
                loggedInUser : loggedInUser,
                changeloggedInUser : changeloggedInUser,
                image: image,
                publicview: !dash?.isloggedinuser,
                api: api,
                setapi: setapi,
                length: transformedExperience.length,
                key: key,
              }}
            />
          );
        })
      ) : (
        <div className="text-gray-500 text-md w-full text-center">
          No Experience mentioned
        </div>
      )}
      {transformedExperience.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center border-slate-300">
          <button
            className="border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setShowAllJobs(!showAllJobs)}
          >
            {showAllJobs
              ? "Show Less"
              : `Show All ${transformedExperience.length}`}{" "}
            <IoIosArrowUp className={showAllJobs ? "rotate-0" : "rotate-180"} />
          </button>
        </div>
      )}
    </div>
  );
}
