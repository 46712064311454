import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa"; // Import the spinner icon
import { IoMdInformationCircleOutline } from "react-icons/io";
import { logincontext } from "../contexts/Logincontext";
import apiUrl from "../apiConfig";

export default function LoginForm({  url }) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const submitForm = async (data) => {
    
    // // console.log(data);
    setLoading(true);
    try {
      const res = await Loginuser(data, url);
      if (res) {
        setError("network", { type: "manual", message: res });
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate(`/forgotpassword`);
  };

  return (
    <div className=" w-full">
      <h1 className="text-center py-3 text-2xl font-[550] font-sans">Login</h1>

      <form onSubmit={handleSubmit(submitForm)} className="p-4">
        {errors.network && ( 
          <p className="text-red-500 text-sm w-full text-center">
            {errors.network.message}
          </p>
        )}
        <input
          type="email"
          placeholder="Email"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.email || errors.network ? "border-red-500" : ""
          }`}
          {...register("email", {
            required: "Email is required",
            
          })}
        />

        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
            errors.password || errors.network ? "border-red-500" : ""
          }`}
          {...register("password")}
        />

        <div className="flex md:justify-between py-4 flex-col md:flex-row gap-4">
          <div className=" flex gap-1 justify-center">
            <input
              type="checkbox"
              className="cursor-pointer"
              onChange={() => setShowPassword((prev) => !prev)}
              id="showPassword"
            />
            <label htmlFor="showPassword" className="cursor-pointer">
              Show password
            </label>
          </div>
          <p
            className="text-[#0052ff] no-underline cursor-pointer"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </p>
        </div>

        <div className="mt-2 flex flex-row-reverse">
          {loading ? (
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
          ) : (
            <button
              type="submit"
              className="bg-[#0052ff] text-white hover:bg-[#014cec] font-[550] py-1 px-4 rounded-3xl"
              onClick={() => clearErrors("network")}
            >
              Log in
            </button>
          )}
        </div>
      </form>
      <br />
      <hr />
      <br />
      <div className="text-md text-center">
        Don't have an account?{" "}
        <span>
          <NavLink
            to="/signup"
            onClick={() => {
              window.scroll(0, 0);
            }}
            className="text-[#0052ff] hover:text-[#014cec] font-[550] hover:underline"
          >
            Create!
          </NavLink>
        </span>
      </div>
      <br />
    </div>
  );
}
