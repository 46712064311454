import React, { useEffect, useState, Fragment } from "react";
import { IoMdClose } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../../apiConfig";

export default function EditMessageModal({
  handleEditMessageModal,
  token,
  selectedMessage,
  selectedUser,
  changeMessageToEditedMessage
}) {

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: selectedMessage.message || "", // Set default tag value here
    },
  });

  // Set the value of the input field when `tagName` changes
  useEffect(() => {
    if (message) {
        // console.log(selectedUser)
      setValue("message", message);
    }
  }, [message, setValue]);


  function decrypt(encryptedString, shift) {
    // Decrypt the string with the inverse shift cipher
    let decryptedString = "";

    for (let i = 0; i < encryptedString.length; i++) {
      let charCode = encryptedString.charCodeAt(i);

      // Shift the character code back
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        decryptedString += String.fromCharCode(
          ((charCode - 65 - shift + 26) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        decryptedString += String.fromCharCode(
          ((charCode - 97 - shift + 26) % 26) + 97
        );
      } else {
        decryptedString += encryptedString[i]; // Non-alphabetical characters
      }
    }

    // Split the decrypted string back into the two original strings using the delimiter
    const delimiter = "|";
    const [string1, string2] = decryptedString.split(delimiter);

    return { string1, string2 };
  }


  const handleEditMessage = async (data) => {
    // setError("");
    // setMessage("");
    // // console.log(data);
    // let { string1: receiverId, string2: current } = decrypt(window.location.pathname.split("/")[2].replace("%7C", "|"), 7);
    // try {
    //   const response = await axios.post(
    //     `${apiUrl.chats.editmessage}${token}`,
    //     {
    //       receiverUserId: receiverId, 
    //       messageId: selectedMessage._id,
    //       message:data.message
    //     }
    //   );
    //   // console.log(response.data)
    //   if (response.status === 200) {
    //     handleEditMessageModal()
        
    //     // setMessage("Message edited successfully");
    //   }
    // } catch (error) {
    //   setError(error.response?.data?.error || "An error occurred");
    // }
    changeMessageToEditedMessage(selectedMessage._id, data.message)
    handleEditMessageModal()
  };


  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={handleEditMessageModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl text-left transition-all mx-4">
                <div className="md:w-[40vw] max-w-screen-lg w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto rounded-xl border-[1.5px] p-4 bg-white">
                  <div>
                    <div className="flex w-full items-start justify-between py-4 cursor-pointer">
                      <div className="text-xl font-[550]">Edit Message</div>
                      <IoMdClose
                        size={30}
                        onClick={handleEditMessageModal}
                        className="p-1 hover:bg-gray-200 border-none rounded-full duration-300"
                      />
                    </div>

                    <form className="" onSubmit={handleSubmit(handleEditMessage)}>
                      <div className="">
                        <div className="flex gap-4 items-center justify-between pt-4">
                          <input
                            type="text"
                            placeholder="Type Message"
                            className="w-full p-2 border-[1.5px] rounded-md"
                            {...register("message", {
                              required: "Message is required",
                            })}
                          />
                        </div>
                        {errors.tag && (
                          <p className="text-red-500 text-sm">
                            {errors.tag.message}
                          </p>
                        )}
                      </div>
                      {error && <p className="text-red-500 text-sm">{error}</p>}
                      {message && (
                        <p className="text-green-500 text-sm">{message}</p>
                      )}
                      <div className="flex justify-end my-2">
                        <button
                          className="bg-[#0052ff] text-white hover:bg-[#014cec] cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4"
                          type="submit" // Submit button should be inside the form
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
