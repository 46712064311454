import React, { useState } from "react";
import Loginform from "../components/Loginform";
import ReDashboard from "./ReDashboard";

export default function Login({  url }) {
  const [role, setRole] = useState("jobseeker");

  const token = localStorage.getItem("token");
  //// // console,log(token);

  const toggleRole = () => {
    setRole(role === "jobseeker" ? "employer" : "jobseeker");
  };

  if (token != null) {
    return <ReDashboard url={url} />;
  } else {
    return (
      <div className="flex justify-center items-center p-3 min-h-[87vh] ">
        <div className="max-w-screen-xl flex justify-center items-center">
          <div
            className={`flex flex-col w-[100%] lg:w-[100%]  h-full  border-2 rounded-lg shadow-lg  shadow-slate-300`}
          >
           
            <div className="h-full">
              <div className="m-4 ">
                {role === "jobseeker" && <Loginform  url={url}/>}
                {role === "employer" && "employer login form"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
