import React, { useEffect, useState,useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Connectionsdropdown from "./Connectionsdropdown";
import axios from "axios";
import apiUrl from "../../apiConfig";
import DefaultCompany from "../../images/DefaultCompany.png";
import DefaultEducation from "../../images/DefaultEducation.png";
import ProfileTile from "./ProfileTile";
import AddTagModal from "./AddTagModal";
import RemoveConnectionModal from "./RemoveConnectionModal";
import { FaSearch } from "react-icons/fa";
import Tooltip from "../Tooltip";
import { logincontext } from "../../contexts/Logincontext";


export default function Connections({
  currentUserId,
  refresh,
  setrefresh,
}) {
  const [openCompModal, setopenCompModal] = useState(false);
  const [openTagModal, setopenTagModal] = useState(false);
  const [openRemoveConnectionModal, setRemoveConnectionModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);


  const [param, setParam] = useState(false);
  const token = localStorage.getItem("token");

  const [searchQuery, setSearchQuery] = useState("");

  const manageCompModal = () => {
    setopenCompModal(!openCompModal);
  };

  const [connected, setconnected] = useState([]);
  const [loading, setloading] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Set items per page

  const reqs = async () => {
    setloading(true);
    try {
      const resp = await axios.post(
        apiUrl.connections.getconnections + token,
        {
          page: currentPage, // Send current page to backend
          limit: itemsPerPage, // Send items per page to backend
        }
      );
      console.log(resp);
      if (resp && resp.status === 200) {
        setconnected(resp.data.connected);
        setTotalPages(resp.data.totalPages); // Set total pages for pagination
      }
    } catch (err) {
      // // console.log(err);
    }
    setloading(false);
  };

  // Fetch connections with pagination
  useEffect(() => {
    reqs();
  }, [refresh, currentPage]);

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const findimage = (name, type) => {
    const company = companies.find((obj) => obj.Name === name);
    const comimage = company ? company.s3Link : DefaultCompany;
    const university = universities.find((obj) => obj.Name === name);
    const schimage = university ? university.s3Link : DefaultEducation;
    return type === "job" ? comimage : schimage;
  };

  const navigate = useNavigate();
  function encrypt(string1, string2, shift) {
    const delimiter = "|";
    let concatenatedString = string1 + delimiter + string2;
    let encryptedString = "";

    for (let i = 0; i < concatenatedString.length; i++) {
      let charCode = concatenatedString.charCodeAt(i);

      if (charCode >= 65 && charCode <= 90) {
        encryptedString += String.fromCharCode(
          ((charCode - 65 + shift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        encryptedString += String.fromCharCode(
          ((charCode - 97 + shift) % 26) + 97
        );
      } else {
        encryptedString += concatenatedString[i];
      }
    }

    return encryptedString;
  }
  
  const navigateToChats = async (item) => {
    const chaturl = encrypt(item.UserId, loggedInUser.complete_profile.UserId, 7);
    navigate("/chats/" + chaturl);
  };

  const handleOpenTagModal = (item) => {
    setSelectedUser(item);
    setopenTagModal(!openTagModal);
  };

  const handleRemoveConnectionModal = (item) => {
    setSelectedUser(item);
    setRemoveConnectionModal(!openRemoveConnectionModal);
  };

  const handleSearchConnections = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        fetchSearchResults(searchQuery);
      } else {
        setParam(!param);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.post(
        apiUrl.connections.getsearchedconnections + token,
        { query }
      );
      if (response.status === 200) {
        setconnected(response.data.searchResults);
      }
    } catch (err) {
      // // console.log(err);
    }
  };

  // Pagination Handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const disconnect = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setconnected(connected.filter((item) => item.url !== url));
        changeloggedInUser({
          ...loggedInUser,
          complete_profile: {
            ...loggedInUser.complete_profile,
            profile : {
              ...loggedInUser.complete_profile,
              connections : {
                ...loggedInUser.complete_profile.connections,
                connections : loggedInUser.complete_profile.connections.connections.filter((con) => con.url !== url)
              }
            }
        }
        });
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };


  return (
    <div>
      {openTagModal && (
        <AddTagModal
          handleOpenTagModal={handleOpenTagModal}
          token={localStorage.getItem("token")}
          selectedUser={selectedUser}
          loggedInUser={loggedInUser}
          changeloggedInUser={changeloggedInUser}
        />
      )}
      {openRemoveConnectionModal && (
        <RemoveConnectionModal
          handleRemoveConnectionModal={handleRemoveConnectionModal}
          token={localStorage.getItem("token")}
          selectedUser={selectedUser}
          disconnect={disconnect}
        />
      )}
      <div className="bg-white w-full border-[1px]  md:rounded-md  py-2 px-4 ">
        <div className="w-full flex justify-between items-center">
          <h1 className=" text-lg font-[550] pb-4 flex items-center gap-1">
            Connections{" "}
            <Tooltip text="Manage and grow your professional network by connecting with colleagues, clients, or new contacts. Stay in touch and expand opportunities with Connections!">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </Tooltip>
          </h1>
          <div className="flex items-center border border-gray-300 px-2 py-1 rounded-md mb-4 w-fit">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              className="w-full focus:outline-none"
              onChange={handleSearchConnections}
            />
            <div>
              <FaSearch />
            </div>
          </div>
        </div>
        <div className={Object.keys(connected).length !== 0 ? "" : "w-full"}>
          {!loading ? (
            Object.keys(connected).length !== 0 ? (
              connected.map((item, key) => (
                <div
                  key={key}
                  className={`${
                    key === connected.length - 1 ? "" : "border-b-[1px]"
                  } px-2 h-full flex md:flex-row flex-col  justify-between md:items-start py-2 w-full`}
                >
                 <div className=" flex w-full justify-between ">
                    <ProfileTile user={item} findimage={findimage} />
                    <div className="inline-block md:hidden">
                      <Connectionsdropdown
                        manageCompModal={() =>
                          handleRemoveConnectionModal(item)
                        }
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 md:py-0 py-3 ">
                    <button
                      to={"/chats/" + item.url}
                      className="duration-300 font-[550] h-fit cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025]"
                      onClick={() => navigateToChats(item)}
                    >
                      <div className="flex gap-1 items-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                          />
                        </svg>
                        <div className=" md:hidden ">Message</div>
                      </div>
                    </button>
                    <button
                      onClick={() => handleOpenTagModal(item)}
                      className="duration-300 font-[550] h-fit cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025]"
                    >
                      <div className="flex gap-1 items-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 6h.008v.008H6V6Z"
                          />
                        </svg>
                        <div className=" md:hidden ">Tag</div>
                      </div>
                    </button>
                    <div className="md:flex hidden">
                      {/* <Connectionsdropdown
                        manageCompModal={() => disconnect(item.url)}
                      /> */}
                      <Connectionsdropdown
                        manageCompModal={() =>
                          handleRemoveConnectionModal(item)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className=" flex justify-center items-center  min-h-[30vh]">
                <h1>No Connections found!</h1>
              </div>
            )
          ) : (
            <div className=" flex justify-center items-center  min-h-[30vh]">
              <h1>Loading...</h1>
            </div>
          )}
        </div>
        {Object.keys(connected).length !== 0 ? 
        <div className="flex justify-center mt-4">
          <button
            className={`md:text-md text-sm px-4 py-2 mx-1 border rounded ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="md:text-md text-sm  px-4 py-2 mx-1">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className={`md:text-md text-sm px-4 py-2 mx-1 border rounded ${
              currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div> : <div></div>}
      </div>
    </div>
  );
}
