import React, { useState, useEffect } from "react";
import { FaGreaterThan } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import { Accordioncomponent } from "../components/Accordioncomponent";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiUrl from "../apiConfig";
import { Accordion2 } from "../components/Accordion2";

export default function CourseDesign() {
  const [role, setrole] = useState("Program");
  const [country,setCountry] = useState("US");
  const modules = {
    "Module 1": [
      "Basics of programming",
      "Data types & operators",
      "Complexity Analysis",
      "Bit-Manipulation & Applications",
      "Recursion / Backtracking",
    ],
    "Module 2": [
      "Sorting / Searching Techniques & Applications",
      "Hashing Implementation & Libraries",
      "Subarrays & Subsequences",
      "Strings & Rolling Hash",
      "Mixed-bag Concepts",
    ],
    "Module 3": [
      "Stacks & Queues",
      "Linked Lists",
      "LRU Cache",
      "Trees / Binary Trees / Binary Search Trees",
      "Priority Queues",
      "Trie DS & Applications",
    ],
    "Module 4": ["Dynamic Programming", "Graph Theory"],
  };

  const [bgColor, setBgColor] = useState("bg-white");

  //   const handleScroll = () => {
  //     const scrollThreshold = 250; // Change this value to the scroll position you want
  //     if (window.scrollY > scrollThreshold) {
  //       setBgColor("bg-[#f2f6fd]");
  //     } else {
  //       setBgColor("bg-white");
  //     }
  //   };

  //   useEffect(() => {
  //     const handleResize = () => {
  //       if (window.innerWidth >= 1024) {
  //         // Tailwind's lg breakpoint is 1024px
  //         window.addEventListener("scroll", handleScroll);
  //       } else {
  //         window.removeEventListener("scroll", handleScroll);
  //         setBgColor("bg-blue-200"); // Reset to default color on smaller screens
  //       }
  //     };

  //     handleResize(); // Set up event listeners based on initial window size
  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);

  useEffect(() => {
    const fetchCountry = async () => {
      const response = await axios.post(apiUrl.getcountry);
      // // console.log(response)
      // // console.log(response.data)
      if(response.data){
        setCountry(response.data.country);
      }
    }

    fetchCountry();
  }, []);


  const [number, setNumber] = useState(null);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       axios.post(apiUrl.location.getcountry, { latitude, longitude })
  //         .then(response => {
  //           // // // console,log(response.data.country);
  //           setCountry(response.data.country);
  //         })
  //         .catch(error => {
  //           // console.error('Error fetching number:', error);
  //         });
  //     },
  //     (error) => {
  //       // console.error('Error getting location:', error);
  //       // Fallback to IP-based lookup
  //       // axios.get('/api/location')
  //       //   .then(response => {
  //       //     setNumber(response.data.number);
  //       //   })
  //       //   .catch(error => {
  //       //     // console.error('Error fetching number:', error);
  //       //   });
  //     }
  //   );
  // }, []);


  
  const about = [
    "4 Months Program",
    "Classes: 14-16 weeks",
    "4:30 - 8:30 AM (CST)",
    "Online Live Classes",
    "Saturday and Sunday",
    "Next Batch: June, 2024",
    "Beginner level",
  ];

  const { register, handleSubmit, formState: err } = useForm();
  const [res, setres] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formsub = async (data) => {
    // // // console,log(data);
    try {
      const response = await axios.post(apiUrl.url.sendusermessage, data);
      // // console,log(response.data.message);
      setres(response.data.message);
    } catch (err) {}
  };
  const handleNavigate = () => {
    navigate("/learning/dsa");
  };

  return (
    <div>
      <div className=" flex justify-center">
        <div className="w-full relative lg:max-w-screen-xl lg:w-[95%] ">
          <div className="lg:absolute block lg:top-[2rem] lg:right-[0px] lg:w-[380px] w-full">
            <div
              className={`lg:fixed  lg:border-[1px] lg:shadow-xl  lg:w-[380px] w-full bg-white z-[4]`}
            >
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/TGomygt_kHA"
                title="7 years of Smart Interviews | How and why we started on this journey?"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>

              {/* small screens */}
              <div className="flex flex-col justify-evenly h-full gap-2 lg:hidden p-2">
                <div className="text-xl font-[550]">
                  Master Advanced Data Structures and Algorithms
                </div>
                <div>
                  18000+ placed in top tech companies. <br />
                  Next batch: June 22, 2024
                </div>
                <div>
                  <span className="p-1 text-sm bg-[#eceb98] text-black font-[550]">
                    best seller
                  </span>{" "}
                  <span className="text-yellow-500 mx-2">4.7 ★★★★</span>
                </div>
                <div>Created by: XXX</div>
                <div>
                  <div className="flex lg:flex-row flex-col justify-around w-[80%]">
                    <div>XXX</div>
                    <div>English</div>
                    <div>XXX</div>
                  </div>
                </div>
              </div>
              <hr className="lg:hidden" />

              <div className="flex">
                <div
                  className={`w-[50%] p-2 font-[550] text-center cursor-pointer ${
                    role === "Program" ? "border-b-2 border-black" : ""
                  }`}
                  onClick={() => setrole("Program")}
                >
                  Program
                </div>
                <div
                  className={`w-[50%] p-2 font-[550] text-center cursor-pointer ${
                    role === "Course" ? "border-b-2 border-black" : ""
                  }`}
                  onClick={() => setrole("Course")}
                >
                  Course
                </div>
              </div>
              <div className="m-2 p-2 flex flex-col gap-2">
                <div className="font-[550]">
                  An investment that helps to fast-track your career growth
                </div>
                {role === "Program" && (
                  <div className="flex flex-col gap-2">
                    <div className="font-[550] text-gray-600">
                      Network with Students
                    </div>
                    <div className="flex gap-2 items-center">
                      <span className="font-[550] text-2xl">
                        <div>$799</div>
                        {/* {country === "India" ? (<div>₹ 60000</div>) : (<div>$799</div>)} */}
                      </span>
                      <span className="text-md text-gray-700 line-through">
                        <div>$999</div>
                        {/* {country === "India" ? (<div>₹ 72000</div>) : (<div>$999</div>)} */}
                      </span>
                      <span className="text-md text-green-600 font-[550]">
                        20% off
                      </span>
                    </div>
                    <div className="flex justify-end">
                      <button className="bg-[#0052ff] text-white hover:bg-[#014cec] font-[550] py-1 px-4 rounded-3xl w-fit">
                        Enroll now
                      </button>
                    </div>
                  </div>
                )}
                {role === "Course" && (
                  <div className="flex flex-col gap-2">
                    <div className="font-[550] text-gray-600">
                      Network with IT Professionals
                    </div>
                    <div className="flex gap-2 items-center">
                      <span className="font-[550] text-2xl">$999</span>
                      <span className="text-md text-gray-700 line-through">
                        $1199
                      </span>
                      <span className="text-md text-green-600 font-[550]">
                        15% off
                      </span>
                    </div>
                    <div className="flex justify-end">
                      <button className="bg-[#0052ff] text-white hover:bg-[#014cec] font-[550] py-1 px-4 rounded-3xl w-fit">
                        Enroll now
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* small screen ends */}

      <div className="lg:block hidden  bg-[#f2f6fd] text-black">
        <div className="flex justify-center h-full">
          <div className="w-full md:max-w-screen-xl md:w-[95%] flex md:flex-row flex-col gap-x-4 md:p-4 h-full">
            <div className="flex flex-col w-[60%] justify-evenly h-full gap-4 ">
              <div className=" flex items-center gap-2 text-sm ">
                <span className="text-gray-700 font-[550] hover:underline">
                  {" "}
                  Courses{" "}
                </span>{" "}
                <span>
                  {" "}
                  <FaGreaterThan size="0.875rem" />{" "}
                </span>{" "}
                <span className="text-gray-700 font-[550] hover:underline">
                  DSA
                </span>
              </div>
              <div className=" text-3xl font-[550] ">
                Master Advanced Data Structures and Algorithms
              </div>
              <div className="text-base">
                18000+ placed in top tech companies. <br />
                Next batch: June 22, 2024
              </div>
              <div>
                <span className="p-1 text-sm bg-[#eceb98] text-black font-[550]">
                  best seller
                </span>{" "}
                <span className=" text-yellow-500 mx-2">4 ★★★★</span>
              </div>
              <div className="text-base">Created by: XXX</div>
              <div>
                <div className=" flex justify-around w-[80%] text-base ">
                  <div>XXX</div>
                  <div>English</div>
                  <div>XXX</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div className="relative w-full md:max-w-screen-xl md:w-[95%]  flex md:flex-row flex-col gap-x-4 md:p-4 h-full">
          <div className="flex flex-col lg:w-[60%] justify-evenly">
            <div className="border-[1px] rounded-xl p-4">
              <div className="text-2xl font-[550]">About this course</div>
              <div className=" py-2">
                <ul className="grid md:grid-cols-2 grid-cols-1">
                  {about.map((item, key) => (
                    <li className="flex items-center pb-2" key={key}>
                      <i className="text-green-600 text-xl">
                        <TiTick />
                      </i>{" "}
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <div className="text-center py-6">
                <div className="font-[550] text-lg md:text-2xl text-center">
                  Course Curriculum
                </div>
                <div className="px-4">
                  30% theoretical concepts and 70% in-class problem solving
                </div>
              </div>
              <div className="flex justify-center">
                {/* <div className="grid md:grid-cols-2 grid-cols-1 w-[100%] gap-6 py-4">
                  {Object.keys(modules).map((item, key) => (
                    <div
                      key={key}
                      className="w-full h-full flex items-center justify-center min-h-[230px]"
                    >
                      <div className="rounded-md px-4 py-2 md:w-[400px] w-[100%] h-[100%] border-gray-200 border-[1.5px] text-black">
                        <div className="font-[550] py-2 text-lg">{item}</div>
                        {modules[item].map((i, key) => (
                          <li key={key} className="text-gray-700">
                            {i}
                          </li>
                        ))}
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className="flex justify-center">
                <div className="w-[100%]">
                  <Accordioncomponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div className="relative w-full md:max-w-screen-xl md:w-[95%]  flex md:flex-row flex-col gap-x-4 p-4 h-full">
          <div className="flex flex-col lg:w-[60%] justify-evenly ">
            <div className="w-full ">
              <div className="font-[550] text-lg md:text-2xl text-center pb-6">
                Frequently Asked Questions
              </div>
              <div className="flex justify-center">
                <div className="w-full">
                  <Accordion2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div className="relative w-full md:max-w-screen-xl md:w-[95%]  flex md:flex-row flex-col gap-x-4 md:p-4 h-full">
          <div className="flex flex-col lg:w-[60%] justify-evenly">
            <div className="">
              <div className="py-4">
                <div className="font-[550] text-lg md:text-2xl text-center ">
                  More Questions?
                </div>
                <div className="text-center px-4 mb-2">
                  Sure, let us know below. We will get back to you soon.
                </div>
              </div>
              <div className="flex justify-center">
                <form className="w-full p-4" onSubmit={handleSubmit(formsub)}>
                  <div className="mb-4">
                    <label htmlFor="email" className="font-[550]">
                      Enter your email: <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className="p-2 border-[1.5px] rounded-md my-2 w-full"
                      name="email"
                      required
                      {...register("email")}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="font-[550]">
                      Name: <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className="p-2 border-[1.5px] rounded-md my-2 w-full"
                      name="email"
                      required
                      {...register("name")}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="font-[550]">
                      Enter your message:{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      cols="30"
                      className="w-full p-2 border-[1.5px] rounded-md my-2"
                      name="message"
                      required
                      {...register("message")}
                    />
                  </div>
                  <div className="flex flex-row-reverse justify-between items-center">
                    {res && <div className=" text-green-500 ">{res}</div>}
                    <input
                      type="submit"
                      value="Submit"
                      className="bg-[#0052ff] text-white hover:bg-[#014cec] font-[550] py-1 px-4 rounded-3xl w-fit cursor-pointer"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
