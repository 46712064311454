import React, { useEffect, useState } from "react";
import { LuPencilLine } from "react-icons/lu";
import apiUrl from "../apiConfig";
import axios from "axios";
export default function AnalyticsComponent({
  urlsubmit,
  curraddsubmit,
  url,
  dash,
  curradd,
  connectionres,
}) {
  const token = localStorage.getItem("token");

  const [views, setViews] = useState(0);
  const [uniqueViewers, setUniqueViewers] = useState(0);

  const getViews = async () => {
    try {
      const response = await axios.post(apiUrl.views.getviewcount + token);
      if (response.status === 200) {
        setViews(response.data.views);
        setUniqueViewers(response.data.numberofviewers);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getViews();
  }, []);
  return (
    <div className="">
      {dash?.isloggedinuser && (
        <div
          className={`w-full p-2 border-[1px]  md:rounded-md   bg-white text-black`}
        >
          <div>
            <div>
              <div>
                <div className="  flex justify-between px-2 items-center">
                  {" "}
                  <div className="font-[550] text-lg">Analytics</div>
                </div>
                <div>
                  <div>
                    <div className="px-2 mx-2 ">
                      <div className="text-base font-[550]">{views}</div>
                      <div className="text-sm text-gray-500">
                        Profile views in the past 7 days
                      </div>
                    </div>
                    <hr className=" my-2" />
                    <div className="px-2 mx-2 ">
                      <div className="text-base font-[550]">
                        {uniqueViewers}
                      </div>
                      <div className="text-sm text-gray-500">
                        Unique visitors in the past 7 days
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <hr />
            <div>
              <div className="text-lg flex justify-between p-2 items-center">
                {" "}
                <div className="font-[550]">Your Current Location</div>

                
                <div
                  className=" p-2 hover:bg-gray-300 cursor-pointer duration-300 rounded-full"
                  onClick={handleEditCurrLocModel}
                >
                  <LuPencilLine size={20} />
                </div>
              </div>
              <div>
                <div className="px-2 text-sm text-gray-500">
                  <div>{curradd.streetAddress}</div>
                  <div>{curradd.society}</div>
                  <div>{curradd.city}</div>
                  <div>
                    {curradd.country} {curradd.pincode}
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
