import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import apiUrl from "../apiConfig";
import { FaSpinner } from "react-icons/fa";
import routest from "../jsonfiles/routes";

export default function EditUrlModal({
  url,
  urlsubmit,
  viewediturl,
  setviewediturl,
  handleviewurlModel,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [newurl, setNewUrl] = useState(url);

  const closemodal = () => {
    setviewediturl(!viewediturl);
  };

  
  const handleChange = (event) => {
    const { value } = event.target;
    setNewUrl(value);
    if (value.length > 100 || value.length < 3) {
      seturlerr("URL must be between 3-100 characters.");
      return;
    }
    // Regex for alphanumeric characters, underscores, and hyphens only
    const isValid = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9]+$/.test(value);

    if (!isValid) {
      seturlerr("URL can only contain letters, numbers.");
    } else {
      seturlerr("");
    }
  };
  const token = localStorage.getItem("token");
  const [urlerr, seturlerr] = useState("");
  const [loading, setloading] = useState(false);
  const submitUrl = async () => {
    // Handle URL submission
    setloading(true);
    try {
      // Check for invalid characters before submitting
      if (urlerr) {
        return;
      }

      const newuri = newurl.toLowerCase();
      if (!/^[a-z0-9]+$/.test(newuri)) {
        seturlerr("URL can only contain letters, numbers.");
        return;
      }

      const blacklistedUrls = [
        "/",
        "/signup",
        "/login",
        "/forgotpassword",
        "/accessmanagement",
        "/documents",
        "/admin/:email",
        "/admin/addlogos",
        "/globaladmin",
        "/settings",
        "/signup/phoneno",
        "/signup/resume",
        "/signup/address",
        "/signup/profilepicture",
        "/signup/add-education",
        "/signup/education-review",
        "/signup/add-project",
        "/signup/project-review",
        "/signup/add-experience",
        "/signup/job-review",
        "/signup/add-skills",
        "/signup/add-socialprofiles",
        "/deleteaccount/*",
        "/:url/*",
        "/:url/resume",
        "/jobs",
        "/jobs/:jobId",
        "/chats",
        "/chats/:chaturl",
        "/network",
        "/network/newpeople",
        "/network/invitations",
        "/network/connections",
        "/network/tags",
        "/courses",
        "/courses/dsa",
        "/post/:id",
      ];

      if (blacklistedUrls.includes(`/${newuri}`)) {
        seturlerr(
          "This URL is not available. Please choose a different one."
        );
        return;
      }

      const response = await axios.post(apiUrl.edituserdetails + token, {
        profileUrl : newuri,
      });
      
      urlsubmit(newuri);
      closemodal();
    } catch (err) {
      seturlerr(err.response.data.message);
    } finally {
      setloading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleviewurlModel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="font-[550] text-xl">
                      Edit your Profile URL
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <div className="p-2">
                    <div>Personalize the URL for your profile.</div>
                    <br />
                    <div className="flex gap-1 items-center">
                      <div>aspireup.ai/</div>
                      <input
                        value={newurl}
                        type="text"
                        onChange={handleChange}
                        className={`w-full focus:border-b-[1.5px] ${
                          urlerr && "border-b-[1.5px] border-red-500 "
                        } text-gray-500 focus-within:outline-none my-2 bg-white text-black`}
                      />
                    </div>
                    {urlerr && (
                      <p className="text-red-500 text-sm font-[550]">
                        {urlerr}
                      </p>
                    )}
                    <div className="flex flex-row-reverse justify-between">
                      {loading ? (
                        <FaSpinner
                          size={30}
                          className="text-[#0052ff] animate-spin"
                        />
                      ) : (
                        <div>
                          {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                          <button
                            onClick={submitUrl}
                            className={` text-white  bg-[#0052ff] hover:bg-[#014cec]   font-[550] py-1 px-4 rounded-3xl my-4 md:my-0 ${
                              urlerr
                                ? "cursor-not-allowed bg-gray-500 hover:bg-gray-400"
                                : "cursor-pointer bg-[#0052ff] hover:bg-[#014cec]"
                            }`}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                    <br />
                    <div>
                      Your custom URL must contain only 3-100 letters or
                      numbers.
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
