import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../apiConfig";
import axios from "axios";

const SidebarSection = ({
  section,
  selectedGroup,
  setSelectedGroup,
  fetchDataSchool,
  selectedSchool,
  otherGroups,
  setPosts,
  navigate,
  schoolurl,
  defaultOpen,
  isMenuOpen,
  toggleMenu,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div className=" overflow-hidden">
      <div
        className="font-[550] p-2 flex justify-left items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`size-5 transition-transform duration-300 ${
            !isOpen ? "-rotate-90" : ""
          }`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
        <span>{section.name}</span>
      </div>
      <div
        className={` transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        {section.groups.map((group, index) => (
          <div
            key={index}
            className={`py-2 px-7 cursor-pointer text-sm  ${
              selectedGroup?.groupId === group.groupId
                ? "bg-blue-100 border-r-[1px] border-[#0052ff] hover:bg-blue-100"
                : "hover:bg-blue-50"
            }`}
            onClick={() => {
              if (group._id == selectedGroup._id) {
                return;
              }
              setPosts([]);
              const foundOtherGroup = otherGroups?.find(
                (othergroup) => othergroup.groupId === group.groupId
              );
              // console.log(foundOtherGroup);
              if (foundOtherGroup) {
                setSelectedGroup(foundOtherGroup);
              } else {
                setSelectedGroup(group);
              }
              fetchDataSchool(selectedSchool, group);
              navigate(`${schoolurl}/${group.groupId}`);
              if (isMenuOpen) {
                toggleMenu();
              }
            }}
          >
            {group.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const encrypt = async (school) => {
  try {
    // console.log(school);
    const resp = await axios.post(apiUrl.feed.getschooldetails, {
      school: school,
    });
    if (!resp) {
      return "";
    }
    // console.log(resp);
    return resp.data.url;
  } catch (err) {
    // console.log(err);
    return "";
  }
};

const Sidebar = ({
  schools,
  setSelectedSchool,
  setOtherGroups,
  otherGroups,
  fetchDataSchool,
  selectedSchool,
  posts,
  setPosts,
  selectedGroup,
  setSelectedGroup,
  sidebarflag,
  setsidebarflag,
  isMenuOpen,
  universities,
  toggleMenu,
}) => {
  const token = localStorage.getItem("token");
  const [sectionsGroups, setSectionGroups] = useState([]);
  const [independentGroups, setIndependentGroups] = useState([]);
  const navigate = useNavigate();
  const { schoolurl } = useParams();
  const [openIndex, setOpenIndex] = useState(0);

  const getsections = async () => {
    try {
      const resp = await axios.post(apiUrl.feed.getsections + token, {
        school: selectedSchool,
      });
      // console.log(resp.data);
      setSectionGroups(resp.data.sections);
      setOtherGroups(resp.data.othergroups);
      setIndependentGroups(resp.data.independentGroups);
    } catch (err) {
      setSectionGroups([]);
      setIndependentGroups([]);
      setOtherGroups([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedSchool) {
        await getsections();
      }
    };

    fetchData();

    // You can optionally include a cleanup function if needed
    return () => {
      // Cleanup code if necessary
    };
  }, [selectedSchool, schoolurl, sidebarflag]);

  useEffect(() => {
    if (selectedGroup && sectionsGroups) {
      const index = sectionsGroups.findIndex((section) =>
        section.groups.some((group) => group.groupId === selectedGroup?.groupId)
      );
      setOpenIndex(index);
    }
  }, [sectionsGroups, selectedGroup]);

  async function decrypt(url) {
    // Decrypt the string with the inverse shift cipher
    let decryptedString = "";
    if (!url) {
      if (selectedSchool) {
        const encryptedUrl = await encrypt(selectedSchool);
        navigate(encryptedUrl);
      }
      return decryptedString;
    }

    try {
      const resp = await axios.post(apiUrl.feed.getschooldetails, {
        url: url,
      });
      if (!resp) {
        return "";
      }
      return resp.data.name;
    } catch (err) {
      // console.log(err);
      return "";
    }
  }

  useEffect(() => {
    const fetchschool = async () => {
      const str = await decrypt(schoolurl, 7);
      setSelectedSchool(str);
    };

    fetchschool();
  }, []);

  return (
    <div className=" w-full">
      <div className=" ">
       
        {independentGroups.map((group, index) => (
          <div
            key={index}
            className={`py-2 px-7 cursor-pointer text-sm  ${
              selectedGroup?.groupId === group.groupId
                ? "bg-blue-100 border-r-[1px] border-[#0052ff] hover:bg-blue-100"
                : "hover:bg-blue-50"
            }`}
            onClick={() => {
              if (group._id == selectedGroup?._id) {
                return;
              }
              setPosts([]);
              setSelectedGroup(group);
              fetchDataSchool(selectedSchool, group);
              navigate(`${schoolurl}/${group.groupId}`);
              if (isMenuOpen) {
                toggleMenu();
              }
            }}
          >
            {group.name}
          </div>
        ))}
        {sectionsGroups.map((section, index) => (
          <SidebarSection
            key={index}
            section={section}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            fetchDataSchool={fetchDataSchool}
            selectedSchool={selectedSchool}
            setPosts={setPosts}
            otherGroups={otherGroups}
            navigate={navigate}
            schoolurl={schoolurl}
            isMenuOpen={isMenuOpen}
            defaultOpen={index === openIndex}
            toggleMenu={toggleMenu}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
