import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import countriesList from "../asserts/Countrylist";

export default function EditCurrLoc({
  viewEditCurrLoc,
  curraddsubmit,
  setviewEditCurrLoc,
  handleEditCurrLocModel,
  curradd,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const { handleSubmit, register, formState: errors } = useForm({});

  const closemodal = () => {
    setviewEditCurrLoc(!viewEditCurrLoc);
  };

  const formsubmit = (data) => {
    // // // console,log(data);
    data.country = selectedCountry;
    curraddsubmit(data);
    closemodal();
  };

  const [selectedCountry, setselectedCountry] = useState("");
  const [filteredCounties, setfilteredCounties] = useState([]);

  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCounties.length > 0) {
        const dropdown = document.getElementById("country");
        if (dropdown && !dropdown.contains(event.target)) {
          setfilteredCounties([]);
        }
      }
    };

    const handleEscapeKey = (event) => {
      // Close the dropdown if the "Escape" key is pressed
      if (event.key === "Escape") {
        setfilteredCounties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredCounties]);

  useEffect(() => {
    setselectedCountry(curradd.country);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={handleEditCurrLocModel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="font-[550] text-xl">
                      Edit Your Location
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <div className="p-2">
                    <div className="flex gap-1 items-center">
                      <form onSubmit={handleSubmit(formsubmit)}>
                        <label htmlFor="city" className="font-[550]">
                          Street Address
                        </label>
                        <input
                          type="text"
                          name="Street Address"
                          defaultValue={curradd.streetAddress}
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  bg-white text-black`}
                          {...register("streetAddress")}
                        />
                        <label htmlFor="city" className="font-[550]">
                        Community Name
                        </label>
                        <input
                          type="text"
                          name="Street Address"
                          defaultValue={curradd.society}
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                          {...register("society")}
                        />
                        <label htmlFor="city" className="font-[550]">
                          City
                        </label>
                        <input
                          type="text"
                          name="City"
                          defaultValue={curradd.city}
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  bg-white text-black`}
                          {...register("city")}
                        />
                        <label htmlFor="city" className="font-[550]">
                          Country
                        </label>
                        <div className="w-[100%] relative">
                          <div>
                            <input
                              type="text"
                              className={`w-full p-2 border-[1.5px] rounded-md my-2 `}
                              name="city"
                              value={selectedCountry}
                              {...register("Country")}
                              onChange={handleCityChange}
                            />
                            {/* Dropdown menu for filtered cities */}
                            {filteredCounties.length > 0 && (
                              <div
                                id="country"
                                className="absolute z-10 mt-1 bg-white border w-[100%] max-h-[150px] overflow-y-auto   rounded-md shadow-lg"
                              >
                                {filteredCounties.map((city, index) => (
                                  <div
                                    key={index}
                                    className="cursor-pointer hover:bg-gray-100 p-2"
                                    onClick={() => handleCitySelect(city)}
                                  >
                                    {city}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>

                        <label htmlFor="city" className="font-[550]">
                          Pin Code
                        </label>
                        <input
                          type="number"
                          name="Pin Code"
                          defaultValue={curradd.pincode}
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2 bg-white text-black`}
                          {...register("pincode")}
                        />
                        <div className="flex flex-row-reverse justify-between">
                          <button
                            type="submit"
                            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>

                    <br />
                    {/* <div>
                      Note: Current location allows you to connect with the
                      people arround you.
                    </div> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
