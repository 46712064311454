import React, { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiUrl from "../apiConfig";
import ComponentOrder from "./ComponentOrder";
import { useEffect } from "react";
import { logincontext } from "../contexts/Logincontext";
export default function ComponentOrderModal({ manageCompModal, url, dash }) {
  const [
    loginerror,
    UserloginStatus,
    loggedInUser,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Logoutuser,
    setloginerror,
    SignupEmployer,
    LoginEmployer,
    changeloggedInUser,
  ] = useContext(logincontext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [componentOrder, setComponentOrder] = useState({});
  const [comp, setComp] = useState([]);
  const [emptycomp, setemptycomp] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const handleArrangeClick = async () => {
    setLoading(true);
    const changed = {
      ...loggedInUser,
      complete_profile: {
        ...loggedInUser.complete_profile,
        profile: {
          ...loggedInUser.complete_profile.profile,
          professionalDetails: componentOrder,
        },
      },
    };
    changeloggedInUser(changed);

    const reqbody = {
      professionalDetails: componentOrder,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      if (response.status === 200) {
        manageCompModal();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setComponentOrder(
      dash.complete_profile.profile.professionalDetails
    );
    setComp(
      Object.keys(dash.complete_profile.profile.professionalDetails)
    );
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={manageCompModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  bg-white text-black`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="md:text-2xl text-lg font-[550]">
                      Sections Order
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={manageCompModal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <div className=" w-[100%]">
                    <ComponentOrder
                      url={url}
                      componentOrder={componentOrder}
                      setComponentOrder={setComponentOrder}
                      comp={comp}
                      setComp={setComp}
                      handleArrangeClick={handleArrangeClick}
                      manageCompModal={manageCompModal}
                      loading={loading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
